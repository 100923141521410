import React from 'react'

const WideRange = () => {


    

    return (
        <section className='bg-[#D4EFEF] '>
            <div className='flex flex-col md:flex-row items-center p-5 md:py-10'>

                <div className='flex-1 '>
                    {/* col1  */}
                    <div className='md:p-10'>
                        <div className='h2'>Wide-ranging rewards. Exclusive choices. Limitless possibilities.</div>


                    </div>

                </div>
                <div className='flex-1 '>
                    {/* col2  */}
                    <p className='description py-5 md:p-10 text-xl font-medium'>As a rapidly expanding global network for brands and a major provider of branded payment solutions, we offer a myriad of ways to connect with your customers globally through our advanced payment technologies and solutions.</p>

                </div>
            </div>
        </section>
    )
}

export default WideRange