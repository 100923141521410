import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { TbZoomInArea } from "react-icons/tb";
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';
import responseErrorManage from '../../../utility/responseErrorManage';

const DirectPaymentList = () => {

    const [paymentList, setPaymentList] = useState([]);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.pageLoaderReducer);


    const [modalTitle, setModalTitle] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [isModalDataProcessing, setIsModalDataProcessing] = useState(false)

    const [paymentDetails, setpaymentDetails] = useState([
        { "test1key": "test1v" }, { "test2key": "test2value" },
    ])
    const [transaction, setTransaction] = useState(true);
    const [sellserCardInfo, setSellserCardInfo] = useState({});
    const [sellserInfo, setSellserInfo] = useState({});
    const [code, setCode] = useState(''); // changeHere - stateName correction
    const [transaction_amount, setTransactionAmount] = useState(''); // changeHere - stateName correction
    const [dueAmount, setDueAmount] = useState(0); // changeHere - stateName correction
    // const [paymentIntentInfo, setpaymentIntentInfo] = { "seller_id": 0, "paid_amount": 0, "due_amount": 0, "code": "" }

    // load users from server
    const loadData = (pageNumber = 1) => {

        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }))

        axios
            .get(baseApiUrl() + "paylog?page=" + pageNumber)
            .then((res) => {


                setPaymentList(res.data);

            })
            .catch((err) => {
                responseErrorManage(err)

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }))
            })


        console.log('test-5', isLoading)
    };

    const hanldePaymentDetails = (paymentType, paymentDeails) => {
        const internalTxnId = paymentDeails["internal_id"];
        console.log("hanldePaymentDetails", paymentType, paymentDeails, internalTxnId)
        
        if (paymentType == 1 || paymentType === null) {
            setModalTitle("Transaction Details - Save Only")
        }
        else if (paymentType == 2) {
            setModalTitle("Transaction Details - Other Platform")
            getOtherPlatformData(internalTxnId, paymentDeails);
        }
        else if (paymentType == 3) {
            setModalTitle("Transaction Details - Nexoz Seller Info")
            getNexozUserInfo(internalTxnId, paymentDeails)
        }

        setShowModal(true)
    }

    const getOtherPlatformData = (internalTxnId, paymentDeails) => {
        


     
        setIsModalDataProcessing(true);

        axios
            .get(baseApiUrl() + "pay-other-platform/"+ internalTxnId)
            .then((res) => {
                // Create the Array of obj for Deatils Modal 
                const responseData = res.data.data; 
                const otherPlatformData = [
                    { "Transaction Label": paymentDeails["transaction_label"] },
                    { "Card Number": paymentDeails["card_number"] },
                    { "Amount": "$ "+paymentDeails["amount"] },
                    { "Transaction ID": paymentDeails["transaction_id"] },
                    
                    { "Txn Date": responseData['txn_date'] },
                    { "Trader Name": responseData['trader_name'] },
                    { "username/link": responseData['username_link'] },
                    { "Trader Country": responseData['trader_country'] },
                    { "Is Traders Id Verified": responseData['is_traders_id_verified'] ? "Yes" :"No" },
                ]
                console.log("paymentDeails_2",responseData['txn_date'] )
                setpaymentDetails(otherPlatformData);
                // Create the Array of obj for Deatils Modal 
            })
            .catch((err) => {
                responseErrorManage(err)
            })
            .finally(() => {
                setIsModalDataProcessing(false);
            })
    }
    const getNexozUserInfo = (internalTxnId, paymentDeails) => {

        setIsModalDataProcessing(true);

        axios
            .get(baseApiUrl() + "seller-details/" + internalTxnId)
            .then((res) => {
                const responseData = res.data.data; 
                console.log("test_221", responseData)
                // Create the Array of obj for Deatils Modal 
                const otherPlatformData = [
                    { "Transaction Label": paymentDeails["transaction_label"] },
                    { "card_number": paymentDeails["card_number"] },
                    { "Amount": "$ "+paymentDeails["amount"] },
                    { "Transaction ID": paymentDeails["transaction_id"] },


                    { "Seller ID": internalTxnId },
                    { "Seller Name": responseData.name },
                    { "Seller Email": responseData.email },
                    

                ]
                setpaymentDetails(otherPlatformData);
                // Create the Array of obj for Deatils Modal 
            })
            .catch((err) => {
                responseErrorManage(err)
            })
            .finally(() => {
                setIsModalDataProcessing(false);
            })

    }

    useEffect(() => {
        loadData();
    }, []);







    return (
        <Layout>

            {/* // Modal Test START */}
            <>

                {showModal ? (
                    <>
                        {
                            isModalDataProcessing ? <div
                                className="text-white justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                            >
                                <AiOutlineLoading3Quarters className="animate-spin text-5xl text-white" />
                            </div> :
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <h3 className="text-3xl font-semibold">
                                                    {modalTitle}
                                                </h3>
                                                <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6 flex-auto">


                                                <p>


                                                    {paymentDetails.map((item) =>

                                                        <div className="grid grid-cols-2 gap-2">
                                                            <div className=" text-slate-500 text-lg leading-relaxed">{Object.keys(item).toString()}</div>
                                                            <div>{item[Object.keys(item).toString()]}</div>
                                                        </div>

                                                    )}
                                                </p>

                                            </div>
                                            {/*footer*/}
                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                <button
                                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    Close
                                                </button>
                                                {/* <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Save Changes
                                        </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>

            {/* // Modal Test  END */}
            <div className='bg-white rounded-lg '>

                <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                    <h3>Transaction List</h3>
                    <Link to={'/direct-payment'}>
                        <button className='button__bg px-6 py-2 block rounded'>Add New Transaction</button>
                    </Link>
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Gateaway
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Method
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Transaction Label
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Transaction ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Card Number
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Details
                                </th>
                            </tr>
                        </thead>
                        <tbody className='mt-5'>
                            {paymentList?.map((item, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                        <td className="px-6 py-4">
                                            Authorize.net
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.txn_save_to == 1 && "Save Label"}
                                            {item.txn_save_to == 2 && "Other Platform"}
                                            {item.txn_save_to == 3 && "Nexoz User"}
                                            <p className="text-red-100">{item.txn_save_to == null && "Not Linked"}</p>
                                            
                                        </td>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                            {item.transaction_label}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                            {item.transaction_id}
                                        </th>
                                        <td className="px-6 py-4">
                                            {item.card_number}
                                        </td>
                                        <td className="px-6 py-4">
                                            $ {item.amount}
                                        </td>
                                        <td className="px-6 py-4">

                                            {item.response_code == 0 && <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded">Pending</span>}
                                            {item.response_code == 1 && <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded">Success</span>}
                                            {item.response_code == 2 && <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded">Rejected</span>
                                            
                                            }
                                        </td>

                                        <td className="px-6 py-4 text-2xl text-center cursor-pointer" >
                                            {item.txn_save_to > 1 ? <TbZoomInArea className='mx-auto' onClick={() => hanldePaymentDetails(item.txn_save_to, item)}  /> : ""}

                                        </td>

                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    {paymentList?.total > 5 &&
                        <div className='pl-3 py-4'>
                            <Pagination
                                activePage={paymentList?.current_page ? paymentList?.current_page : 0}
                                itemsCountPerPage={paymentList?.per_page ? paymentList?.per_page : 0}
                                totalItemsCount={paymentList?.total ? paymentList?.total : 0}
                                onChange={(pageNumber) => {
                                    loadData(pageNumber)
                                }}
                                pageRangeDisplayed={8}
                                itemClass="page-item"
                                linkClass="page-link"
                                itemClassFirst="p-first-page-link"
                                itemClassLast="p-last-page-link"
                                activeClass="p-active"
                                activeLinkClass="p-active-link"
                                firstPageText="First Page"
                                lastPageText="Last Lage"
                            />
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default DirectPaymentList;