import React from 'react'

const CardIcons = () => {
  return (
    <div>
        <div className='card-bg-image'></div>
    </div>
  )
}

export default CardIcons