import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { AiOutlineMenu } from "react-icons/ai";

const RoobNavBar = () => {
    const [responsiveMenu, setResponsiveMenu] = useState('menu');
    const [toggleMenu, setToggleMenu] = useState(false);

    const menu = (e) => {
        setToggleMenu(!toggleMenu)
        // alert("test");
        let list = document.querySelector('ul');
        if (responsiveMenu === 'menu') {
            setResponsiveMenu("close");
            // list.classList.add('top-[80px]');
            // list.classList.add('opacity-100');
        }
        else {
            setResponsiveMenu("menu");
            // list.classList.remove('top-[80px]');
            // list.classList.remove('opacity-100');
        }


        // e.name === 'menu' ? (e.name = "close",list.classList.add('top-[80px]') , list.classList.add('opacity-100')) :( e.name = "menu" ,list.classList.remove('top-[80px]'),list.classList.remove('opacity-100'))
    }

    return (
        <>
        {/* maintainance banner  */}
        <div className="bg-red-500 text-center uppercase text-white text-md py-3">
            WEBSITE IS UNDER MAINTENANCE 2
        </div>
        {/* maintainance banner  */}
            <nav class="p-5 shadow md:flex md:items-center md:justify-between bg-gradient-to-r from-[#4d429e] to-[#242131]">
                <div class="flex justify-between items-center ">
                    <span class="text-2xl font-[Poppins] cursor-pointer">
                        <div id="header_logo" className="text-white">
                            <Link to="/" >
                                <div className="flex items-center justify-end">
                                    <img src="/img/logo-v3.png" alt="" class="mr-2 w-[100px]  md:w-[150px] " />
                                    {/* <span className="text-lg font-semibold text-white font-circular">Nexoz</span> */}
                                </div>
                            </Link>

                        </div>
                    </span>

                    <span class="text-3xl cursor-pointer mx-2 md:hidden block">
                        {/* <ion-icon name="menu" onclick="Menu(this)"></ion-icon> */}
                        <AiOutlineMenu className="text-white" onClick={() => setToggleMenu(!toggleMenu)} />
                    </span>
                </div>

                <ul class={`md:bg-none bg-gradient-to-r from-[#4d429e] to-[#242131]  md:flex md:items-center z-20 md:z-auto md:static absolute text-white w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 md:opacity-100  transition-all ease-in duration-300 top-[80px] pb-16 ${toggleMenu ? "opacity-100" : "opacity-0 "}  `}>
                    <li class="mx-4 my-6 md:my-0">
                        <Link to="/" class="text-lg hover:text-cyan-500 duration-500">Home</Link>
                    </li>
                    <li class="mx-4 my-6 md:my-0">
                        <a href="#" class="text-lg hover:text-cyan-500 duration-500">Contact us</a>
                    </li>
                    <li class="mx-4 my-6 md:my-0">
                        <a href="#" class="text-lg hover:text-cyan-500 duration-500">Sign up</a>
                    </li>
                    <Link to="/auth" class="text-white w-[100px]  border-2 font-semibold border-[#fff] rounded px-5 py-1 flex items-center hover:bg-white hover:text-[#242131] " href="/"  >Log in</Link>

                    {/* <button class="bg-cyan-400 text-white font-[Poppins] duration-500 px-6 py-2 mx-4 hover:bg-cyan-500 rounded ">
                        Login
                    </button> */}
                    <h2 class=""></h2>
                </ul>
            </nav>
        </>
    );
};

export default RoobNavBar;