import React from 'react';
import { Navigate } from 'react-router-dom';
import MultistepPurchaseCode from '../../components/HeroSection/MultistepPurchaseCode';
import Layout from '../../components/Layout/Layout';
import useAuth from '../../hooks/useAuth';

const PurchaseCode = () => {
    const auth = useAuth();
    return (
        auth ? <>
            <div className=''>
                <Layout>
                    {/* Purchase Code */}
                    <div className="flex justify-center items-center h-screen">

                        <MultistepPurchaseCode />
                    </div>

                </Layout>
            </div>
        </> : <Navigate to="/auth" />

    );
};

export default PurchaseCode;