import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import useAuth from '../../hooks/useAuth';

const CheckCodeBalance = () => {
    const [code, setCode]= useState('')

    const auth = useAuth();
    return (
        auth ? <>
            <div className=''>
                <Layout>
                    {/* <h1>Check Code Balance   </h1> */}
                    <div className="w-full">
                        <div className="w-full mt-20 flex flex-col  justify-center items-center">
                            {/* <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-full ml-5)">GIVE CODE HERE</h1> */}
                            <span className="text-xl md:text-3xl font-bold  md:md-0  ">GIVE CODE HERE</span>
                            <input value={code} onChange={(e) =>setCode( e.target.value)} type="number" placeholder="Type here" className="my-6 input input-bordered input-primary w-full max-w-xs" />
                            <button class="btn btn-outline btn-info">CHECK</button>
                        </div>
                        {/* <div className="text-right w-full mt-2">
                                <label htmlFor="" className="text-gray-600 font-medium" >* test</label>
                            </div> */}
                    </div>


                </Layout>
            </div>
        </> : <Navigate to="/auth" />

    );

};

export default CheckCodeBalance;