import React from 'react';
import { Route, Routes } from "react-router-dom";
import PurchaseDetails from '../components/HeroSection/MultiStepPurchase/PurchaseDetails';
import ProtectedRoutes from '../hooks/ProtectedRoutes';
// import Profile from './Auth/Profile/Profile';
import Signup from './Auth/Signup/Signup';
import CheckCodeBalance from './CheckCodeBalance/CheckCodeBalance';
import DevTest from './DevTest';
import AddGiftCard from './GiftCard/AddGiftCard/AddGiftCard';
import EditGiftCard from './GiftCard/EditGiftCard/EditGiftCard';
import GiftCards from './GiftCard/GiftCards/GiftCards';
import Homepage from './Homepage/Homepage';
import NoPageFound from './NoPageFound/NoPageFound';
import DashboardNexoz from './Private/DashboardNexoz/DashboardNexoz';
import DirectPaymentList from './Private/DirectPaymentModule/DirectPaymentList';
import DirectPaymentModule from './Private/DirectPaymentModule/DirectPaymentModule';
import TransactionSaveToExistingUser from './Private/DirectPaymentModule/TransactionSaveToExistingUser';
import AddGiftCardOffer from './Private/GiftCardOffer/AddGiftCardOffer/AddGiftCardOffer';
import EditGiftCardOffer from './Private/GiftCardOffer/EditGiftCardOffer/EditGiftCardOffer';
import GiftCardOffers from './Private/GiftCardOffer/GiftCardOffers/GiftCardOffers';
import KycInfo from './Private/KycInfo/KycInfo';
import Profile from './Private/Profile/Profile';
import SellerLists from './Private/SellerLists/SellerLists';
import ProductStocks from './Private/Stores/ProductStocks/ProductStocks';
import StoreCard from './Private/Stores/StoreCard';
import MyTransactions from './Private/Transaction/Transactions/MyTransactions';
import PaymentMethods from './Private/WithDraw/PaymentMethods/PaymentMethods';
import WithdrawList from './Private/WithDraw/WithdrawList/WithdrawList';
import WithdrawRequest from './Private/WithDraw/WithdrawRequest/WithdrawRequest';
import AffliateDisclosure from './public/AffliateDisclosure/AffliateDisclosure';
import FinancialDisclosureNotice from './public/FinancialDisclosureNotice/FinancialDisclosureNotice';
import PrivacyPolicy from './public/PrivacyPolicy/PrivacyPolicy';
import TermOfUse from './public/TermOfUse/TermOfUse';
import TermsOfSale from './public/TermsOfSale/TermsOfSale';
import Transaction from './Purchase/Transaction/Transaction';
import PurchaseCode from './PurchaseCode/PurchaseCode';
import PurchaseHistory from './PurchaseHistory/PurchaseHistory';
import ReedemCode from './ReedemCode/ReedemCode';
import OpenPageTest from './test/OpenPageTest';
import PrivatePageTest from './test/PrivatePageTest';
import CreateUser from './UserManagement/CreateUser/CreateUser';
import Customer from './UserManagement/Customer/Customer';
import EditUser from './UserManagement/EditUser/EditUser';
import Users from './UserManagement/Users/Users';
import Withdraw from './Withdraw/Withdraw';
import HomepageTwo from './Homepage/HomepageTwo';
function Master() {

  return (
    <div>

      <div className=''>

        <Routes>

          {/* public pages  */}
          <Route path="/" element={<HomepageTwo />} />
          <Route path="/home" element={<Homepage />} />


          <Route path="/login" element={<Signup />} />
          <Route path="/auth" element={<Signup />} />
          <Route path="/dev-test2" element={<DevTest />} />
          <Route path="/test-public" element={<OpenPageTest />} />

          {/* Informative Pages */}
          <Route path="/term-of-use" element={<TermOfUse />} />
          <Route path="/term-of-sale" element={<TermsOfSale />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/financial-disclousure" element={<FinancialDisclosureNotice />} />
          <Route path="/affliate-disclousure" element={<AffliateDisclosure />} />
          {/* Informative Pages // */}
          
          {/* public pages  end */}
          {/* protection doone  */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/test-private" element={<PrivatePageTest />} />


            {/* store Section */}
            <Route path="/stores" element={<StoreCard />} />
            <Route path="/store-stocks/:skus" element={<ProductStocks />} />
            {/* store Section End*/}
            

            
            {/* Customer Pages */}
            <Route path="/purchase-code" element={<PurchaseCode />} />
            <Route path="/purchase-history" element={<PurchaseHistory />} />
            <Route path="/purchase-details/:id" element={<PurchaseDetails />} />
            <Route path="/check-code-balance" element={<CheckCodeBalance />} />
            <Route path="/reedem-code" element={<ReedemCode />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/transactions" element={<Transaction />} />
            {/* Customer Pages */}

            <Route path="/dashboard" element={<DashboardNexoz />} />
            <Route path="create/user" element={<CreateUser />} />
            <Route path="edit/user/:id" element={<EditUser />} />
            <Route path="users" element={<Users />} />
            <Route path="sellers" element={<SellerLists />} />
            <Route path="customers" element={<Customer />} />


            <Route path="gift-cards" element={<GiftCards />} />
            <Route path="add-gift-cards" element={<AddGiftCard />} />
            <Route path="gift-card/:id" element={<EditGiftCard />} />

            {/* <Route path="sellers" element={<SellerLists />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="create-transaction" element={<CreateTransaction />} />
            <Route path="edit-transaction/:id" element={<EditTransaction />} /> */}

            {/* <Route path="my-transaction" element={<MyTransactions />} /> */}


            <Route path="gift-card-offer" element={<GiftCardOffers />} />
            <Route path="add-gift-card-offer" element={<AddGiftCardOffer />} />
            <Route path="edit-gift-card-offer/:id" element={<EditGiftCardOffer />} />
            <Route path="kyc" element={<KycInfo />} />

            <Route path="my-transactions" element={<MyTransactions />} />
            <Route path="withdraw-request" element={<WithdrawRequest />} />
            <Route path="withdraw-history" element={<WithdrawList />} />


            <Route path="/dev-test" element={<DevTest />} />



            {/* Profile  */}
            <Route path="/payment-methods" element={<PaymentMethods />} />
            {/* Profile  */}

            {/* Manager/Admin Pages */}
            <Route path="/direct-payment" element={<DirectPaymentModule />} />
            <Route path="/direct-payment-list" element={<DirectPaymentList />} />
            <Route path="/temp1" element={<TransactionSaveToExistingUser />} />
            {/* Manager/Admin Pages End */}


          </Route>

          {/* // 404 page  */}
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </div>

    </div>
  );
}

export default Master;