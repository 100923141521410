import React from 'react';
import Layout from '../../../components/Layout/Layout';

const KycInfo = () => {
    return (
        <Layout>
            <div>




            </div>
            <div className="relative max-w-md mx-auto md:max-w-2xl mt-2 md:mt-16 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl ">
                <div className="p-5">
                    <h1>General Profile </h1>
                    <p>
                        Daily Limit : $200<br />
                        Lifetime Limit : $1000
                    </p>
                </div>
                <div className="p-5">
                    <h1>Verified General Profile </h1>
                    <p>
                        Daily Limit : <br />
                        Monthly Limit : $20000
                    </p>
                </div>
                <div className="p-5">
                    <h1>Business Profile </h1>
                    <p>
                        Daily Limit : <br />
                        Monthly Limit : $40000
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default KycInfo;