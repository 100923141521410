import React from 'react'

const HeroSectionTwo = () => {
  const test = true;
  return (
    <div>

      {test &&
        <div className="px-2 pt-10 pb-5 md:p-10 w-full bg-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

            {/* Section 1  */}
            <section className="order-last md:order-first  bg-white flex flex-col	justify-between">
              <p className=" font-raleway text-xl md:text-4xl font-bold text-gunmetal   text-left">Nexoz Solutions enhances connections with fans, employees, clients, and customers on B2B and B2C levels.</p>
              <p className=" font-raleway text-sm md:text-lg font-thin text-gunmetal  text-left pt-4 pb-2">Nexoz's worldwide payment and incentives network enhances business
                performance by driving customer engagement, strengthening employee
                loyalty, and boosting revenue.</p>

              <div className=" flex flex-col-reverse md:flex-row items-center  pt-6 md:pt-10 justify-center md:justify-start">
                <div className="pt-0 md:pt-4 md:pt-0">
                  <a class="font-raleway bg-[#1C96A0]  text-white text-lg px-8 py-2 rounded-full " href="tel:8304762275">Call 830.476.2275</a>
                </div>
              </div>

            </section>
            {/* Section 1 END */}


            {/* Section 2 */}
            {/* Section 2 END */}

            {/* left sidebar  end*/}
            {/* right sidebar  */}
            {1 === 1 && <section className="bg-[#D4EFEF] rounded-lg flex justify-center items-center		">
            {/* {1 === 1 && <section className="bg-[#D4EFEF] dark:bg-[#1C96A0] rounded-lg flex justify-center items-center		"> */}
              {/* <CardRightSide/> */}
              {/* <img src="/img/logo-v4-big.png" alt="" className="w-[473px] h-[234px] " /> */}
              <div className=' '>
                <img src="/img/logo-v4-big.png" alt="" className="w-[380px] h-[189px]  md:w-[473px] md:h-[234px]" />
              </div>
              {/* <img src="/img/logo-v4-big.png" alt="" className="w-[450px] h-[159px] " /> */}
            </section>}
            {/* right sidebar  end*/}


          </div>

        </div>}

      {/* <BecomeNexBoss /> */}

    </div>
  )
}

export default HeroSectionTwo