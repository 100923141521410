import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import SpendLimits from '../../../components/SpendLimits/SpendLimits';
import WalletWidget from '../../../components/WalletWidget/WalletWidget';
import useAuth from '../../../hooks/useAuth';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';



const DashboardNexoz = () => {

    const auth = useAuth();
    const dispatch = useDispatch();
    const authUserPermission = useSelector((state) => state.authUserPermission);

    const [giftCardOfferList, setGiftCardOfferList] = useState({});

    const storeList = [
        { name: 'Lyft B2B', status: 1, earnings: "2%" },
        { name: 'Steak n Shake', status: 1, earnings: "2%" },
        { name: '1-800-petsupplies', status: 1, earnings: "2%" },
        { name: "Beat's Coffee & Tea", status: 1, earnings: "2%" },

    ];
    const recentPurchase = [
        { name: 'PlayStation Store', pdate: '07/08/22', earnings: "$10.00", earned: "$0.40" },
        { name: 'PlayStation Store', pdate: '07/08/22', earnings: "$10.00", earned: "$0.40" },
        { name: 'PlayStation Store', pdate: '07/08/22', earnings: "$10.00", earned: "$0.40" },
        { name: 'PlayStation Store', pdate: '07/08/22', earnings: "$10.00", earned: "$0.40" },


    ];

    useEffect(()=>{
        loadAvailableCardList();
    },[])

    const loadAvailableCardList = (pageNumber = 1) => {
        dispatch(isLoadingFullPageUpdate({status:true,msg:"Loading..."}));
        axios

            .get(baseApiUrl() + "gift-card-offer?page=" + pageNumber)
            .then((res) => {

               
                setGiftCardOfferList(res.data);
                // console.log(test2)

            })
            .catch((err) => {
               
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({status:false,msg:"Deleting..."}));
            })


    };


    // table style 
    const divTableRow = ""
    // table style 


    return (
        auth ? <>
            <div className=''>
                <Layout>
                    {/* dashboard_card */}
                    <section id="dashboard_card" className="flex flex-col md:flex-row ">
                        <div className="w-full md:w-5/12">
                            <SpendLimits />
                        </div>
                        <div className="w-1/12 h-[20px]">
                        </div>
                        <div className="w-full md:w-5/12">
                            <WalletWidget />
                        </div>
                    </section>
                    {/* dashboard_card end */}
                    {/* Details */}
                    <section id="dashboard_stats" className="flex flex-col md:flex-row mt-[50px]">
                        <div className="bg-[#4d429e] rounded-2xl    text-white font-thin w-full md:w-5/12 md:drop-shadow-xl">
                            <h3 className="py-7 pl-[40px]">Gift Card Offer List</h3>
                            <div className="h-[400px] w-full overflow-auto">

                                <table className="w-full ">
                                    <tr className="bg-[#4A456C] text-left border-y-2  ">
                                        <th className="py-2 w-[40%] pl-[40px]">CARD NAME</th>
                                        <th className="w-[40%]  pl-5">Status</th>
                                        <th className="w-[20%]">Rate</th>
                                    </tr>
                                    {
                                        giftCardOfferList?.data?.map(giftCardDetails => <tr className="pt-2 font-sans text-sm font-medium	">
                                            <td className="pl-[40px] py-5 text-xs md:text-lg"> {giftCardDetails.title} </td>
                                            <td className="text-sm md:text-md pl-5">{giftCardDetails.is_active == 1 ? "Active" : "Maintenance"} </td>
                                            <td>{giftCardDetails.rate}</td>
                                        </tr>)
                                    }
                                </table>
                            </div>

                        </div>
                        <div className="w-1/12 h-[20px]">

                        </div>
                        <div className="bg-gradient-to-r from-[#4d429e] to-[#242131] rounded-2xl   flex flex-col  justify-center  text-white font-thin w-full md:w-5/12 md:drop-shadow-xl">
                            <h3 className="py-7 pl-[40px]">Recent purchase</h3>
                            <div className="h-[400px] w-full overflow-auto">

                                <table className="w-full ">
                                    <tr className="bg-[#4A456C] text-left border-y-2  ">
                                        <th className="py-2 w-[40%] pl-[40px]">Store name:</th>
                                        <th className="w-[40%]">Interest rate:</th>
                                        <th className="w-[20%]"></th>
                                    </tr>
                                    {
                                        recentPurchase.map(store => <tr className="pt-2 font-sans text-sm font-medium 	">
                                            <td className="pl-[40px] py-5 "> {store.name} <br /><span className="text-xs">{store.pdate} </span> </td>
                                            <td className=" text-xs text-right pr-10"> {store.earnings} <br /><span className="text-xs">{store.earned} </span> </td>

                                            <td className=""> <span> <IoCheckmarkCircleOutline className="inline-block" /> Buy now</span> </td>
                                        </tr>)
                                    }




                                </table>
                            </div>

                        </div>
                    </section>
                    {/* Details end */}
                </Layout>
            </div>
        </> : <Navigate to="/login" />
    );
};

export default DashboardNexoz;