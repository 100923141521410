
import React, { useState } from 'react';

const IconContainer = () => {
    return <div className='pt-10 pb-10 bg-white '>
        <div className='mx-auto	 grid grid-cols-3 gap-10 md:gap-0 md:grid-cols-7 items-center  md:grid-flow-col 		'>
            {/* <div className='flex flex-row justify-around	items-center		'> */}
            <div className=''> <img src="/img/icon/icon-ebay.png"></img> </div>
            <div className='justify-center'> <img src="/img/icon/icon-google-play.png"></img> </div>
            <div className='justify-center'> <img src="/img/icon/icon-tmobile.png"></img> </div>

            <div className='justify-center'> <img src="/img/icon/icon-red-circle.png"></img> </div>
            <div className='justify-center'> <img src="/img/icon/icon-disney.png"></img> </div>
            <div className='justify-center'> <img src="/img/icon/icon-amazon.png"></img> </div>

            <div className='justify-center'> <img src="/img/icon/icon-best-buy.png"></img> </div>



        </div>
    </div>
}

export default IconContainer; 