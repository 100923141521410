import React, { useState } from 'react';

const SpendLimits = () => {
    const [dailyLimit, setDailyLimit] = useState(200);
    const [monthlyLimit, setMonthlyLimit] = useState(0);
    const [lifetimeLimit, setLifetimeLimit] = useState(1000);
    const [kycProfileLevel, setKycProfileLevel] = useState(1);
    return (

        
        <div className="bg-gradient-to-r from-[#4d429e] to-[#242131] rounded-2xl h-[300px]  flex flex-col  justify-center pl-[40px] text-white font-thin w-full md:drop-shadow-xl">
            <h3 className="mb-3">Spend limits</h3>
            <p>
                $ {dailyLimit}   <br />
                Daily limit <br />
                {kycProfileLevel === 1 ? <>
                    $ {lifetimeLimit} <br />
                    Lifetime Limit<br />
                </> : <>

                    $ {monthlyLimit} <br />
                    Monthly Limit <br />
                </>

                }


                KYC Profile Level  {kycProfileLevel}


            </p>
        </div>
    );
};

export default SpendLimits;