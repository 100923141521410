import React from 'react'

const PaymentCardSolution = () => {
    return (
        <section className='flex flex-col md:flex-row font-raleway'>
            <div className='flex-1 flex flex-col justify-center'>
                {/* col 1   */}
                <div className='p-5 md:p-14'>
                    <p className='h2 text-[#0A2224] '>Payment card solutions has never been easier</p>
                    <p className='description mt-5'>Easily reward and build solutions with our wide range of payment cards spanning across all major currencies and card brands, such as Mastercard, Visa, AmericanExpress etc.</p>
                </div>

            </div>
            <div className='flex-1  bg-[#D4EFEF] rounded-xl flex justify-center item-center ' >
                <img src="img/v2/gift-poss.png" className='p-10'></img>
                {/* col 2   */}
            </div>

        </section>
    )
}

export default PaymentCardSolution