import React from 'react';
import { Link } from 'react-router-dom';
import './nexfooter.css';

const FooterTwo = () => {
    const menuTitle = "text-2xl font-semibold   mb-3 uppercase "
    const menuLink = " text-md pb-1 "
    const menuLinkTextColor = "text-[#726d9a]"
    const menuLinkTextColorWhite = "text-[#4C3A9E] md:text-gray-300"
    return (
        <>
            <section className="bg-[#0A2224] flex space-arround flex-col md:flex-row py-[50px] md:pt-[100px]  md:pb-[100px] gap-6 text-white" >
                <div className='flex-1 md:pl-4 '>
                    <img className='pb-3 ' src="/img/logo-nexoz-white.png"></img>
                    <p className='font-raleway'>
                        Nexoz solutions help you build solutions that help you connect better with fans, employees, clients and customers on both b2b and b2c levels
                    </p>
                </div>
                <div className='flex-1 '>
                    <h3 className='font-raleway pb-3 font-semibold	'>Contact Us</h3>
                    <p className='font-raleway'>
                        830 476 2275 <br></br>
                        5718 Westheimer Rd, Suite 1000<br></br>
                        Houston, Texas 77057
                    </p>
                </div>
                <div className='flex-1 '>
                    <h3 className='font-raleway pb-3 font-semibold	'>Email</h3>
                    <p className='font-raleway'>
                        Sales@nexoz.net
                    </p>
                </div>
            </section>
            <section className='flex flex-col md:flex-row items-center justify-center  pb-[50px] font-raleway text-[#839091]'>
                {/* footer copywrite  */}
                <div>© 2022 NexOz llc. All rights reserved</div>
                {/* <div>Terms & Conditions</div> */}
            </section>
            

        </>
    );
};

export default FooterTwo;