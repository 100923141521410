import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TbCircleDotted } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../../components/Layout/Layout';
import baseApiUrl from '../../../../utility/baseApiUrl';
import responseErrorManage from '../../../../utility/responseErrorManage';


const WithdrawRequest = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate()
    const loggedinUserInfo = useSelector((state) => state.authInfo.user);





    const [formTitle] = useState('Withdraw Request');




    // States 

    const [availableBalance, setAvailableBalance] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [userPaymentDetails, setUserPaymentDetails] = useState({});

    const [paymentMethodLabel, setPaymentMethodLabel] = useState('');
    const [paymentMethodValue, setPaymentMethodValue] = useState('');






    // States //

    const [paymentMethodList, setPaymentMethodList] = useState(["Paypal", "Bank Account", "USDT"]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [isSmallLoaderOn, setIsSmallLoaderOn] = useState(true);


    const [description, setDescription] = useState(''); // changeHere - stateName correction





    const onSubmit = e => {
        e.preventDefault();
        submitForm();
    }

    const submitForm = () => {

        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });


        let data = new FormData();
        data.append('payment_method', selectedPaymentMethod);
        data.append("withdraw_amount", withdrawAmount);
        data.append("description", description);



        axios.post(baseApiUrl() + 'gift-card', data).then(res => {

            console.log(res)

            Toast.fire({
                icon: 'success',
                title: res.data.msg
            });

            dispatch({
                type: "fullPageLoader_update",
                payload: false
            });

            resetForm();
            navigate("/gift-cards");

        }).catch((err) => {

            dispatch({
                type: "fullPageLoader_update",
                payload: false
            });

            console.log(err);

            Toast.fire({
                icon: 'error',
                title: 'server error'
            });
        });

    }


    const resetForm = () => {

        setDescription('');
        selectedPaymentMethod('');
        setWithdrawAmount('');


    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const getPaymentMethodDetails = () => {
        setIsSmallLoaderOn(true);
        axios
            .get(baseApiUrl() + `payment-method/${loggedinUserInfo.id}`)
            .then(res => {

                const resData = res.data.data;
                console.log("getPaymentMethodlist", resData);
                setUserPaymentDetails(resData);


            })
            .catch(error => {
                console.log(error)
                responseErrorManage(error)

            })
            .finally(() => {
                setIsSmallLoaderOn(false);
            })
    }

    const switchPaymentMethodInfo = () => {

        switch (selectedPaymentMethod) {
            case "Paypal":
                setPaymentMethodLabel("Paypal")
                setPaymentMethodValue(userPaymentDetails.paypal)
                break;
            case "Bank Account":
                setPaymentMethodLabel("Bank Account")
                setPaymentMethodValue(userPaymentDetails.bank_details)
                break;
            case "USDT":
                setPaymentMethodLabel("USDT")
                setPaymentMethodValue(userPaymentDetails.usdt)
                break;

            default:
                setPaymentMethodLabel("")
                setPaymentMethodValue("")
                break;
        }
    }

    useEffect(() => {
        getPaymentMethodDetails()
        getBalance();
    }, [])
    useEffect(() => {
        switchPaymentMethodInfo()

    }, [selectedPaymentMethod])



    const getBalance = () => {


        axios.get(baseApiUrl() + 'get-balances')
            .then(res => {
                console.log("test_get_balances", res.data.data);
                // setSellerDue(res.data.due);
                const responseData = res.data.data;
                setAvailableBalance(responseData.total_paid);


            })

    }

    return (
        <div>
            <Layout>

                <div>
                    <div className='lg:px-24 gap-10'>
                        <div className='w-full bg-white rounded-md border shadow' >
                            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                                <h3>{formTitle}</h3>
                                <Link to={'/gift-cards'}>
                                    <button className='button__bg px-6 py-2 block rounded'>Back To Lists</button>
                                </Link>
                            </div>
                            <form onSubmit={onSubmit} className='py-5 px-8'>
                                <div className="mb-10">
                                    <span className="p-2 bg-gray-200 rounded-md">
                                        Available Balance : $ {availableBalance}
                                    </span>

                                </div>
                                <div className=''>

                                    <div className=''>
                                        <div className="w-full mb-6">
                                            <div className="flex flex-col md:flex-row items-center">

                                                <label className="form-label">Select Payment Method</label>
                                                <select onChange={e => setSelectedPaymentMethod(e.target.value)} value={selectedPaymentMethod}
                                                    className="select-field w-8/12 mx-5" required>
                                                    <option value="">Select</option>
                                                    {paymentMethodList?.map((item, index) => {
                                                        return (
                                                            <option key={item} value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select>
                                                {
                                                    isSmallLoaderOn &&
                                                    <div className="1/12">
                                                        <div className="text-2xl ml-5 animate-ping">
                                                            {/* <TbRotate2 /> */}
                                                            <TbCircleDotted />
                                                            {/* <TbCircleDot/> */}
                                                        </div>

                                                    </div>
                                                }

                                            </div>




                                            <div className='mt-5 flex gap-4 flex-wrap'>

                                                {/* <div>
                                                    {giftCardItem?.price_type == 0 && 'Price Type : Range'}
                                                    {giftCardItem?.price_type == 1 && 'Price Type : Fixed'}
                                                </div> */}



                                            </div>


                                        </div>


                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2 bg-gray-100 p-4 rounded-lg">
                                            <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> {paymentMethodLabel}</label>
                                            <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> {paymentMethodValue}</label>
                                            {/* <input value={paymentMethodValue} disabled type="text" placeholder="" className="form-field" required /> */}
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2 bg-gray-100 p-4 rounded-lg">
                                            <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Withdraw Amount</label>
                                            <div className="flex items-center">
                                                <span className="mx-2">$</span>
                                                
                                                <input value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} type="number" placeholder="write withdraw amount here" className="form-field border-2 border-blue-400" required />
                                            </div>

                                        </div>


                                        <div className='mt-2 w-full'>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Note</label>
                                            <textarea className="mb-5 form-field" rows="3" cols="50"
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                            />
                                        </div>

                                    </div>

                                    <div className='flex flex-wrap gap-3 '>
                                        <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                                        <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                                    </div>
                                </div>


                            </form>

                        </div>

                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default WithdrawRequest;