import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import baseApiUrl from '../../utility/baseApiUrl';

const WalletWidget = () => {
    const [availableBalance, setAvailableBalance] = useState(0);
    const authUserPermission = useSelector((state) => state.authInfo.permission);
    const [sellerDue, setSellerDue] = useState(0);
    
    // const availableBalance = useSelector((state) => state.authInfo.availableBalance);
    const getBalance = () => {


        axios.get(baseApiUrl() + 'get-balances')
           .then(res => {
              console.log("test_get_balances", res.data.data);
              // setSellerDue(res.data.due);
              const responseData = res.data.data; 
              setAvailableBalance(responseData.total_paid);
              setSellerDue(responseData.due);
  
           })
  
     }
     useEffect(() => {
        console.log("authUserPermission_topbar",authUserPermission)
        if (authUserPermission === 'seller') {
           getBalance();
        }
  
     }, [])
    return (
        <div className="bg-gradient-to-r from-[#4d429e] to-[#242131] rounded-2xl h-[300px]  flex flex-col  justify-center pl-[40px] text-white font-thin w-full md:drop-shadow-xl">
            {/*      */}
            <h3 className="mb-3">Wallet</h3>
            <p>
                $ {availableBalance}   <br />
                Available balance<br />
                $ {sellerDue} <br />
                Due Balance


            </p>
        </div>
    );
};

export default WalletWidget;