import { useSelector } from "react-redux";

export default function useAuth(){
    const isLoggedIn = useSelector(state => state.authInfo.status);
    // const isLoggedIn = useSelector(state => state.userLoginStatus);
    console.log("isLoggedIn", isLoggedIn)

    // check in localstorage 
    
    // check in localstorage 

    // console.log("test-useAuth")
    const auth = isLoggedIn ; 
    return auth ; 
}