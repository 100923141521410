import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay,  Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './swiper-slider.css'

const SwipperSlider = () => {
    return (
        <Swiper
            modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
            autoplay={{
                delay: 1500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            }}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            navigation

            pagination={{ clickable: true }}
            // pagination={{ el: '.my-swiper-pagination', clickable: true }}

            scrollbar={{ draggable: true }}

        >
            <SwiperSlide> <img className='mt-10' src="/img/v2/carousel-1-item.png"></img></SwiperSlide>
            <SwiperSlide> <img className='mt-10' src="/img/v2/carousel-2-item.png"></img></SwiperSlide>
            <SwiperSlide> <img className='mt-10 p-5' src="/img/v2/carousel-3-item-2.jpeg"></img></SwiperSlide>

        </Swiper>
    )
}

export default SwipperSlider