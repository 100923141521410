import React from 'react';
import { Link } from 'react-router-dom';
import './nexfooter.css';

const NexozFooter = () => {
    const menuTitle = "text-2xl font-semibold   mb-3 uppercase "
    const menuLink = " text-md pb-1 "
    const menuLinkTextColor = "text-[#726d9a]"
    const menuLinkTextColorWhite = "text-[#4C3A9E] md:text-gray-300"
    return (
        <>
            <section className=" relative border-y-2 border-[#4C3A9E] w-full h-screen md:h-[500px] overflow-hidden    bg-white">

                <div id="footer_menu" className="z-20  absolute flex flex-col justify-start md:flex-row px-10   w-full h-full ">
                    <div className="w-full md:w-3/12  mt-10 md:mt-40 pl-[20px] md:pl-0">
                        <h2 className={`${menuTitle} text-[#4C3A9E]`}>LEGAL</h2>
                        <ul className="font-medium">

                            <li><Link to='/term-of-use' className={`${menuLink} ${menuLinkTextColor}`} >Term of Use </Link></li>
                            <li><Link to='/term-of-sale' className={`${menuLink} ${menuLinkTextColor}`} >Term of Sale</Link></li>
                            
                            <li><Link to='/privacy-policy' className={`${menuLink} ${menuLinkTextColor}`} >Privacy Policy</Link></li>
                            <li><Link to='/financial-disclousure' className={`${menuLink} ${menuLinkTextColor}`} >Financial Disclousure</Link></li>
                            <li><Link to='/affliate-disclousure' className={`${menuLink} ${menuLinkTextColor}`} >Affliate Disclousure</Link></li>
                        </ul>
                    </div>

                  
                    <div className="w-full md:w-3/12 mt-10  md:mt-40 text-white pl-[20px] md:pl-0">
                        <h2 className={`${menuTitle} text-[#4C3A9E] md:text-white`}>LOCATION</h2>
                        <ul className="font-medium">
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `} >Houston Galleria / Westheimer</li>
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `}>5718 Westheimer Rd, Suite 1000</li>
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `}>Houston, Texas 77057</li>
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `}>support@nexoz.net</li>
                            
                        </ul>

                    </div>
                    {/* <div className="w-full md:w-3/12 mt-10  md:mt-40 text-white pl-[20px] md:pl-0">
                        <h2 className={`${menuTitle} text-[#4C3A9E] md:text-white`}>Overview</h2>
                        <ul className="font-medium">
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `} >About</li>
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `}>Careers</li>
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `}>Press</li>
                            <li className={`${menuLink} ${menuLinkTextColorWhite} `}>Contact</li>
                        </ul>

                    </div> */}
                    <div className="md:w-3/12 w-full">

                    </div>
                    <div className=" w-full md:w-3/12 md:flex items-end justify-end mb-5 mt-[100px]">
                        <div className="flex items-center justify-end">
                            <img src="/img/logo-v3-dark.png" alt="" class="mr-2 w-[150px] hidden md:block " />
                            <img src="/img/logo-v3.png" alt="" class="mr-2 w-[150px] block md:hidden" />
                            
                            {/* <img src="/img/logo-sm.png" alt="" class="mr-2 w-[50px] " /> */}
                            {/* <span className="text-lg font-semibold  text-white md:text-[#494662]">Nexoz</span> */}
                        </div>
                    </div>

                </div>
                <div id="footer_skew" className=" absolute h-full  left-[277px] md:left-[270px] w-full md:w-[400px] bg-gradient-to-b from-[#4d429e] to-[#43405e]"></div>
                {/* <div id="footer_skew" className="absolute translate-x-20 skew-x-12 w-[400px] h-[500px] bg-[#4C3A9E]"></div> */}
            </section>
            <div className="bg-white">
                <p className="text-sm md:text-lg text-center flex items-center justify-center pt-8 pb-12 text-[#726d9a] flex-col md:flex-row">
                    <span>©2022 Nexoz llc. All Rights Reserved. </span>
                    <div className="flex items-center justify-center mt-1 md:mt-0">
                        <img src="/img/usa-flag.png" alt="" class="mx-2 w-[20px] inline-block " />
                        <span>Based In The USA</span>
                    </div>

                </p>
            </div>
        </>
    );
};

export default NexozFooter;