import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loggedOut } from "../../../redux/slices/userSlice";
import './style.css';
const TopNavBar = () => {
   let navigate = useNavigate();
   const authUserPermission = useSelector((state) => state.authInfo.permission);
   const loggedinUserInfo = useSelector((state) => state.authInfo.user);
   const [sellerDue, setSellerDue] = useState(0);
   const [availableBalance, setAvailableBalance] = useState(0);
   const [pendingBalance, setPendingBalance] = useState(0);
   
   const dispatch = useDispatch();
   const handleLogoutOnClik = () => {
      localStorage.clear();
      // logout();
      dispatch(loggedOut())
      // dispatch({
      //    type: "login_status_update",
      //    payload: false
      // })
      // dispatch({
      //    type: "loggedinUserInfo_update",
      //    payload: {}
      // })
      dispatch({
         type: "tokenValue_update",
         payload: ""
      })
      //     alert("hello")

      // dispatch(loggedInUser(true))
      // setOpen(false);
      navigate("/");
   };
   function loadBody() {

      // const menuicon = document.getElementById("menuicon");
      // const phone_menu_icon = document.getElementById("phone_menu_icon");

      const sidebar = document.getElementById("sidebar");

      // const activeMenu = document.querySelector(".active__menu");

      // menuicon.addEventListener("click", function() {
      sidebar.classList.toggle('toggle__Sidebar');
      // });

      // phone_menu_icon.addEventListener("click", function() {
      //   sidebar.classList.toggle('toggle__phone__Sidebar');
      // });
   }
   function loadBodyMobile() {

      // const menuicon = document.getElementById("menuicon");
      // const phone_menu_icon = document.getElementById("phone_menu_icon");

      const sidebar = document.getElementById("sidebar");

      // const activeMenu = document.querySelector(".active__menu");

      // menuicon.addEventListener("click", function() {
      //   sidebar.classList.toggle('toggle__Sidebar');
      // });

      // phone_menu_icon.addEventListener("click", function() {
      sidebar.classList.toggle('toggle__phone__Sidebar');
      // });
   }

   // const getBalance = () => {


   //    axios.get(baseApiUrl() + 'get-balances')
   //       .then(res => {
   //          console.log("test_get_balances", res.data.data);
   //          // setSellerDue(res.data.due);
   //          const responseData = res.data.data;
   //          setAvailableBalance(responseData.total_paid);
   //          setSellerDue(responseData.due);

   //       })

   // }
   useEffect(() => {
      console.log("authUserPermission_topbar", authUserPermission)
      if (authUserPermission === 'seller') {
         // getBalance();
      }

   }, [])



   return (
      <>
         <div className="bg-gradient-to-r from-[#4d429e] to-[#242131] flex justify-between items-center px-5 py-6">
            <div className="flex justify-between lg:gap-28 md:gap-16 gap-8 items-center ">

               {/* logo */}
               <div className="  ">
                  <Link to={'/'}>
                     {/* <img width="95px" height="60px" src="/img/logo.svg" alt="PAKBET TV" /> */}
                     <img width="95px" height="60px" src="/img/logo-v3.png" alt="Nexoz" />
                     {/* <img src="/img/logo-v2.png" alt="" class="mr-2 w-[100px]  md:w-[150px] " /> */}
                     {/* <img width="95px" height="60px" src="https://ozchest.com/static/media/Logo.e1bbcb99de4ca7b833d1.PNG" alt="PAKBET TV" /> */}
                     {/* <div className="w-[95px] pl-1 md:pl-2 py-2 md:py-5">
                        <img src="/img/logo.svg" alt="" />
                    </div> */}
                    
                  </Link>
               </div>



               {/* <div className="flex items-center gap-5 md:hidden">
                  <div onClick={loadBody} id="menuicon" className=" lg:block md:block  hidden  text-lg cursor-pointer light__bg rounded-md p-2">
                     <AiOutlineMenu />
                  </div>

                  <div onClick={loadBodyMobile} id="phone_menu_icon" className="lg:hidden md:hidden  block text-lg cursor-pointer light__bg rounded-md p-2">
                     <AiOutlineMenu />
                  </div>
               </div> */}

            </div>

            {
               // <div className="hidden md:flex pb-3 px-5 font-bold items-center   ">
               //    <div className="flex justify-between items-center ">
               //       <span className="text-[#95A3AF]">Available Balance</span>

               //       <div className="badge badge-success  ml-2">$ {availableBalance}</div>
               //    </div>
               //    <div className="flex justify-between items-center  ml-10">
               //       <span className="text-[#95A3AF]">Due</span>

               //       <div className="badge ml-2">$ {sellerDue}</div>
               //    </div>

               // </div>
            }

            <section className="text-[#95A3AF] text-lg flex items-center">
               <div>
                  <h3 className="uppercase text-right text-sm"> {loggedinUserInfo?.name} <span className="text-md"> | Balance : ${loggedinUserInfo?.balance} </span> </h3>
                  {/* <p className="text-right text-md">
                     <span>Balance : $100</span>
                  </p> */}
                  <p className="text-right">
                     <a href="#" onClick={handleLogoutOnClik} className="text-sm text-right hover:text-white">Logout</a>
                  </p>

               </div>
               <div className="h-[50px] w-[50px] border-2  rounded-full ml-[10px]">
                  <Link to="/profile">
                     <img src={`https://ui-avatars.com/api/?length=1&background=4d429e&color=fff&name=${loggedinUserInfo?.name}`} className="rounded-full" alt="" />

                  </Link>
               </div>





            </section>

            {/* <div className="flex items-center gap-5 ">

               <div className="light__bg rounded-md p-2 text-xl">
                  {loggedinUserInfo?.name}
               </div>
               {authUserPermission === 'seller' && <span>
                  Due: {sellerDue}
               </span>}

               <button onClick={handleLogoutOnClik} className="light__bg rounded-md p-2">

                  <FiLogOut />
               </button>
            </div> */}

         </div>
         {/* <div className="md:hidden pb-3 px-5 font-bold ">
            <div className="flex justify-between">
               Available Balance
               <div className="badge badge-success">45000</div>
            </div>
            <div className="flex justify-between">
               Pending Balance
               <div className="badge">30000</div>
            </div>

         </div> */}

      </>
   );
};

export default TopNavBar;
