import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Layout from '../../../components/Layout/Layout';
import { updateIsShowCardInput } from '../../../redux/slices/CreditCardSlice';
import CreditCardSwapDirect from './CreditCardSwapDirect';
import TransactionSaveOnly from './TransactionSaveOnly';
import TransactionSaveToExistingUser from './TransactionSaveToExistingUser';
import TransactionSaveToOtherPlatformUser from './TransactionSaveToOtherPlatformUser';

const DirectPaymentModule = () => {
    const dispatch = useDispatch();
    const isShowCardInput = useSelector(state => state.creditCard.isShowCardInput);

    // const [isCardSwapSuccess, setIsCardSwapSuccess] = useState(false);
    const [isResetCardInfo, setIsResetCardInfo] = useState(false);
    const [anetCardResponse, setAnetCardResponse] = useState(null);
    const [currentPaymentModule, setCurrentPaymentModule] = useState(0);
    const [transactionId, setTransactionId] = useState(1231);
    


    const handlePaymentModuleSelection = () => {
        if (currentPaymentModule === 0) {
            setCurrentPaymentModule(1);
        }
        else if (currentPaymentModule === 1) {
            setCurrentPaymentModule(2);
        }
        else if (currentPaymentModule === 2) {
            setCurrentPaymentModule(3);
        }
        else if (currentPaymentModule === 3) {
            setCurrentPaymentModule(1);
        }
    }

    const testCard = (val) => {
        console.log(alert(val))
    }




    const [selectedMethod, setSelectedMethod] = useState("");
    const handleOnChange = (event) => {
        console.log("handleOnChange", event.target.value)
        setSelectedMethod(event.target.value);
    }


    const hanleAnetSuccessReponse = (anetCardResponse) => {
        // dispatch(updateIsShowCardInput(true))
        // setIsCardSwapSuccess(true);
        console.log("test_112: hanleAnetSuccessReponse",anetCardResponse);
        setTransactionId(anetCardResponse.db_txn_id);
    }

    useEffect(() => {
        console.log("test_110", anetCardResponse);   
        if(anetCardResponse=="No response returned"){
            toast.warning("No response returned")
        }
        if (anetCardResponse && (anetCardResponse?.errorCode === "")) {
            hanleAnetSuccessReponse(anetCardResponse.data)
        }


    }, [anetCardResponse])

    const resetDirectPayment = () =>{
        setIsResetCardInfo(!isResetCardInfo);         
        dispatch(updateIsShowCardInput({ isShowCardInput: true, txnId: 0, chargedAmount:  0}))
        // dispatch(updateIsShowCardInput({isShowCardInput: true, txnId:0 })); 

    }

   
    useEffect(() => {
        resetDirectPayment()
        console.log("test_222", "direct_payment_module_load")
    },[])
    return (
        <Layout>


            <div className="flex justify-between">
                <p>
                    
                </p>
                <button className="btn btn-warning "onClick={() => resetDirectPayment()}>RESET</button>
            </div>


            <CreditCardSwapDirect  isResetCardInfo={isResetCardInfo} setAnetCardResponse={setAnetCardResponse} />


            {
                !isShowCardInput &&
                <>
                    <section className="w-6/12 bg-gray-100 rounded p-2">
                        <p className="text-2xl my-2 pl-2">Select Where to Save</p>
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text">Save only</span>
                                <input onChange={handleOnChange} type="radio" name="method-selection" className="radio checked:bg-red-500" checked={selectedMethod === "method-1"} value="method-1" />
                            </label>
                        </div>
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text">Other Platform User</span>
                                <input onChange={handleOnChange} type="radio" name="method-selection" className="radio checked:bg-blue-500" checked={selectedMethod === "method-2"} value="method-2" />
                            </label>
                        </div>
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text">Nexoz Existing User</span>
                                <input onChange={handleOnChange} type="radio" name="method-selection" className="radio checked:bg-blue-500" checked={selectedMethod === "method-3"} value="method-3" />
                            </label>
                        </div>
                    </section>
                </>
            }


            <section>
                {
                    selectedMethod === "method-1" &&
                    <div className="my-5">
                        <TransactionSaveOnly transactionId={transactionId}></TransactionSaveOnly>
                    </div>
                }

                {
                    selectedMethod === "method-2" &&
                    <div className="my-5">
                        <TransactionSaveToOtherPlatformUser></TransactionSaveToOtherPlatformUser>
                    </div>
                }

                {
                    selectedMethod === "method-3" &&
                    <div className="my-5">
                        <TransactionSaveToExistingUser></TransactionSaveToExistingUser>
                    </div>

                }


            </section>
        </Layout>
    );
};

export default DirectPaymentModule;