import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ImSpinner3 } from "react-icons/im";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateIsShowCardInput } from '../../../redux/slices/CreditCardSlice';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';

const CreditCardSwap = ({ cardInfo, paylogId, isResetCardInfo, setAnetCardResponse }) => {
    const dispatch = useDispatch();
    const monthValues = ["01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12];
    const yearValues = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040];


    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    const [cardNumber, setCardNumber] = useState("");
    const [expMonth, setExpMonth] = useState(0);
    const [expYear, setExpYear] = useState(0);
    const [ccv, setCcv] = useState("");
    const [chargeAmount, setChargeAmount] = useState("");
    const [txnId, setTxnId] = useState("");

    const [isShowCardInput, setIsShowCardInput] = useState(true);
    const [isOverwriteCardData, setIsOverwriteCardData] = useState(false);

    const [isInlineLoading, setIsInlineLoading] = useState(false);


    const loadTxnInfo = (paymentLogId) => {
        axios.get(baseApiUrl() + "paylog/" + paymentLogId)
            .then(response => {
                const responseData = response.data.data;
                console.log("responseData", responseData);
                setChargeAmount(responseData.amount);
                setTxnId(responseData.id);
                setCardNumber(responseData.card_number);

            })
            .catch((err => {

            }))
            .finally(() => {

            })
    }
    useEffect(() => {
        if (paylogId > 0 && cardInfo['payment_log_id'] > 0) {
            loadTxnInfo(paylogId);
        }
    }
        , [paylogId])
    const chargeCreditCard = () => {
        

        // form validation 
        let formValidationSuccess = true;
        if (!ccv.length > 0) {
            toast.warning('CCV Field Required');
            formValidationSuccess = false;
        }
        if (!cardNumber.length > 0) {
            toast.warning('Please provie the card number');
            formValidationSuccess = false;
        }
        if (chargeAmount < 1) {
            toast.warning('Charge Amount is Required');
            formValidationSuccess = false;
        }
        if (expMonth == 0) {
            toast.warning('Select Expiry month');
            formValidationSuccess = false;
        }
        if (expYear == 0) {
            toast.warning('Select Expiry Year');
            formValidationSuccess = false;
        }
        if (!formValidationSuccess) {
            return;
        }


        // form validation 



        // const chargedAmount =  ;
        // const commissionAmount =  ;
        const sellerRecivingBalance = cardInfo['price'] - cardInfo['rate'];



        console.log("cardinfo" ,cardInfo )

        const payloadData = {
            // card_number: "4946606123943121",

            card_number: cardNumber.replace(" ", ""),
            exp_date: `${expYear}-${expMonth}`,
            ccv: ccv,
            charge_amount: chargeAmount,
            seller_id: cardInfo['seller_id'],
            gift_card_id: cardInfo['id'],
            seller_reciving_balance: sellerRecivingBalance,


        }
        setIsInlineLoading(true);
        setIsSaveButtonDisabled(true);
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Processing..." }));
        axios
            .post(baseApiUrl() + 'pg-anet-charge-card', payloadData)
            .then(response => {
                setIsSaveButtonDisabled(true);
                console.log("test_104", typeof response.data, response.data);
                setAnetCardResponse(response.data);
                setTxnId(response.data.data.db_txn_id);
                // setLocalSwapSuccess(false);

                // setIsCardSwapSuccess(true);
                console.log("test_111_before", response.data);

                if (response.data.errorCode === "") {
                    setIsShowCardInput(false);
                    setIsOverwriteCardData(false);
                    toast.success("Card Charged Successfully.")
                    dispatch(updateIsShowCardInput({ isShowCardInput: false, txnId: response.data.data.db_txn_id }))

                    console.log("test_112_dispatch", "dispatch");
                }
            })
            .catch(errors => {
                setIsShowCardInput(true);
                setIsSaveButtonDisabled(false);
                console.log("test_105", errors)
                toast.error(errors.response.data.errorMsg)
            })
            .finally(() => {
                setIsInlineLoading(false);
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })



        // setIsCardSwapSuccess(true)
    }

    useEffect(() => {
        console.log("isResetCardInfo", isResetCardInfo)
        console.log("cardInfo101", cardInfo)
        if (isResetCardInfo === true) {
            resetFieldInfo()
        }
    }, [isResetCardInfo, cardInfo])
    const resetFieldInfo = () => {
        setChargeAmount("")
        setCardNumber("")
        setExpMonth(monthValues[0])
        setExpYear(yearValues[0]);
        setCcv("");
        setTxnId("");
        setIsSaveButtonDisabled(false);
    }

    useEffect(() => {
        console.log("paylogId", paylogId)
    }, [paylogId])


    return (
        <>
            <section>

                {/* && cardInfo['payment_log_id']>0 */}
                {(!isShowCardInput || paylogId > 0) && !isOverwriteCardData ? <>
                    <section className="my-5  p-10 bg-gray-100 rounded">
                        <div className="text-xl">Transaction ID : {txnId} </div>
                        <div>Card Number : {cardNumber} </div>
                        {expYear > 0 && <div>Exp Date : {expMonth}-{expYear} </div>}
                        {ccv && <div>CCV : {ccv} </div>}
                        <div>Amount : ${chargeAmount} </div>
                        <div>Status : <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded">Success</span> </div>

                        <div className="mt-5 flex justify-end">
                            <button onClick={() => { resetFieldInfo(); setIsShowCardInput(true); setIsOverwriteCardData(true); }} className="btn btn-warning btn-sm">Try Again</button>
                        </div>




                    </section>
                </>
                    : <section className="w-6/12">
                        <div className="grid grid-cols-2  my-2">
                            <p>Card Number</p>
                            <div className="flex items-center">
                                <input onChange={(e) => setCardNumber(e.target.value)} type="text" className="input-blue text-xl" placeholder="Card Number" value={cardNumber} />
                                <span className="pl-3">{cardNumber.length}</span>

                            </div>
                        </div>
                        <div className="grid grid-cols-2 my-2">
                            Validaty (mm/year)
                            <div>
                                <select onChange={(e) => setExpMonth(e.target.value)} className="border-2 border-gray-300 rounded p-1 mx-1" name="cars" id="cars">
                                    {
                                        monthValues.map((item, index) => {
                                            if (index === 0) {
                                                return <option selected value={item}>{item}</option>
                                            }
                                            else {
                                                return <option value={item}>{item}</option>
                                            }

                                        })
                                    }

                                </select>
                                <select onChange={(e) => setExpYear(e.target.value)} className="border-2 border-gray-300 rounded p-1 mx-1" name="years" id="yars">
                                    {
                                        yearValues.map((item, index) => {
                                            if (index == 0) {
                                                return <option value={item} selected>{item} </option>

                                            }
                                            else {
                                                return <option value={item}>{item}  </option>
                                            }
                                        })
                                    }
                                </select>
                            </div>
                            {/* <input type="text" className="input-blue" /> */}
                        </div>
                        <div className="grid  grid-cols-1 md:grid-cols-2   my-2">
                            CCV
                            <input value={ccv} onChange={(e) => setCcv(e.target.value)} type="text" className="input-blue  text-2xl w-[130px]" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2   my-2">
                            Charge Amount ( $ )
                            <div>
                                <input value={chargeAmount} onChange={(e) => setChargeAmount(e.target.value)} type="number" className="input-blue  text-2xl w-[130px]" />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
                            {isInlineLoading ?
                                <div className="text-3xl p-5">
                                    <ImSpinner3 className=" animate-spin " />
                                </div> :

                                <button disabled={isSaveButtonDisabled} onClick={() => chargeCreditCard()} className="btn btn-outline btn-md btn-primary w-[100px]">CONFIRM</button>
                            }



                        </div>

                    </section>

                }


            </section>
        </>
    );
};

export default CreditCardSwap;