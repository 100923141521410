import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateIsShowCardInput } from '../../../redux/slices/CreditCardSlice';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';

const CreditCardSwapDirect = ({ isResetCardInfo, setAnetCardResponse }) => {





    const dispatch = useDispatch();

    const monthValues = ["01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12];
    const yearValues = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040];

    const isShowCardInput = useSelector(state => state.creditCard.isShowCardInput);


    const [chargeAmount, setChargeAmount] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [expMonth, setExpMonth] = useState(monthValues[0]);
    const [expYear, setExpYear] = useState(yearValues[0]);
    const [ccv, setCcv] = useState("");
    const [txnId, setTxnId] = useState("");



    const generateCreditCardFormatValue = (e) => {

        setCardNumber(e.target.value)

    }

    const ccvValidation = ccvValue => {
        if (ccvValue.length <= 4) {
            setCcv(ccvValue)
        }

    }



    const chargeCreditCard = () => {

        // form validation 
        let formValidationSuccess = true;
        if (!ccv.length > 0) {
            toast.warning('CCV Field Required');
            formValidationSuccess = false;
        }
        if (!cardNumber.length > 0) {
            toast.warning('Please provie the card number');
            formValidationSuccess = false;
        }
        if (chargeAmount < 1) {
            toast.warning('Charge Amount is Required');
            formValidationSuccess = false;
        }
        if (!formValidationSuccess) {
            return;
        }


        // form validation 







        const payloadData = {
            // card_number: "4946606123943121",
            // card_number: "4111111111111112",
            card_number: cardNumber.replace(" ", ""),
            exp_date: `${expYear}-${expMonth}`,
            ccv: ccv,
            charge_amount: chargeAmount,
        }

        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Processing..." }));
        axios
            .post(baseApiUrl() + 'pg-anet-charge-card', payloadData)
            .then(response => {
                console.log("test_104", typeof response.data, response.data);
                setAnetCardResponse(response.data);
                setTxnId(response.data.data.db_txn_id);
                // setLocalSwapSuccess(false);

                // setIsCardSwapSuccess(true);
                console.log("test_111_before", response.data);

                if (response.data.errorCode === "") {
                    toast.success("Card Charged Successfully.")
                    dispatch(updateIsShowCardInput({ isShowCardInput: false, txnId: response.data.data.db_txn_id, chargedAmount:  chargeAmount}))

                    console.log("test_112_dispatch", "dispatch");
                }
            })
            .catch(errors => {
                console.log("test_105", errors)
                toast.error(errors.response.data.errorMsg)
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })



        // setIsCardSwapSuccess(true)
    }
    const resetFieldInfo = () => {
        setChargeAmount("")
        setCardNumber("")
        setExpMonth(monthValues[0])
        setExpYear(yearValues[0]);
        setCcv("");
        setTxnId("");
    }


    useEffect(() => {
        console.log("isResetCardInfo", isResetCardInfo)
        if (isResetCardInfo === true) {
            resetFieldInfo()
        }
    }, [isResetCardInfo])

    return (
        <>
            {!isShowCardInput &&
                <section className="my-5  p-10 bg-gray-100 rounded">
                    <div className="text-xl">Transaction ID : {txnId} </div>
                    <div>Card Number : {cardNumber} </div>
                    <div>Exp Date : {expMonth}-{expYear} </div>
                    <div>CCV : {ccv} </div>
                    <div>Amount : ${chargeAmount} </div>

                </section>
            }
            {
                isShowCardInput &&
                <section className="  p-10 bg-gray-100 rounded">





                    <section className="w-6/12">
                        <div className="grid grid-cols-2  my-2">
                            <p>Card Number-</p>
                            <div className="flex items-center">
                                <input onChange={(e) => generateCreditCardFormatValue(e)} type="text" className="input-blue text-xl" placeholder="Card Number" value={cardNumber} />
                                <span className="pl-3">{cardNumber.length}</span>

                            </div>
                        </div>
                        <div className="grid grid-cols-2 my-2">
                            Validaty (mm/year)
                            <div>
                                <select onChange={(e) => setExpMonth(e.target.value)} className="border-2 border-gray-300 rounded p-1 mx-1" name="cars" id="cars">
                                    {
                                        monthValues.map((item, index) => {
                                            if (index === 0) {
                                                return <option selected value={item}>{item}</option>
                                            }
                                            else {
                                                return <option value={item}>{item}</option>
                                            }

                                        })
                                    }

                                </select>
                                <select onChange={(e) => setExpYear(e.target.value)} className="border-2 border-gray-300 rounded p-1 mx-1" name="years" id="yars">
                                    {
                                        yearValues.map((item, index) => {
                                            if (index == 0) {
                                                return <option value={item} selected>{item} </option>

                                            }
                                            else {
                                                return <option value={item}>{item}  </option>
                                            }
                                        })
                                    }
                                </select>
                            </div>
                            {/* <input type="text" className="input-blue" /> */}
                        </div>
                        <div className="grid grid-cols-2   my-2">
                            CCV
                            <input value={ccv} onChange={(e) => ccvValidation(e.target.value)} type="text" className="input-blue  text-2xl w-[130px]" />
                        </div>
                        <div className="grid grid-cols-2   my-2">
                            Charge Amount ( $ )
                            <div>
                               <input value={chargeAmount} onChange={(e) => setChargeAmount(e.target.value)} type="number" className="input-blue  text-2xl w-[130px]" />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 my-2">
                            <div></div>
                            <button onClick={chargeCreditCard} className="btn btn-outline btn-primary w-[200px]">CONFIRM</button>
                        </div>

                    </section>

                </section>

            }
        </>
    );
};

export default CreditCardSwapDirect;