import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Layout from '../../../components/Layout/Layout';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import { updateUserInfo } from '../../../redux/slices/userSlice';
import baseApiUrl from '../../../utility/baseApiUrl';
import responseErrorManage from '../../../utility/responseErrorManage';

const Profile = () => {
    const dispatch = useDispatch();
    const [profileImg, setProfileImg] = useState(null);
    const loggedinUserInfo = useSelector((state) => state.authInfo.user);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');

    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');


    useEffect(() => {
        // setName(loggedinUserInfo.name);
        // setEmail(loggedinUserInfo.email);
        // setContactNumber(loggedinUserInfo.contact_number);
        if (loggedinUserInfo?.id) {
            getUserInfo()
        }


    }, [])

    const getUserInfo = () => {
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }));
        axios.get(baseApiUrl() + `users/${loggedinUserInfo.id}`)
            .then(res => {
                const data = res.data; //
                setName(data.name);
                setEmail(data.email);
                setContactNumber(data.contact_number);
                dispatch(updateUserInfo(data))

            })
            .catch((err) => {
                responseErrorManage(err)

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));

            })
    }



    const handleUserInfoUpdate = () => {

        const data = {
            'name': name,
            'email': email,
            'contact_number': contactNumber,
        }
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Updating..." }));
        axios.post(baseApiUrl() + `users/q/update-user-info`, { ...data })
            .then((response) => {
                toast.success(response.data.msg);
                getUserInfo();



            })
            .catch((error) => {
                responseErrorManage(error)

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }

    const handleChangePassword = () => {

        const data = {
            'password': password,
            'old_password': oldPassword,
        }
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Updating password..." }));
        axios.post(baseApiUrl() + `users/change-password`, { ...data })
            .then((response) => {
                toast.success(response.data.msg);
                // getUserInfo();              
            })
            .catch((error) => {
                responseErrorManage(error);
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }


    return (
        <Layout>
            <>

                <div className="relative max-w-md mx-auto md:max-w-2xl mt-2 md:mt-16 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl ">
                    <div className="px-6">
                        <div className="flex flex-wrap justify-center">
                            {/* <div className="w-full flex justify-center">
                                <div className="relative">
                                    
                                    <img src="https://github.com/creativetimofficial/soft-ui-dashboard-tailwind/blob/main/build/assets/img/team-2.jpg?raw=true" className="shadow-xl rounded-full align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-[150px]" />
                                </div>
                            </div> */}
                            <div className="w-full text-center my-10">
                                <div className="flex flex-col items-start mt-2">
                                    <h3 className="text-2xl text-slate-700 font-bold leading-normal mb-1">{loggedinUserInfo.name}</h3>
                                    <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                                        <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>{loggedinUserInfo.contact_number}
                                    </div>
                                </div>
                                {/* <div className="flex justify-center lg:pt-4 pt-8 pb-0">
                                    <div className="p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">3,360</span>
                                        <span className="text-sm text-slate-400">Photos</span>
                                    </div>
                                    <div className="p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">2,454</span>
                                        <span className="text-sm text-slate-400">Followers</span>
                                    </div>

                                    <div className="p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">564</span>
                                        <span className="text-sm text-slate-400">Following</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <>
                            <div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2">
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Full Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="full name" className="form-field" required />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2">
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Email</label>
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="email" className="form-field" required />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2">
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Contact Number</label>
                                    <input value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} type="text" placeholder="contact number" className="form-field" required />
                                </div>
                                <div className="flex justify-end">
                                    <button onClick={() => handleUserInfoUpdate()} type="button" className="text-white block   bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center md:mr-2 mb-2">SAVE</button>
                                    <div className="pb-10"> </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-1 items-center mb-2">
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Password</label>
                                    <input onChange={(e) => setOldPassword(e.target.value)} type="text" placeholder="OLD Password" className="form-field" required />
                                    <input onChange={(e) => setPassword(e.target.value)} type="text" placeholder="New Password" className="form-field" required />
                                    <button onClick={handleChangePassword} type="button" className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2  dark:focus:ring-yellow-900">UPDATE</button>

                                </div>


                                <div className="pb-10">

                                </div>
                            </div>
                        </>
                    </div>
                </div>
                <div className="relative max-w-md mx-auto md:max-w-2xl mt-2 md:mt-16 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl ">
                    <div className="p-5">

                        <h3 className="text-2xl text-slate-700 font-bold leading-normal mb-1">KYC Status</h3>
                        <div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2">
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> Profile Status</label>
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> Not Verified ( Level 1) </label>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2">
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> Current Limit</label>
                                <div>
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> Daily Limit $200 </label>
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300"> Lifetime Limit $1000 </label>
                                </div>

                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2">
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Verify Your Profile</label>
                                <div>
                                    <button class="btn btn-outline btn-primary mb-4">Apply For Verifed Personal Profile</button> <br />
                                    <button class="btn btn-outline btn-warning">Apply For Verifed Bsuiness Profile </button>

                                    {/* <button onClick={handleChangePassword} type="button" className="mb-5 focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2  dark:focus:ring-yellow-900">Apply For Verifed Personal Profile </button> */}
                                    {/* <button onClick={handleChangePassword} type="button" className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2  dark:focus:ring-yellow-900">Apply For Verifed Bsuiness Profile </button> */}
                                </div>

                            </div>
                        </div>

                    </div>

                   
                </div>


                {/* <footer className="relative pt-6 pb-2 mt-6">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap items-center md:justify-between justify-center">
                            <div className="w-full md:w-6/12 px-4 mx-auto text-center">
                                <div className="text-sm text-slate-500 font-semibold py-1">
                                    Tailwind CSS Component from <a href="https://www.creative-tim.com/product/notus-design-system-pro?ref=tailwindcomponents" className="text-slate-700 hover:text-slate-500" target="_blank" rel="noreferrer">Notus PRO Html</a> by <a href="https://www.creative-tim.com" className="text-slate-700 hover:text-slate-500" target="_blank" rel="noreferrer"> Creative Tim</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </>
        </Layout>
    );
};

export default Profile;