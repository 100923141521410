import React, { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import useAuth from '../../../hooks/useAuth';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';
import responseErrorManage from '../../../utility/responseErrorManage';
const axios = require("axios");

const Transaction = () => {
    const auth = useAuth();
    const [listData, setListData] = useState([]);
    const dispatch = useDispatch();

    // load users from server
    const loadLists = (pageNumber = 1) => {
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }));
        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });
        axios

            .get(baseApiUrl() + `purchase/q/user-purchase-history?page=` + pageNumber)
            .then((res) => {
                setListData(res.data);
                // dispatch({
                //     type: "fullPageLoader_update",
                //     payload: false
                // });
                // setUser(res.data);
                // console.log(test2)

            })
            .catch((err) => {

                console.log(err);
                responseErrorManage(err);

                // dispatch({
                //     type: "fullPageLoader_update",
                //     payload: false
                // });
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "" }));
            })


        // console.log('test-5', isLoading)
    };


    useEffect(() => {

        loadLists();
    }, []);


    return (
        auth ? <>
            <div className=''>
                <Layout>
                    <div className='bg-white rounded-lg '>

                        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                            <h3>Transaction History</h3>
                            {/* <Link to={'/create/user'}>
                                <button className='button__bg px-6 py-2 block rounded'>Add User</button>
                            </Link> */}
                        </div>

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Amount
                                        </th>
                                        {/* <th scope="col" className="px-6 py-3">
                                            Code
                                        </th> */}
                                        <th scope="col" className="px-6 py-3">
                                            Code
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Currency
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Schedule Date
                                        </th>
                                        {/* <th scope="col" className="px-6 py-3 text-right">
                  Rate %
                </th> */}
                                        <th scope="col" className="px-6 py-3 text-right">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='mt-5'>
                                    {listData?.data?.map((item, index) => {
                                        return (
                                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                    {item.amount}
                                                </th>
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                    {item.code}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {item.currency_id === 1 ? 'Crypto' : 'Naira'}
                                                </td>
                                                <td className="px-6 py-4 capitalize ">
                                                    {item.order_status === 1 && <div class="badge badge-warning badge-outline">Pending</div>}
                                                    {item.order_status === 2 && <div class="badge badge-primary badge-outline">Paid</div>}
                                                    {item.order_status === 3 && <div class="badge badge-secondary badge-outline">Cancelled</div>}
                                                    {item.order_status === 4 && <div class="badge badge-accent badge-outline">Hold</div>}
                                                </td>


                                                <td className="px-6 py-4 capitalize ">
                                                    {item.schedule_date}
                                                </td>

                                                {/* <td className="px-6 py-4 capitalize text-right">
                      {Math.round(item.rate) } %
                    </td> */}
                                                <td className="px-6 py-4 flex gap-5 text-lg uppercase text-right ">
                                                    {/* <span onClick={() => deleteUser(item)} className=' cursor-pointer'> <AiOutlineDelete /> </span> */}
                                                    {/* <Link to={`/edit/user/${item.id}`}><AiOutlineEdit /></Link> */}
                                                    <Link className="hover:text-blue-500" to={`/edit/user/${item.id}`}>PAY NOW</Link>
                                                    <Link className="hover:text-blue-500" to={`/edit/user/${item.id}`}>Cancel</Link>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>

                            {listData?.total > 5 &&
                                <div className='pl-3 py-4'>
                                    <Pagination
                                        activePage={listData?.current_page ? listData?.current_page : 0}
                                        itemsCountPerPage={listData?.per_page ? listData?.per_page : 0}
                                        totalItemsCount={listData?.total ? listData?.total : 0}
                                        onChange={(pageNumber) => {
                                            loadLists(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        itemClassFirst="p-first-page-link"
                                        itemClassLast="p-last-page-link"
                                        activeClass="p-active"
                                        activeLinkClass="p-active-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </Layout>
            </div>
        </> : <Navigate to="/auth" />

    );
};

export default Transaction;