import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { isLoadingFullPageUpdate } from "../../redux/slices/loadingSlice";
import { purchaseInfoClear, purchaseInfoUpdate } from "../../redux/slices/purchaseSlice";
import { loggedInTrue, tokenUpdate } from "../../redux/slices/userSlice";
import AlertUi from "../../UiComponents/Daisy/AlertUi";
import baseApiUrl from "../../utility/baseApiUrl";
import responseErrorManage from "../../utility/responseErrorManage";


const MultistepPurchaseCode = () => {
    let navigate = useNavigate();
    const purchaseInfo = useSelector((state) => state.purchaseInfo);
    const loggedInStatus = useSelector((state) => state.authInfo.status);
    const dispatch = useDispatch();
    const [isPurchasecodeTabActive, setIsPurchasecodeTabActive] = useState(1);
    const [scheduleDate, setScheduleDate] = useState('');
    // const [purchasedAmount, setPurchasedAmount] = useState(0);
    // const [paymentMethod, setPaymentMethod] = useState(1);
    // const [haveSchedule, setHaveSchedule] = useState('schedule');

    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');

    const [verificationCode, setVerificationCode] = useState('');
    const [tempUserInfo, setTempUserInfo] = useState([]);
    const [validationErrorMsg, setValidationErrorMsg] = useState('');



    const [radio, setRadio] = useState(true)


    const handleRadioButton = (e) => {
        console.log("handleRadioButton", e.target.value)
        // setHaveSchedule(e.target.value)
        // console.log("handleRadioButton_2",haveSchedule,"test",e.target.value )
        setRadio(!radio)
        dispatch(purchaseInfoUpdate({ payment_schedule: e.target.value }))
    }


    // Post Request 
    const resetFormData = () =>{
        console.log("resetformData")
        dispatch(purchaseInfoClear());

    }
    const handlePurchaseByNewUser = () => {

        // return;
        const data = {
            email: contactEmail,
            name: contactName,
            contact_number: contactNumber,

            amount: purchaseInfo.amount,
            // currency_id: contactNumber,
            is_scheduled: !radio,
            schedule_date: purchaseInfo.schedule_date,
        }
        if (purchaseInfo.currency === "Naira") {
            data.currency_id = 2;
        } else if (purchaseInfo.currency === "Crypto") {
            data.currency_id = 1;
        }

        console.log("handlePurchaseByNewUser", data);
        // return;
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..." }));
        axios.post(baseApiUrl() + 'purcahse-code-new-user-verify', { ...data })
            // axios.post(baseApiUrl() + 'purcahse-code-new-user', { ...data })
            .then(response => {
                console.log("suc", response.data)
                toast.success(response.data.msg); // unauthenticated
                setIsPurchasecodeTabActive(3);
                setTempUserInfo(response.data.data);

            })
            .catch(err => {
                console.log(err)
                responseErrorManage(err)
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }

    const handleVerify = () => {
        const data = {
            user_id: tempUserInfo.id,
            token: verificationCode,
            email: tempUserInfo.email
        }

        console.log("handlePurchaseByNewUser", data);
        // return;
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Verifying..." }));
        axios.post(baseApiUrl() + 'user-verification', { ...data })
            // axios.post(baseApiUrl() + 'purcahse-code-new-user', { ...data })
            .then(response => {
                console.log("suc", response.data)
                toast.success(response.data.msg); // unauthenticated
                // setTempUserInfo(response.data.data);
                // get user login info 
                const userInfo = response.data.user;
                const token = response.data.token;
                console.log("btoken", token)
                // localStorage.setItem("token_value", token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                dispatch(loggedInTrue(userInfo));
                dispatch(tokenUpdate(token));
                navigate("/purchase-code");
                // navigate to dahsbaord purchase history

            })
            .catch(err => {
                console.log(err)
                responseErrorManage(err)
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }
    // Post Request //
    const handlePurchaseForLoggedInUser = () => {
        const proceedNext = proceedCheckoutValidation();
        if (!proceedNext) {
            // if proceedNext false then next code will not execute
            return;
        }
        // passed validation , so execute the code.      
        const data = {
            // email: contactEmail,
            // name: contactName,
            // contact_number: contactNumber,
            // =2000&=ngn&=btc&=OZPIN - 2&=Test Description 2
            price_amount: purchaseInfo.amount,
            price_currency: 'ngn',
            pay_currency: 'btc',
            order_id: 'OZPIN',
            order_description: `Purchase ${purchaseInfo.amount} code`,



            amount: purchaseInfo.amount,
            // currency_id: contactNumber,
            is_scheduled: !radio,
            schedule_date: purchaseInfo.schedule_date,
        }
        if (purchaseInfo.currency === "Naira") {
            data.currency_id = 2;
        } else if (purchaseInfo.currency === "Crypto") {
            data.currency_id = 1;
        }

        console.log("handlePurchaseByNewUser", data);
        
        // return;
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }));
        axios.post(baseApiUrl() + 'checkout', { ...data })
            .then(response => {
                console.log("suc", response.data)
                toast.success(response.data.msg); 
                resetFormData();
                const invoiceId = response.data.data.invoice_id;
                const invoiceUrl = `https://nowpayments.io/payment/?iid=${invoiceId}`;
                window.open(invoiceUrl, '_blank');
                // Clear default data
                
                navigate(`/purchase-details/${invoiceId}`)


            })
            .catch(err => {
                console.log(err)
                responseErrorManage(err)
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })

    }

    const handleCancellation = () => {
        setIsPurchasecodeTabActive(1);
        alert("handleCancellation");

    }


    // validation
    const proceedCheckoutValidation = () => {
        let proceedNext = true;
        const minAmount = 5000;
        const maxAmount = 50000;
        // purchae amount can not be empty
        if (!purchaseInfo.amount) {
            setValidationErrorMsg('purchae amount can not be empty')

            proceedNext = false;

        } else if (purchaseInfo.amount < minAmount) {
            // purchase amount can not be less than this amount
            setValidationErrorMsg(`purchase amount can not be less than ${minAmount} amount`)
            proceedNext = false;
        } else if (purchaseInfo.amount > maxAmount) {
            // purchase amount can not be more then this amount
            setValidationErrorMsg(`purchase amount can not be more than ${maxAmount} amount`)
            proceedNext = false;
        }
        else if (!purchaseInfo.currency) {
            setValidationErrorMsg(`Please choose a currency`)
            proceedNext = false;
        }
        if (proceedNext) {
            setValidationErrorMsg('');
        }
        return proceedNext;
    }

    // if not need  remove this. 
    const handleCreateInvoice = () => {
        const data = {
            "price_amount": 7,
            "price_currency": "usd",
            "pay_currency": "btc",
            "order_id": "OZPIN - 2",
            "order_description": "Test Description"
        }
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "connecting payment getaway..." }));
        axios.post(baseApiUrl() + 'pay-np-create-invoice', { ...data })
            // axios.post(baseApiUrl() + 'purcahse-code-new-user', { ...data })
            .then(response => {
                console.log("suc", response.data)
                toast.success(response.data.msg); // unauthenticated
                // Now Open the nowpayments Invocie page, in new tab.


            })
            .catch(err => {
                console.log(err)
                responseErrorManage(err)
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }

    return (
        <div className="w-full md:w-1/2">
            <AnimatePresence>
                {isPurchasecodeTabActive === 1 && <motion.div className={`w-full  `}

                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <h1 className="text-2xl md:text-5xl font-bold pb-10">Purchase Code</h1>
                    <div className="mb-5">
                        {validationErrorMsg && <AlertUi msg={validationErrorMsg} />}
                    </div>
                    {/* <div className="w-full">
                        <div className="w-full flex flex-col md:flex-row justify-between items-center">
                            <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-full ml-5)">Purchase Amount</h1>
                            <input value={purchaseInfo.amount} onChange={(e) => dispatch(purchaseInfoUpdate({ amount: e.target.value }))} type="number" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" />
                        </div>
                       
                    </div> */}
                    <div className="w-full flex flex-col md:flex-row justify-between items-center">
                        <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-1/2 ml-5)">Purchase Amount</h1>
                        <div className="w-1/2">
                            <div class="mt-1  relative rounded-md shadow-sm flex flex-col justify-center">
                                <div class="absolute pl-[14px] pointer-events-none  ">
                                    <span class="text-gray-500 sm:text-sm md:text-3xl "> ₦ </span>
                                </div>
                                <input value={purchaseInfo.amount} onChange={(e) => dispatch(purchaseInfoUpdate({ amount: e.target.value }))} type="text" name="price" id="price" class="py-1  pl-[46px] border-2 border-indigo-600 focus:ring-indigo-500  focus:border-indigo-500 block w-full  sm:text-sm md:text-3xl  rounded-md" placeholder="0.00" />
                                {/* <div class="absolute inset-y-0 right-0 flex items-center">
                                    <label for="currency" class="sr-only">Currency</label>
                                    <select id="currency" name="currency" class="text-2xl focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                                        <option>USD</option>
                                        <option>CAD</option>
                                        <option>EUR</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>

                    </div>
                    <div className="w-full mt-5">
                        <div className="w-full flex   flex-col md:flex-row justify-between items-center">
                            <p className="text-xl md:text-3xl font-bold mb-2 md:md-0 md:(text-left w-1/2 ml-5)">Payment Currency</p>
                            {/* <input type="text" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" /> */}
                            <select className="select select-primary w-1/2 max-w-xs" onChange={(e) => dispatch(purchaseInfoUpdate({ currency: e.target.value }))}>
                                <option disabled selected>Select</option>
                                <option selected={purchaseInfo.currency === "Crypto" ? true : false} value="Crypto">Crypto</option>
                                <option selected={purchaseInfo.currency === "Naira" ? true : false}  value="Naira">₦ Naira</option>

                            </select>
                        </div>

                    </div>
                    <div className="w-full mt-5 flex justify-between mb-4">
                        {/* <input type="radio" name="radio-1" className="radio" checked /> Immediate
                        <input type="radio" name="radio-1" className="radio" /> Schedule */}
                        <p className="text-xl">Select Payment Schedule</p>
                        <div className="text-xl"  >
                            <input type="radio" id="age1" name="schedule" value="immediate" onChange={(e) => handleRadioButton(e)} checked={radio} />
                            <label for="age1" className="pl-2 py-2  block-inline" >Immediate</label><br></br>
                            <input type="radio" id="age2" name="schedule" value="schedule" onChange={(e) => handleRadioButton(e)} checked={!radio} />
                            <label for="age2" className="pl-2 py-2 block-inline">Schedule</label><br></br>
                        </div>
                    </div >
                    {radio}
                    {
                        !radio ?
                            <div className="w-full  flex justify-between mb-5 text-xl">
                                <label for="birthday">Select Schedule Date</label>
                                <input onChange={(e) => dispatch(purchaseInfoUpdate({ schedule_date: e.target.value }))} type="date" id="birthday" name="birthday" />
                            </div> : <></>
                    }


                    {/* <p className="py-1 md:py-6 text-right md:text-left">The cheap, fast way.</p> */}
                    <div className="flex justify-end items-center">
                        {
                            loggedInStatus ? <button onClick={() => handlePurchaseForLoggedInUser()} className="btn btn-primary">Proceed Checkout </button> : <button onClick={() => setIsPurchasecodeTabActive(2)} className="btn btn-primary block w-full">Next</button>
                        }


                    </div>

                </motion.div>}
                {/* </AnimatePresence>
                <AnimatePresence> */}
                {isPurchasecodeTabActive === 2 && <motion.div className="w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <h2 className="text-2xl md:text-xl font-bold pb-5 text-right ">Already have an account ?<Link to="/auth" className="underline text-indigo-500 ml-2">Login</Link> </h2>
                    <h1 className="text-2xl md:text-5xl font-bold pb-10">Contat Info</h1>
                    <div className="w-full">
                        <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
                            <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-full ml-5)">Full Name</h1>
                            <input
                                value={contactName} onChange={(e) => (setContactName(e.target.value))}
                                type="text" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" />
                        </div>
                    </div>
                    <div className="w-full mt-5">
                        <div className="w-full flex  flex-col md:flex-row justify-between items-start md:items-center">
                            <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-full ml-5)">Contact Number</h1>
                            <input
                                value={contactNumber} onChange={(e) => (setContactNumber(e.target.value))}
                                type="text" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" />
                        </div>
                    </div>
                    <div className="w-full  mt-5">
                        <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
                            <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-full ml-5)">Eamil</h1>
                            <input
                                value={contactEmail} onChange={(e) => setContactEmail(e.target.value)}
                                type="text" placeholder="Type here" className="input input-bordered input-primary w-full max-w-xs" />
                        </div>
                    </div>

                    <div className="text-left">
                        <p className="text-left md:text-right w-full pt-6 pb-2 text-md md:text-xl font-semidbold ">* Purchase Amount : {purchaseInfo.amount} Method: {purchaseInfo.currency}</p>
                        <p className="block md:hidden text-left md:text-right w-full pb-6 text-red-700 text-sm md:text-md font-semidbold  break-all ">* System will generate random password <br /> and send it on your email.</p>
                        <p className="hidden md:block text-left md:text-right w-full pb-6 text-red-700 text-sm md:text-md font-semidbold   break-all ">* System will generate random password and send it on your email.</p>
                    </div>


                    <div className="flex justify-between items-center">

                        {/* <button onClick={() => setIsPurchasecodeTabActive(true)} className="btn btn-primary">BACK </button> */}
                        <button onClick={() => setIsPurchasecodeTabActive(1)} className="btn btn-outline">BACK</button>
                        <button onClick={() => handlePurchaseByNewUser()} className="btn btn-primary">Proceed </button>
                    </div>
                </motion.div>}

                {isPurchasecodeTabActive === 3 && <motion.div className="w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="w-full flex flex-col md:flex-row justify-between items-center">
                        <h1 className="text-xl md:text-3xl font-bold mb-2 md:md-0  md:(text-left w-1/2 ml-5)">Verification Code</h1>
                        <div className="w-1/2">
                            <div class="mt-1  relative rounded-md shadow-sm flex flex-col justify-center">
                                <input value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} type="text" name="price" id="price" class="py-1  pl-[10px] border-2 border-indigo-600 focus:ring-indigo-500  focus:border-indigo-500 block w-full  sm:text-sm md:text-3xl  rounded-md" placeholder="" />
                            </div>
                        </div>

                    </div>
                    <p className="hidden md:block text-left md:text-right w-full pb-6 text-red-700 text-sm md:text-md font-semidbold   break-all ">* Check your email for verification code.</p>
                    <div className="flex items-center justify-end">
                        <a className="cursor-pointer text-blue-800 text-lg" onClick={handleCancellation}>CANCEL</a>
                        <button onClick={() => handleVerify()} className="ml-5 btn btn-primary mx-5 ">Verify</button>
                        <button onClick={() => setIsPurchasecodeTabActive(2)} className=" btn btn-primary btn-outline  ">Send Again</button>
                    </div>
                    <div>

                    </div>



                </motion.div>}
                {/* {isPurchasecodeTabActive === 4 && <motion.div className="w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <PurchaseDetails />
                </motion.div>} */}
            </AnimatePresence>

        </div >
    );
};

export default MultistepPurchaseCode;