import { React, useEffect, useState } from 'react';
import { AiFillCreditCard, AiOutlineCheckCircle, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';
import responseErrorManage from '../../../utility/responseErrorManage';

const axios = require("axios");

const GiftCards = () => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const authUserPermission = useSelector((state) => state.authInfo.permission);

  const [searchGiftCard, setSearchGiftCard] = useState({});
  const [toggleSearchGiftCardForm, setToggleSearchGiftCardForm] = useState(false);
  const [card_number, setCardNumber] = useState(''); // changeHere - stateName correction


  const [giftCard, setGiftCard] = useState({});

  const [transaction, setTransaction] = useState({});
  const [cardPrice, setCardPrice] = useState('');
  const [payable, setPayable] = useState('');

  const [code, setCode] = useState(''); // changeHere - stateName correction
  const [transaction_amount, setTransactionAmount] = useState(''); // changeHere - stateName correction


  const dispatch = useDispatch();
  const imagePath = apiStoragePath();

  // load  from server
  const loadData = (pageNumber = 1) => {

    dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }));
    // my-giftcard
    let url = '';
    console.log("seller_1", authUserPermission)
    if (authUserPermission === 'seller') {
      url = baseApiUrl() + 'my-giftcard?page=';
    } else {
      url = baseApiUrl() + 'gift-card?page=';
    }
    console.log("authUserPermission", authUserPermission, url)

    axios
      .get(url + pageNumber)
      .then((res) => {

        // setGiftCard(res.data);
        console.log("setGiftCard", res.data)

        if (authUserPermission === 'seller') {
          setGiftCard(res.data.data);
          console.log("giftCard_101", giftCard)
        } else {
          setGiftCard(res.data);
        }


      })
      .catch((err) => {

      })
      .finally(() => {
        dispatch(isLoadingFullPageUpdate({ status: false, msg: "Deleting..." }));
      })
  };


  const payment = (item_data) => {

    if (item_data?.card_status == '1') {

      const amount = item_data.price - item_data.rate;

      setPayable(amount);

      const finalAmount = amount - item_data.paid_amount;

      if (item_data.paid_amount == finalAmount) {

        Toast.fire({
          icon: 'success',
          title: 'Card paid'
        });

      } else {
        setCardPrice(finalAmount);
        setTransaction(item_data);
      }


    } else {
      Toast.fire({
        icon: 'error',
        title: 'Card not approved'
      });
    }

  }



  const closePopup = () => {
    setTransaction({})
  }



  useEffect(() => {
    loadData();
  }, []);

  // delete giftCard
  const deletePost = id => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        axios.delete(baseApiUrl() + 'gift-card/' + id).then(res => {

          if (res.data.msg) {
            Swal.fire(
              'Deleted!',
              'Delete Success.',
              'success',
            )

            loadData()
          }

          else {
            Swal.fire(
              'Error!',
              'Server error try again.',
              'error',
            )
          }

        })
      }
    })
  }


  const onSubmit = e => {

    e.preventDefault();

    if (cardPrice >= transaction_amount) {
      console.log("transaction_101", transaction)

      let data = new FormData();
      data.append('code', code);
      data.append("transaction_amount", transaction_amount);
      data.append("gidcard_id", transaction?.id);
      data.append("seller_id", transaction?.seller_id);

      dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..." }));
      axios.post(baseApiUrl() + 'transaction', data).then(res => {

        if (res.data.errorMsg) {
          Toast.fire({
            icon: 'error',
            title: res.data.errorMsg
          });
        } else {
          Toast.fire({
            icon: 'success',
            title: res.data.msg
          });

          setTransaction({})
          loadData();
          setTransactionAmount('');
          setCode('')
        }
      }).catch((err) => {
        responseErrorManage(err)
        console.log(err);

        // Toast.fire({
        //   icon: 'error',
        //   title: 'The code already used.'
        // });
      })
        .finally(() => {
          dispatch(isLoadingFullPageUpdate({ status: false, msg: "..." }));
        })
        ;
    }
    else {
      Toast.fire({
        icon: 'error',
        title: `Amount cannot be bigger ${cardPrice}`
      });
    }

  }

  const searchCard = e => {

    e.preventDefault();

    let data = new FormData();
    data.append('card_number', card_number);

    dispatch(isLoadingFullPageUpdate({ status: true, msg: "Searching..." }));

    axios.post(baseApiUrl() + 'search-card-number', data).then(res => {

      if (res.data.errorMsg) {
        setSearchGiftCard({});
        dispatch({
          type: "fullPageLoader_update",
          payload: false
        });

        Toast.fire({
          icon: 'error',
          title: res.data.errorMsg
        });
      }
      else {

        setSearchGiftCard(res.data.data);
      }
    }).catch((err) => {
      console.log(err);


      Toast.fire({
        icon: 'error',
        title: 'Try again'
      });
    })
      .finally(() => {
        dispatch(isLoadingFullPageUpdate({ status: false, msg: "Saving..." }));
      })
      ;


  }

  return (
    <Layout>

      {transaction?.id &&

        <div className="popup__background">
          <div className='bg-white form__center w-100 rounded'>

            {transaction &&
              <div className='py-5 px-8'>
                <div className='flex justify-between '>
                  <h1>{transaction.title}</h1>
                  <button onClick={closePopup} className="text-lg text-red-400">X</button>
                </div>

                <div className='mt-6 flex flex-wrap gap-6'>
                  <p>Price  {transaction.price}</p>
                  <p>Rate {transaction.rate}</p>
                  <p>Payable  {payable}</p>
                  <p>Paid  {transaction.paid_amount}</p>
                  <p>Due amount {cardPrice}</p>
                </div>

              </div>
            }

            <div className='bg-white rounded'>
              <form onSubmit={onSubmit} className='pb-5 px-8'>
                <div className="w-full mb-6 flex gap-6">

                  {/* <div>
                    <label className="form-label">Code</label>
                    <input type="text"
                      value={code}
                      onChange={e => setCode(e.target.value)}
                      className="form-field"
                      required
                    />
                  </div> */}

                  <div>
                    <label className="form-label">Amount</label>
                    <input type="number"
                      value={transaction_amount}
                      onChange={e => setTransactionAmount(e.target.value)}
                      className="form-field"
                      required
                    />
                  </div>

                </div>

                <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Apply</button>
              </form>
            </div>
          </div>
        </div>
      }



      {toggleSearchGiftCardForm &&

        <div className="popup__background">
          <div className='bg-white form__center w-100 rounded'>

            <div className='py-5 px-8'>
              <div className='flex justify-between '>
                <h1>Card Search Form</h1>
                <button onClick={() => setToggleSearchGiftCardForm(false)} className="text-lg text-red-400">X</button>
              </div>

            </div>


            <div className='bg-white rounded'>

              <form onSubmit={searchCard} className='pb-5 px-8'>

                <div className=''>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Card Number</label>
                  <input type="text"
                    value={card_number}
                    onChange={e => setCardNumber(e.target.value)}
                    className="form-field" required />
                </div>

                <button type="submit" className="mt-6 text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
              </form>

              {searchGiftCard?.id &&

                <div className='mt-4 px-8 pb-4'>

                  <p className='my-3'>Name : {searchGiftCard?.giftcard_offer?.title}</p>

                  <div className='flex flex-wrap gap-2'>
                    <p className='my-3'> <span className='pr-2'>Card  Number  :</span> {searchGiftCard?.card_number}</p>
                    <p className='my-3'> <span className='pr-2'>Card  Price  :</span> {searchGiftCard?.price}</p>
                    <p className='my-3'> <span className='pr-2'>Card  Rate  :</span> {searchGiftCard?.rate}</p>
                  </div>

                  <p className='my-3'> <span className='pr-2'>Card Status :</span>
                    {searchGiftCard.card_status === 0 && 'Pending'}
                    {searchGiftCard.card_status === 1 && 'Approved'}
                  </p>

                  <Link to={`/gift-card/${searchGiftCard.id}`}>
                    <button className="mt-6 text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <span className='flex gap-4'> Card Details <AiOutlineEdit /></span>

                    </button>
                  </Link>

                </div>
              }

            </div>
          </div>
        </div>
      }


      <div className='bg-white rounded-lg '>
        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Gift Card List</h3>
          <button onClick={() => setToggleSearchGiftCardForm(true)} className='button__bg px-6 py-2 block rounded'>Search Gift Card</button>
          <Link to={'/add-gift-cards'}>
            <button className='button__bg px-6 py-2 block rounded'>Add Gift Card</button>
          </Link>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {/* <th scope="col" className="px-6 py-3">
                  Image
                </th> */}
                {
                  authUserPermission !== 'seller' && <th scope="col" className="px-6 py-3">
                    Seller Info
                  </th>
                }

                <th scope="col" className="px-6 py-3">
                  Card Info
                </th>
                {
                  authUserPermission === 'admin' && <th scope="col" className="px-6 py-3">
                    Card Number
                  </th>
                }

                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate
                </th>

                <th scope="col" className="px-6 py-3">
                  Paid Amount
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Pay Status
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {giftCard?.data?.map((item, index) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    {
                      authUserPermission !== 'seller' &&
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {/* <img src={imagePath + item.image} className="w-16 border-2 h-10 object-cover" alt={item.title} /> */}
                        {item?.name}
                      </th>
                    }
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {/* <img src={imagePath + item.image} className="w-16 border-2 h-10 object-cover" alt={item.title} /> */}
                      {item?.title}
                    </th>
                    {
                      authUserPermission === 'admin' && <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {/* <img src={imagePath + item.image} className="w-16 border-2 h-10 object-cover" alt={item.title} /> */}
                        {item?.card_number}
                      </th>

                    }

                    {/* <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item?.giftcard_offer?.title}
                    </th> */}
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.card_status === 0 && <span class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded">Pending</span>}
                      {item.card_status === 1 && <span class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded">Approved</span>}
                      {item.card_status === 2 && <span class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded">Rejected</span>
                      }
                    </th>
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      $ {item.price}
                    </th>
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.rate}
                    </th>


                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.paid_amount}


                    </th>
                    {/* <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                       {item.paid === 0 && 'Pending'}
                       {item.paid === 1 && 'Paid'}
                    </th> */}

                    <td className="mt-2 px-6 py-4 flex gap-5 text-2xl">


                      <AiOutlineCheckCircle className={item.payment_log_id > 0 ? ` text-green-500 ` : ` text-white`} />

                      {
                        authUserPermission === "admin" && <>
                          <span onClick={() => payment(item)} className=' cursor-pointer'> <AiFillCreditCard /> </span>
                          <span onClick={() => deletePost(item.id)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                        </>
                      }

                      <Link to={`/gift-card/${item.id}`}> <AiOutlineEdit /></Link>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

          {giftCard?.total > 5 &&
            <div className='pl-3 py-4'>
              <Pagination
                activePage={giftCard?.current_page ? giftCard?.current_page : 0}
                itemsCountPerPage={giftCard?.per_page ? giftCard?.per_page : 0}
                totalItemsCount={giftCard?.total ? giftCard?.total : 0}
                onChange={(pageNumber) => {
                  loadData(pageNumber)
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                itemClassFirst="p-first-page-link"
                itemClassLast="p-last-page-link"
                activeClass="p-active"
                activeLinkClass="p-active-link"
                firstPageText="First Page"
                lastPageText="Last Lage"
              />
            </div>
          }
        </div>
      </div>
    </Layout>
  );
};

export default GiftCards;