import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import './style.css';

const Sidebar = () => {


  // 

  // const authUserPermission = 'user';
  const userRole = useSelector((state) => state.authInfo.permission);
  // const authUserPermission = useSelector((state) => state.authUserPermission);
  // let authUserPermission='';
  const [authUserPermission, setAuthUserPermission] = useState('')
  useEffect(() => {

    if (!userRole) {
      setAuthUserPermission('user');
    }
    else {
      setAuthUserPermission(userRole);
    }
  }, [userRole])


  const menuStyle = " bg-white pb-5 flex text-lg hover:underline ";

  return (
    <div className='px-4 bg-white h-screen text-2xl'>



      {/* General Customer */}
      <div className="mt-5">
        {/* <ul className="[&>li]:pb-5 text-[#4C3A9E] text-xl">
          <li> <NavLink to="/" className="w-full block">Dashboard</NavLink>  </li>
          <li>  <NavLink to="/" className="w-full block">Stores</NavLink>  </li>
          <li>  <NavLink to="/" className="w-full block">Transactions</NavLink>  </li>
          <li>  <NavLink to="/" className="w-full block">Payment Method</NavLink>  </li>
        </ul> */}

        <NavLink to="/dashboard" className={menuStyle}>
          Dashboard
        </NavLink>
        <NavLink to="/stores" className={menuStyle}>
          Stores
        </NavLink>





        {/* <NavLink to="/" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Home
        </NavLink>
        {
          authUserPermission === 'user' && <>
            <NavLink to="/dashboard" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Dashboard
            </NavLink>
            <NavLink to="/purchase-code" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Purchase Code
            </NavLink>
            <NavLink to="/purchase-history" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Purchase History
            </NavLink>
            <NavLink to="/transactions" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Transactions
            </NavLink>
            <NavLink to="/check-code-balance" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Check Code Balance
            </NavLink>
            <NavLink to="/reedem-code" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Reedem Code
            </NavLink>
            <NavLink to="/withdraw" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Withdraw
            </NavLink>
            <NavLink to="/profile" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Profile
            </NavLink>
          </>

        } */}


        {/* admin  */}
        {authUserPermission === 'admin' ? <>
          <NavLink to="/users" className={menuStyle}>
            User Lists
          </NavLink>
          <NavLink to="/sellers" className={menuStyle}>
            Seller Lists
          </NavLink>

          <NavLink to="/create/user" className={menuStyle}>
            Add User
          </NavLink>
          <NavLink to="/gift-cards" className={menuStyle}>
            Transaction History
          </NavLink>
          <NavLink to="/direct-payment" className={menuStyle}>
            Direct Payment
          </NavLink>
          <NavLink to="/direct-payment-list" className={menuStyle}>
            Direct Payment List
          </NavLink>
          {/* <NavLink to="/add-gift-cards" className={menuStyle}>
            Add Balance
          </NavLink> */}
          <NavLink to="/gift-card-offer" className={menuStyle}>
            Gift Card Offers
          </NavLink>
          <NavLink to="/add-gift-card-offer" className={menuStyle}>
            Add Gift  Card Offers
          </NavLink>



        </> : <></>}


        {/* admin // */}

        {/* Seller*/}
        {authUserPermission === 'seller' ? <>
          <NavLink to="/gift-cards" className={menuStyle}>
            Transaction History
          </NavLink>
          <NavLink to="/add-gift-cards" className={menuStyle}>
            Add Balance
          </NavLink>
          <NavLink to="/my-transactions" className={menuStyle}>
            Payouts
          </NavLink>
          <NavLink to="/withdraw-request" className={menuStyle}>
            Withdraw Request
          </NavLink>
          <NavLink to="/withdraw-history" className={menuStyle}>
            Withdraw History
          </NavLink>


        </>
          : <></>}

        {/* Seller //*/}

        <NavLink to="/payment-methods" className={menuStyle}>
          Withdrawal Methods
        </NavLink>
        <NavLink to="/kyc" className={menuStyle}>
          Account Limits
        </NavLink>
      </div>








    </div>
  );
};

export default Sidebar;

// profile