import React from 'react';

const SimpleBox = ({title="WriteTitleinProps",titleBgColor="bg-gray-300", titleColor="text-white",children}) => {
    return (
        <section className={`   border-2 border-gray-200  rounded-md shadow-lg`}>
            <div className={`${titleBgColor} ${titleColor} px-5 py-3 text-xl rounded`}>
                {title}
            </div>
            <div className="pl-5 pt-5 pb-5">
                {children}
            </div>



        </section>
    );
};

export default SimpleBox;