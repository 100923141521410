import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { AiOutlineMenu } from "react-icons/ai";

const HeaderTwo = () => {
    const [responsiveMenu, setResponsiveMenu] = useState('menu');
    const [toggleMenu, setToggleMenu] = useState(false);

    const menu = (e) => {
        setToggleMenu(!toggleMenu)
        // alert("test");
        let list = document.querySelector('ul');
        if (responsiveMenu === 'menu') {
            setResponsiveMenu("close");
            // list.classList.add('top-[80px]');
            // list.classList.add('opacity-100');
        }
        else {
            setResponsiveMenu("menu");
            // list.classList.remove('top-[80px]');
            // list.classList.remove('opacity-100');
        }


        // e.name === 'menu' ? (e.name = "close",list.classList.add('top-[80px]') , list.classList.add('opacity-100')) :( e.name = "menu" ,list.classList.remove('top-[80px]'),list.classList.remove('opacity-100'))
    }

    return (
        <>
       
        {/* maintainance banner  */}
            <nav class="md:p-5  md:flex md:items-center md:justify-start ">
                <div class="flex-1 flex justify-between items-center ">
                    
                    {/* Logo  */}
                    <span class="text-2xl font-[Poppins] cursor-pointer">
                        <div id="header_logo" className="text-gunmetal">
                            <Link to="/" >
                                <div className="flex items-center justify-end">
                                    <img src="/img/logo-v4.png" alt="" class="mr-2 w-[100px]  md:w-[150px] " />
                                    {/* <img src="/img/v2/logo-v3-dark-2.png" alt="" class="hidden dark:block mr-2 w-[100px]  md:w-[150px] " /> */}
                                    {/* <span className="text-lg font-semibold text-white font-circular">Nexoz</span> */}

                                    
                                </div>
                            </Link>

                        </div>
                    </span>
                    {/* Logo end. */}

                    {/* mobile toggle button  */}
                    <span class="text-3xl cursor-pointer mx-2 md:hidden block">
                        {/* <ion-icon name="menu" onclick="Menu(this)"></ion-icon> */}
                        <AiOutlineMenu className="text-gunmetal" onClick={() => setToggleMenu(!toggleMenu)} />
                    </span>
                    {/* mobile toggle button end. */}
                    

                  
                </div>

                {/* top menu links  */}
                <ul class={`flex-1 font-raleway md:bg-none text-[#0C3436] bg-[#D4EFEF] md:bg-white  md:flex md:items-center z-20 md:z-auto md:static absolute  text-gunmetal  w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 md:opacity-100  transition-all ease-in duration-300 top-[80px] pb-16 ${toggleMenu ? "opacity-100" : "opacity-0 "}  `}>
                {/* <ul class={`flex-1 font-raleway md:bg-none bg-gradient-to-r from-[#4d429e] to-[#242131]  md:flex md:items-center z-20 md:z-auto md:static absolute  text-gunmetal  w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 md:opacity-100  transition-all ease-in duration-300 top-[80px] pb-16 ${toggleMenu ? "opacity-100" : "opacity-0 "}  `}> */}
                    <li class="mx-4 my-6 md:my-0">
                        <Link to="/" class="text-lg hover:text-cyan-500 duration-500">Home</Link>
                    </li>
                    <li class="mx-4 my-6 md:my-0">
                        <a href="#service" class="text-lg hover:text-cyan-500 duration-500">Services</a>
                    </li>
                    <li class="mx-4 my-6 md:my-0">
                        <a href="#contact" class="text-lg hover:text-cyan-500 duration-500">Contact</a>
                    </li>
                    
                    <h2 class=""></h2>
                </ul>
                {/* top menu links end. */}
              
            </nav>
        </>
    );
};

export default HeaderTwo;