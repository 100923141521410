import React from 'react';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useSelector } from 'react-redux';

const LoadingScreen = () => {
    const msg = useSelector(state => state.isLoading.msg);
    const screenSize = useSelector(state => state.isLoading.screenSize)
    return (
        <div className={`z-20 bg-gray-900 opacity-70 w-full h-[100vh] absolute flex flex-col justify-center items-center`}>
            {/* 2 */}

            <AiOutlineLoading3Quarters className="animate-spin text-5xl text-white" />
            <p className="text-3xl text-white py-4">{msg}</p>
        </div>
    );
};

export default LoadingScreen;