import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import loadingSlice from '../redux/slices/loadingSlice';
import userSlice from '../redux/slices/userSlice';

import thunk from 'redux-thunk';
import creditCardSlice from '../redux/slices/CreditCardSlice';
import purchaseSlice from '../redux/slices/purchaseSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["authInfo"]
  // whitelist: ['userLoginStatus','loggedinUserInfo','authUserPermission','tokenValue', 'currentPropertyId', 'testerName']
}

const rootReducer = combineReducers({
  authInfo: userSlice,
  isLoading: loadingSlice,
  purchaseInfo: purchaseSlice, 
  creditCard: creditCardSlice
})
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]

})
let persistor = persistStore(store)
export { persistor };
export default store; 