import React from 'react';
import Layout from '../../../../components/Layout/Layout';

const WithdrawList = () => {
    return (
        <Layout>
            <div>
                Withdraw List
            </div>
        </Layout>
    );
};

export default WithdrawList;