import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';

const TransactionSaveOnly = () => {

    const transactionId = useSelector(state => state.creditCard.txnId);


    const [txnLabel, setTxnLabel] = useState("");
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    const handleSaveTxnLabel = () => {

        const payload = {
            transaction_label: txnLabel,
            txn_id: transactionId
        }


        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..."}))
        setIsSaveButtonDisabled(true)
        axios
            .post(baseApiUrl() + "paylog-save-only/" + transactionId, payload)
            .then(response => {
                console.log(response.data);
               
                if(response.data.data){
                    toast.success("Transaction has been saved.")
                    setIsSaveButtonDisabled(true);
                }

            })
            .catch(errors => {
                console.log(errors);
                setIsSaveButtonDisabled(false);

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: ""}))
            })
    }

    return (
        <div>
            Transaction Save only
            <section className="w-[600px]">

                <div className="grid grid-cols-2 my-5">
                    Transaction ID :
                    <p>{transactionId}</p>
                </div>
                <div className="grid grid-cols-2 ">
                    Transaction Name :
                    <input value ={txnLabel} onChange={(e)=>{setTxnLabel(e.target.value)}} type="text" className="input-blue" />
                </div>
                <div className="grid grid-cols-2 my-2 ">
                    <div></div>
                    <button disabled={isSaveButtonDisabled} onClick={handleSaveTxnLabel} className="btn btn-info">SAVE</button>

                </div>


            </section>

        </div>
    );
};

export default TransactionSaveOnly;