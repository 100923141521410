import React from 'react';
import NexozFooter from '../../components/footers/NexozFooter';
import NexozHeroSection from '../../components/NexozHeroSection/NexozHeroSection';
import RoobNavBar from '../../components/NexozHeroSection/RoobNavBar';
import HeaderTwo from '../../components/v2/Layout/HeaderTwo';
import HeroSectionTwo from '../../components/v2/Homepage/HeroSectionTwo';
import CardInfo from '../../components/v2/Homepage/CardInfo';
import IconContainer from '../../components/v2/Homepage/IconContainer';
import FooterTwo from '../../components/v2/Layout/FooterTwo';
import CarosoulContainer from '../../components/v2/Homepage/CarosoulContainer';
import InfoCardOne from '../../components/v2/Homepage/InfoCardOne';
import PaymentCardSolution from '../../components/v2/Homepage/PaymentCardSolution';
import BuildYourBrand from '../../components/v2/Homepage/BuildYourBrand';
import WideRange from '../../components/v2/Homepage/WideRange';
import EcommerceCard from '../../components/v2/Homepage/EcommerceCard';
import CardIcons from '../../components/v2/Homepage/CardIcons';
import "./../../components/v2/Homepage/homepage.css"

const HomepageTwo = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    return (
        <div className='pt-5 md:pt-10	bg-white '>
            <div className='px-5 md:px-10 pb-5  '>
                {
                    enableHeader && <HeaderTwo />

                }
                {
                    enableHeroSection && <HeroSectionTwo />
                }
            </div>
            <div>
                <CardInfo />
            </div>

            <div className='px-10 '>
                <IconContainer />
            </div>
            <div className='px-3 md:px-10 pb-5 md:pb-10'>
                <CarosoulContainer />
            </div>
            <div id="service" className='px-3 md:px-10 pb-5 md:pb-10'>
                <InfoCardOne />
            </div>
            <div className='px-3 md:px-10 pb-5  md:pb-10'>
                <PaymentCardSolution />
            </div>
            <div className='px-3 md:px-10 pb-5 md:pb-10 '>
                <BuildYourBrand />
                <EcommerceCard />
                <WideRange />
            </div>
            <div>
                <CardIcons />
            </div>
            <div id="contact" className='px-10 bg-[#0A2224]'>
                <FooterTwo />
            </div>


            {/* <FooterDaisy/> */}

        </div>
    );
};

export default HomepageTwo;