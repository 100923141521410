import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../utility/baseApiUrl';

const TransactionSaveToOtherPlatformUser = (isResetInputFileds) => {

    const dispatch = useDispatch();
    const transactionId = useSelector(state => state.creditCard.txnId);

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    // const [transactionLabel, setTransactionLabel] = useState("")
    const [txnLabel, setTxnLabel] = useState("");
    const [txnDate, setTxnDate] = useState("")
    const [traderName, setTraderName] = useState("")
    const [usernameLink, setUsernameLink] = useState("")
    const [traderCountry, setTraderCountry] = useState("")
    const [isTradersIdVerified, setIsTradersIdVerified] = useState(false)



    useEffect(() => {
        if (isResetInputFileds) {
            resetInputFields();
        }
    }, [isResetInputFileds])
    const resetInputFields = () => {
        setIsSaveButtonDisabled(false);
        setTxnLabel("");
        setTxnDate("")
        setTraderName("")
        setUsernameLink("")
        setTraderCountry("")
        setIsTradersIdVerified(false)
    }

    const handleSave = () => {

        const payload = {
            transaction_label: txnLabel,
            txn_date: txnDate,
            trader_name: traderName,
            username_link: usernameLink,
            trader_country: traderCountry,
            is_traders_id_verified: isTradersIdVerified,
            transaction_id: transactionId,
        }


        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..." }))
        setIsSaveButtonDisabled(true)
        axios
            .post(baseApiUrl() + "pay-other-platform", payload)
            .then(response => {
                console.log(response.data);

                if (response.data.data) {
                    toast.success("Transaction has been saved.")
                    setIsSaveButtonDisabled(true);
                }

            })
            .catch(errors => {
                console.log(errors);
                setIsSaveButtonDisabled(false);

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "" }))
            })

    }
    return (




        <div>
           
            <p className="text-lg bg-yellow-100 p-3 inline-block">Transaction Save To Other Platformuser</p>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">Transaction label</label>
                {/* <input onChange={(e) => setTransactionLabel(e.target.value)} type="text" className="input-blue text-xl" placeholder="transaction label" value=
                {transactionLabel} /> */}
                {/* <input onChange={(e) => setTransactionLabel(e.target.value)} type="text" className="input-blue text-xl" placeholder="Trader name" value={transactionLabel} /> */}
                <input type="text" onChange={(e) => setTxnLabel(e.target.value)} value={txnLabel} className="input-blue text-xl" placeholder="Transaction Label" />
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">Date</label>
                <input onChange={(e) => setTxnDate(e.target.value)} type="date" className="input-blue text-xl" placeholder="Date" value={txnDate} />
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">Trader name</label>
                <input onChange={(e) => setTraderName(e.target.value)} type="text" className="input-blue text-xl" placeholder="Trader name" value={traderName} />
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">username/link</label>
                <input onChange={(e) => setUsernameLink(e.target.value)} type="text" className="input-blue text-xl" placeholder="username/link" value={usernameLink} />
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">Traders Country</label>
                <input onChange={(e) => setTraderCountry(e.target.value)} type="text" className="input-blue text-xl" placeholder="Traders Country" value={traderCountry} />
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">Is traders ID verified on an external platform</label>
                <div className="text-left">
                    <input onChange={(e) => setIsTradersIdVerified(!isTradersIdVerified)} type="checkbox" className="input-blue text-xl" placeholder="username/link" checked={isTradersIdVerified} />
                </div>

            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mx-2 my-2">
                <label htmlFor="">Proof of purchase if applicable</label>
                <div className="text-left">
                    <input type="file" className="text-xl" placeholder="username/link" />
                </div>

            </div>
            <div className="float-right">
                <button disabled={isSaveButtonDisabled} onClick={handleSave} className="btn btn-info">SAVE</button>
            </div>


        </div>
    );
};

export default TransactionSaveToOtherPlatformUser;