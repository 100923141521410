
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LoadingScreen from './components/utility/LoadingScreen/LoadingScreen';
import './index.css';

import Master from './pages/Master';
import "./responsive.css";


function App() {

  const token = useSelector((state) => state.authInfo.token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const isLoadingFullPage = useSelector(state => state.isLoading.fullPage);

  
 
  return (
    <>



      {/* <CounterView/> */}
      <div id="mainbody" className="relative  w-full h-screen bg-white">
        {isLoadingFullPage && <LoadingScreen />}
        <Master />
        <ToastContainer />
      </div>


    </>
  );
}

export default App;
