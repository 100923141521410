import React from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import useAuth from '../../hooks/useAuth';

const Withdraw = () => {
    const auth = useAuth();
    return (
        auth ? <>
            <div className=''>
                <Layout>Withdraw </Layout>
            </div>
        </> : <Navigate to="/auth" />

    );
};

export default Withdraw;