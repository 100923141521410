import React from 'react'

const EcommerceCard = () => {

    const Card = ({ img, title, description }) => {
        return <div className='font-raleway basis-1/3 '>
            <div className={``}>
                <img src={img} className={`w-[100%] }`} />
            </div>
            <div className='flex flex-row justify-start items-center py-5'>
                
                <h3 className='text-[#ffffff] font-bold font-3xl'>{title}</h3>

            </div>
            <p className='text-[#D1EAEC] text-sm'>{description}</p>

        </div>
    }

    return (
        <div className='bg-[#1C979F] p-5 md:py-10 md:px-14'>
            <div className='flex flex-col md:flex-row'>
                <div className='md:basis-2/6 flex flex-col items-center justify-center  '>
                    {/* 40% */}
                    <div className='pr-5'>
                        <p className='h2 text-white '>An eccommerce solution for every level</p>
                        <p className='description text-white mt-5'>Dropshippers, manufacturers, whole sellers, retailers, importers and exporters can all benefit from our wide range of solutions and products to make business a lot more convenient</p>
                    </div>
                </div>
                <div className='md:basis-4/6'>
                    {/* 60% */}
                    <div className='flex flex-col md:flex-row gap-8 mt-10 '>
                        <Card img={"/img/v2/ecomm-1.png"} title={"Dropshipping."} description={"Get access to gift cards for your sales brands for up to 10% off for an extra boost in margins or afford yourself a price cut that will put you above the competition."} />
                        <Card img={"/img/v2/ecomm-2.png"} title={"Import Export."} description={"No longer is your currency a barrier, break the chains and limitations of difference in currency with our global range of products and global solutions to enable your business transact across borders with ease."} />
                        <Card img={"/img/v2/ecomm-3.png"} title={"Manufacturing, Wholesale,Retail."} description={"Tired of failed transactions during light or bulk purchases? Explore our catalog for multiple ways to pay your vendors and keep your business moving at every turn."} />


                    </div>
                </div>
            </div>
        </div>
    )
}

export default EcommerceCard