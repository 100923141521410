
import React from 'react';
import NexozFooter from '../../../components/footers/NexozFooter';
import RoobNavBar from '../../../components/NexozHeroSection/RoobNavBar';

const PrivacyPolicy = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    const textParagraphStyle = "font-sans [&>p]:text-lg font-medium [&>h2]:text-[#625e8a] bg-white"
    const paragraphTitleStyle = "text-2xl  text-[#434060] "
    // const textParagraphStyle = "text-[#605b81] text-md"
    return (
        <div>
            {
                enableHeader && <RoobNavBar />

            }
            {
                enableHeroSection && <section className="py-5 md:py-10 px-5 md:px-28 bg-white ">
                    <h1 className="text-2xl md:text-5xl font-bold text-[#493D93] pb-5">
                        Privacy Policy
                    </h1>

                    {/* <div className={textParagraphStyle}>
                        <h2 className={paragraphTitleStyle}>INTRODUCTION</h2>
                        This PRIVACY POLICY explains how we (Nexoz LLC) and its affiliates such as payment processors, payment providers, financial services providers, employees, contractors and/or entities with interest, process the Personal Information of website visitors, platform users, Account Holders and/or any person/entity that interreacts with us. You are to read and understand this policy in full, as it constitutes the core of our obligations when it relates to your Personal Information and how, when and why you provide us your Personal information and how, when and why we use/require your Personal Information. <br /><br />

                       
                    </div> */}


                    <div className={textParagraphStyle}>
                        <h2 className={paragraphTitleStyle}>INTRODUCTION </h2>
                        <p>
                            This PRIVACY POLICY explains how we (Nexoz LLC) and its affiliates such as payment processors, payment providers, financial services providers, employees, contractors and/or entities with interest, process the Personal Information of website visitors, platform users, Account Holders and/or any person/entity that interreacts with us. You are to read and understand this policy in full, as it constitutes the core of our obligations when it relates to your Personal Information and how, when and why you provide us your Personal information and how, when and why we use/require your Personal Information.</p>
                        <br />

                        <h2 className={paragraphTitleStyle}>INITIAL REMARKS</h2>
                        <p>
                            Privacy is a fundamental human right, we here at Nexoz LLC believe each person/entity deserves the right to ensure their privacy is protected and it&rsquo;s at the core of our values.</p>

                        <p>As such, we strive to protect your right, however; by law and our business operations we are required to collect Personal Information to ensure our services are provided effective, useful and of standard.</p> <br />

                        <h2 className={paragraphTitleStyle}>WHEN WE COLLECT INFORMATION</h2>
                        <p>
                            We collect information which is outlined herein when:</p>

                        <p>When you visit our website;<br />
                            When you make use of our services;<br />
                            When you register for an account;<br />
                            When you log into your account;<br />
                            When you make use of the online platforms;<br />
                            When you contact us for support, inquire about services and/or contact us in general;<br />
                            When you share your Gift Card information;<br />
                            When our payment processors verify the Gift Card information;<br />
                            When we issue payment and/or register payment towards you;<br />
                            When third parties request such information when we process your requests.<br />
                        </p>

                        <br />
                        <h2 className={paragraphTitleStyle}>PERSONAL INFORMATION WE COLLECT</h2>
                        <p>  Personal Information we collect, is only information you willingly provide us and general Personal Information which is automatically collected by us.</p><br />

                        <h2 className={paragraphTitleStyle}>INFORMATION YOU PROVIDE US</h2>
                        <p>
                            Contact information, such as your first and last name, email address, mailing addresses, phone number, and company name.<br />
                            Profile information, such as your username and password that you may set to establish an online account with us, your date of birth, your gender, etc..<br />
                            Feedback or correspondence, such as information you provide when you contact us with questions, feedback, or otherwise correspond with us online.<br />
                            Payment information, such as your credit or debit card information, cryptocurrency wallet address, or the fact that payment was completed through a third-party payment solution (such as PayPal); and, in the case of corporate sales, bank account information and purchase order number furthermore payee information for check and bank account information for personal accounts shall also be collected and/or where provided.<br />
                            Transaction information, such as information about the gift cards you purchase and the personal message you may choose to include in connection with a gift card order<br />
                            Gift card information, such as your gift card number and PIN.<br />
                            Usage information, such as information about how you use the Service and interact with us, including information associated with any content you submit to us.<br />
                            Marketing information, such as information such on your preferences for receiving communications about our offerings and promotions, and details about how you engage with our communications.<br />
                            Other information that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>GIFT CARD RELATED INFORMATION</h2>
                        <p>
                            When you provide us Gift Card information we require, such shall include not limited:</p>

                        <p>Card Number;<br />
                            Pin Number, CVC/PIN and/or Expiry date where applicable;<br />
                            Gift Card Receipt;<br />
                            E-mail address;<br />
                            Phone Number;<br />
                            Recipients permission<br />
                        </p>

                        <br />
                        <h2 className={paragraphTitleStyle}>AUTOMATICALLY COLLECTED information</h2>
                        <p> We automatically collect, when you visit our website/platform:</p>

                        <p>IP addresses;<br />
                            Your current location;<br />
                            VPN information;<br />
                            Device you connect with.<br /><br />

                            <h2 className={paragraphTitleStyle}>
                            HOW WE USE YOUR INFORMATION<br />
                            FOR OUR SERVICES</h2>
                            We use your information to operate the services we offer, as such we use your personal information to:</p>

                        <p>
                            provide, operate and improve the Service<br />
                            provide information about our products and services<br />
                            establish and maintain your user profile on the Service<br />
                            communicate with you about the Service, including by sending you - announcements, updates, security alerts, and support and administrative messages<br />
                            understand your needs and interests, and personalize your experience with the Service and our communications<br />
                            provide support and maintenance for the Service to respond to your requests, questions and feedback<br /><br />


                           <h2 className={paragraphTitleStyle}> FOR SAFETY, FRAUD PREVENTION, COMPLIANCE</h2>
                            We may use your personal information as we believe necessary or appropriate to:</p>

                        <p>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;<br />
                            protect our, your or others&rsquo; rights, privacy, safety or property (including by making and defending legal claims);<br />
                            enforce the terms and conditions that govern the Service or any gift card purchased through the Service; and<br />
                            protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.<br /><br />
                           
                           
                            <h2 className={paragraphTitleStyle}> YOUR CONSENT</h2>
                            In some cases we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>HOW WE SHARE YOUR PERSONAL INFORMATION</h2>
                        <p>
                            We don&rsquo;t sell your Personal Information, we do however share it with affiliates and relevant authorities where required.</p>

                        <p>We may employ third party companies and individuals to administer and provide services on our behalf;</p>

                        <p>We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us;</p>

                        <p>We may share your personal information with third parties for the compliance, fraud prevention and safety purposes described above. When we share information with fraud prevention companies, those companies may use information about you to help us and their other clients prevent fraudulent or other illegal transactions.</p>

                        <p>We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>YOUR RIGHTS, YOUR ACCOUNT</h2>
                        <p>You are able to delete, revise, amend and/or change the Personal Information on your account.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>CHOOSING NOT TO SHARE YOUR INFORMATION</h2>
                        <p>
                            Where we are required by law to collect your personal information, or where we need your personal information in order to provide the Service to you, if you do not provide this information when requested (or you later ask to delete it), we may not be able to provide you with our services.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>YOUR SECURITY</h2>
                        <p>
                            We take reasonable measures to protect your personal information in an effort to prevent loss, misuse, and unauthorized access, disclosure, alteration and destruction. However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>CHILDREN</h2>
                        <p>
                            We do not offer our services for any person under the age of eighteen (18), unless parental consent is granted, as such; the legal guardian is therefore bound by this privacy policy.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>CHANGES TO POLICY</h2>
                        <p>
                            We reserve the right to modify this Privacy Policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service.</p>

                        <p>We may (and, where required by law, will) also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through the Service.</p>

                        <p>
                            Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on the Service (or as otherwise indicated at the time of posting). In all cases, your continued use of the Service after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>

                        <p>You can request the following information about how we have collected and used your Personal Information during the past 12 months:<br />
                            You can request a copy of the Personal Information that we maintain about you<br />
                            You can ask us to delete the Personal Information that we maintain about you.<br />
                            You are entitled to exercise the rights described above free from discrimination. This means that we will not penalize you for exercising your rights by taking actions such as by denying you goods or services, increasing the price/rate of goods or services, decreasing the service quality, or suggesting that we may penalize you as described above for exercising your rights. However, the CCPA allows us to charge you a different price or provide a different service quality if that difference is reasonably related to the value of the Personal Information we are unable to use.<br />
                            HOW TO EXECUTE YOUR RIGHTS<br />
                            Contact us via legal@nexoz.net.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>CONTACT US</h2>
                        <p>
                            If you have any questions, you may contact our legal team at legal@nexoz.net for further information.</p>

                    </div>



                </section>
            }
            {
                enableFooterSection && <NexozFooter />
            }

            {/* <FooterDaisy/> */}

        </div>
    );
};

export default PrivacyPolicy;