const { createSlice } = require("@reduxjs/toolkit");

export const creditCardSlice = createSlice({
    name: "creditCard",
    initialState: { isShowCardInput: true, txnId: 0, chargedAmount: 0  },   // initialState: {count:0},
    reducers: {
        updateIsShowCardInput: (state, action) => {
            console.log("redux_101", action)
            // console.log("redux_102",action.payload)
            // console.log("redux_103",state.isShowCardInput)


            'isShowCardInput' in action.payload ? state.isShowCardInput = action.payload.isShowCardInput : state.isShowCardInput = state.isShowCardInput;
            'txnId' in action.payload ? state.txnId = action.payload.txnId : state.txnId = state.txnId;
            'chargedAmount' in action.payload ? state.chargedAmount = action.payload.chargedAmount : state.chargedAmount = state.chargedAmount;

            console.log("redux_104", state)
        },
    }
})

export const { updateIsShowCardInput } = creditCardSlice.actions;
export default creditCardSlice.reducer;