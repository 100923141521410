import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Layout from '../../../../components/Layout/Layout';
import useAuth from '../../../../hooks/useAuth';
import baseApiUrl from '../../../../utility/baseApiUrl';
// import storeList from '../CardList.json';

const ProductStocks = () => {
    const auth = useAuth();
    let { skus } = useParams();


    // States
    const [productStockList, setProductStockList] = useState([]);
    // States//



    const getProductStockList = () => {
        // call the ss , to get purchase details
        const data = {
            "skus": skus
        }

        axios.post(baseApiUrl() + "pf-find-stocks", { ...data })
            .then(response => {
                console.log("test_101", response.data);
                setProductStockList(response.data);
            })
            .catch(error => {
                console.log("error_getPurchaseDetails", error);
            })
            .finally(() => {

            })


        // iF not paid yet call the nowpayments getway to check the latest payment status.
    }


    useEffect(() => {
        getProductStockList()
    }, [])

    return (
        auth ? <>
            <div className=''>
                <Layout>
                    <div className="bg-[#E2E6E7]   py-5 px-4">

                        {/* page title  */}
                        <section className="stores__card bg-white rounded min-h-screen shadow-xl  px-5">


                            {/* page title // */}

                            {/* store search  */}
                            <section className="mb-2">
                                <h1 className="text-xl text-[#51575e] py-2">Amazon Sotck List</h1>
                                <hr className="text-[#51575e]  " />
                            </section>
                            {/*<section className="my-3">

                                <form class="flex items-center">
                                    <label for="voice-search" class="sr-only">Search</label>
                                    <div class="relative w-full">
                                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <input type="text" id="voice-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter a store name..." required="" />
                                    </div>
                                    <button type="submit" class="inline-flex items-center py-2.5 px-10 ml-2 text-sm font-medium text-white bg-gradient-to-r from-[#4d429e] to-[#242131] rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Search
                                    </button>
                                    <button type="submit" class="inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white bg-gradient-to-r from-[#242131] to-[#4d429e] rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Clear
                                    </button>
                                </form>


                            </section> */}

                            {/* store search end */}

                            {/* store list loop  */}

                            <section class="">
                                <table class=" w-full">
                                    {/* <thead>
                                        <tr>
                                            <th>Song</th>
                                            <th>Artist</th>
                                            <th>Year</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>

                                        <tr>
                                            <td>
                                                Product
                                            </td>
                                            <td>
                                                Price
                                            </td>
                                            <td>
                                                Quantity
                                            </td>
                                            <td>
                                                Action
                                            </td>
                                           
                                        </tr>
                                        {
                                            productStockList.map((productItem, index) => <tr className={` items-center  ${index % 2 === 1 ? "bg-gray-200" : ""}`}>
                                                {/* <td >
                                                    <div className="py-1">
                                                        <img src={productItem.img} alt="" className="h-[100px] ml-5 rounded " />
                                                    </div>
                                                </td> */}
                                                <td>
                                                    <p className="py-2">
                                                        {productItem.product}
                                                        {/* <br /> */}
                                                        {/* <span className="text-xs">
                                                           {store.status}
                                                        </span> */}

                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="py-2">
                                                        ${productItem.purchasePrice}
                                                        {/* <br /> */}
                                                        {/* <span className="text-xs">
                                                           {store.status}
                                                        </span> */}

                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="py-2">
                                                        {productItem.quantity}
                                                        {/* <br /> */}
                                                        {/* <span className="text-xs">
                                                           {store.status}
                                                        </span> */}

                                                    </p>
                                                </td>
                                                {/* <td>Earn up to {store.earnings}</td> */}
                                                {/* <a href="/"></a> */}
                                                <td className="text-right pr-5">
                                                    <button className="font-extrabold text-transparent  bg-clip-text bg-gradient-to-r from-blue-900 to-violet-700">Buy now</button>
                                                </td>

                                            </tr>)
                                        }




                                    </tbody>
                                </table>
                            </section>
                            {/* store list loop // */}

                        </section>


                    </div>
                </Layout>
            </div>
        </> : <Navigate to="/login" />
    );
};

export default ProductStocks;