import React, { useEffect } from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { screenSize } from '../../redux/slices/loadingSlice';
import Sidebar from './Sidebar/Sidebar';
import TopNavBar from './TopNavBar/TopNavBar';

const Layout = ({ children }) => {
    
    
    const dispatch = useDispatch();
    useEffect(() => {
        const divHeight = document.getElementById('layout_wrapper').clientHeight;    
        console.log("divHeight", divHeight)
        dispatch(screenSize(divHeight))
        
       
    
      }, [])
     
    function loadBody() {

        const sidebar = document.getElementById("sidebar");
        sidebar.classList.toggle('toggle__Sidebar');
    }
    function loadBodyMobile() {

        const sidebar = document.getElementById("sidebar");
        sidebar.classList.toggle('toggle__phone__Sidebar');

    }
    return (
        <div id="layout_wrapper">

            <TopNavBar></TopNavBar>

            <div className='flex relative layout-responsive-container'>

                <div id="sidebar" className='sidebar z-10'>
                    <Sidebar></Sidebar>
                </div>

                <div className='content__body'>

                    <div className='flex items-center mb-3 z-20 flex-row-reverse md:flex-row'>
                        {/* navigation toggle  */}
                        <div className='inline-block'>

                            <div onClick={loadBody} id="menuicon" className=" lg:block md:block  hidden  text-lg cursor-pointer light__bg rounded-md p-2">
                                <AiOutlineMenu />
                            </div>

                            <div onClick={loadBodyMobile} id="phone_menu_icon" className="lg:hidden md:hidden  block text-lg cursor-pointer light__bg rounded-md p-2">
                                <AiOutlineMenu />
                            </div>
                        </div>
                        {/* navigation toggle  */}
                        {/* breadcrumb */}
                        <div>
                            <h1 className="pl-3 text-[#4d429e] text-lg ">Home / Dashboard</h1>
                        </div>
                        {/* breadcrumb */}
                    </div>





                    {children}
                </div>

            </div>

        </div>
    );
};

export default Layout;