import React from 'react';
import NexozFooter from '../../../components/footers/NexozFooter';
import RoobNavBar from '../../../components/NexozHeroSection/RoobNavBar';

const TermOfUse = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    const textParagraphStyle = "font-sans [&>p]:text-lg font-medium [&>h2]:text-[#625e8a] bg-white"
    const paragraphTitleStyle = "text-2xl  text-[#434060] "
    // const textParagraphStyle = "text-[#605b81] text-md"
    return (
        <div>
            {
                enableHeader && <RoobNavBar />

            }
            {
                enableHeroSection && <section className="py-5 md:py-10 px-5 md:px-28 bg-white ">
                    <h1 className="text-2xl md:text-5xl font-bold text-[#493D93] pb-5">
                        Term Of Use
                    </h1>

                    <div className={textParagraphStyle}>
                        <p>
                            Here at Nexoz LLC (hereinafter “us/we”), we offer essentially a single service, where you can sell us your Gift Card (either MasterCard, Visa, Amazon, and/or any major retailer where permitted and accepted by Nexoz), where we shall process the Gift card and issue you a payment in return or other gift cards that we provide.
                        </p>
                        <br />
                        <p>
                            To ensure the smooth transaction and continued use of our services, you; the customer, visitor, guest, and/or entity/person making use of our services, website, and/or platforms unconditionally agree to this term of use by making use of our services, visiting our website and/or platforms, you agree to be bound by the terms and conditions of this terms of use. This Terms of Use does not regulate the sale of the Gift Cards to us, and it merely regulates the use and access of our services, platform, website and/or online related services, whichever may be applicable.
                        </p>
                        <br />
                        <p>
                            Where you make use of our services (which is where you sell your Gift Cards to us, and we process it) you are bound by this Terms of Use and the Terms of Sale.
                        </p>
                        <br />
                        <p>
                            You further agree to be bound by our Privacy Policy, and to provide documents/information to us pertaining to our Know Your Customer (KYC) policy and our Anti Money Laundering Policy (AML), at our request.
                        </p>
                        <br />
                        <p>
                            We repeat, by making use of our services, accessing our services and/or interacting with us in any way, given where you are a customer and/or a guest, you unconditionally agree to our policies and you furthermore agree to be bound by the conditions thereof. We are automatically bound by the policies.
                        </p>
                        <br />
                        <p>
                            We confirm that the intention of our policies is not to create confusion and/or result in blanket protection of our company, but to ensure adequate procedures, regulations, and/or policies are followed to ensure your safekeeping, smooth transactions and finally for your protection (likewise ours).
                        </p>
                        <br />
                        <p>
                            Should you reject/disagree and/or not accept our policies, you are directed to leave our services, website and/or platforms immediately and therefore, you cannot conduct business with us until such time you agree unconditionally to our respective policies.
                        </p>
                        <br />
                    </div>

                    <div className={textParagraphStyle}>
                        <br />
                        <h2 className={paragraphTitleStyle}>DISCLAIMER</h2>
                        <p>
                            While we employ to create the best experience for all our customers and guests, errors do occur, we, therefore, provide no warranty to how our services are to operate nor the platforms (website and/or online services), as such the website and services are provided AS-IS, we may at any time adapt our services, platform and/or website without any notice to you and we may suspend and/or terminate services, platform and/or website at any time without notice to you.
                        </p>
                        <br />
                    </div>
                    <div className={textParagraphStyle}>
                        <br />
                        <h2 className={paragraphTitleStyle}>ELIGIBILITY OF USE</h2>
                        <p>
                            We are bound by the current legislation of the United States of America. As such we cannot offer our services or access to our services, website and/or online platforms to any person under the age of eighteen (18) without parental consent.
                        </p>
                        <br />
                        <p>
                            Should you be under the age of eighteen (18), you are requested to obtain parental consent and/or consent from your legal guardian.
                        </p>
                        <p>
                            We have no restrictions on our business operations with duly registered entities within the United States of America.
                        </p>
                        <br />
                        <p>
                            WE SHALL NOT do any business with any person and/or entity which is located within a state and/or country which the United States Government sanctions,. Should you be located within a sanctioned country, you are emplored to contact our internal compliance department at legal@nexoz.net to determine your eligibility.
                        </p>
                        <br />
                    </div>
                    <div className={textParagraphStyle}>
                        <br />
                        <h2 className={paragraphTitleStyle}>ACCOUNTS</h2>
                        <p>
                            <span className="italic">
                                Safety is our top priority; as such, you hereby agree that:
                            </span>
                            <br />
                            You are duly authorized and/or hold the required consent and capacity to create an account on our platform as either a natural person or entity.<br />
                            You are duly authorized to use the applicable account to enter and conclude transactions. <br />
                            You agree to not publish your login information on any platform nor share your login details.<br />
                            You agree to contact us immediately should you believe your account has been breached.<br />
                            You agree not to share your account access with any other person and/or entity.<br />
                            You agree that you shall be fully liable for any actions you commit with the account. You shall indemnify us from any claim for harm, damages, and/or consequential damages or whichever claim you may have or believe in having to your account.<br />
                            You shall use the correct personal information when signing up and using the account.<br />




                        </p>
                        <br /> <br />
                        <h2 className={paragraphTitleStyle}>OUR INTELLECTUAL PROPERTY RIGHTS</h2>
                        <p>
                            All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, "Content"), including but not limited to the design, structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained on the Site is owned, controlled or licensed by or to Nexoz LLC, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights. <br /> <br />
                            You have no right to use our intellectual property, nor do we consent to the use of our intellectual property <br /> <br />
                            Should you require consent for whichever reason to use our intellectual property, you are to contact legal@nexoz.net beforehand and obtain written consent and/or rejection. A nonresponse does not contribute to acceptance.

                        </p>
                        <br /> <br />
                        <h2 className={paragraphTitleStyle}>LIMITED LIABILITY</h2>
                        <p>
                            Except where prohibited by law, in no event will Nexoz LLC be liable to you for any indirect, consequential, exemplary, incidental, or punitive damages, including lost profits, even if we have been advised of the possibility of such damages. <br /> <br />
                            If notwithstanding the other provisions of these Terms of Use, We are found to be liable to you for any damage or loss which arises out of or is in any way connected with your use of the Site or any Content, our liability shall in no event exceed the greater of the amount of $100.00 (ONE HUNDRED UNITED STATES DOLLARS); <br /> <br />
                            Where some jurisdictions do not allow for limited liability, you shall waive your right in terms of such limited liability and/or where it is not allowed; refer to point 5.3. <br /> <br />
                            In no event shall your damages exceed the amount you have received from us in terms of the Gift Card payouts.

                        </p>
                        <br />
                    </div>

                    <div className={textParagraphStyle}>
                        <br />
                        <h2 className={paragraphTitleStyle}>INDEMNITY</h2>

                        You agree to indemnify and hold Nexoz LLC, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees) made against us by any third party due to or arising out of or in connection with your use of the website, platform and/or online services.


                        <br />
                        <br />
                        <h2 className={paragraphTitleStyle}>VIOLATION OF THE TERMS OF USE</h2>



                        <p>
                            You agree that we may, in at our sole discretion and without prior notice, terminate your access to our services, website and/or online platform and/or block your future access to the website and/or online platform if we determine that you have violated these Terms of Use or other agreements or guidelines which may be associated with your use of the website and/or online platform. <br /> <br />

                            You also agree that any violation by you of these Terms of Use will constitute an unlawful and unfair business practice and will cause irreparable harm to us, for which monetary damages would be inadequate, and you consent to us obtaining any injunctive or equitable relief that we deem necessary or appropriate in such circumstances. These remedies are in addition to any other remedies we may have at law or in equity.<br /> <br />
                            You agree that we may, in our sole discretion and without prior notice, terminate your access to the website and/or online platform, for cause, which includes (but is not limited to)
                            requests by law enforcement or other government agencies,
                            a request by you (self-initiated account deletions),
                            discontinuance or material modification of the Site or any service offered on or through the website and/or online platform, or
                            unexpected technical issues or problems.
                        </p>

                        <br /> <br />
                        <h2 className={paragraphTitleStyle}>GOVERNING LAW</h2>
                        <p>
                            You agree and consent to the jurisdiction of the United States of America, the State of Texas. As such, only an applicable court within the jurisdiction shall hold the required competency and jurisdiction to preside over any dispute which may occur.
                        </p>


                        <br /> <br />
                        <h2 className={paragraphTitleStyle}>SEVERABILITY</h2>

                        <p>
                            Suppose any of these Terms of Use provisions are held by a court or other tribunal of competent jurisdiction to be void or unenforceable. In that case, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use so that these Terms of Use shall remain in full force and effect.
                        </p>


                        <br /> <br />
                        <h2 className={paragraphTitleStyle}>CONTACT</h2>
                        <p>
                            Should you require any information and/or request assistance, you are to direct your complaint/notice and/or inquiry to legal@nexoz.net.
                        </p>


                    </div>


                </section>
            }
            {
                enableFooterSection && <NexozFooter />
            }

            {/* <FooterDaisy/> */}

        </div>
    );
};

export default TermOfUse;