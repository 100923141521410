import React from 'react'

const CardInfo = () => {


    const Card = ({title, description}) => {
        return <div className='bg-white rounded-lg min-w-[278px] max-w-[300px] min-h-[170px] p-5 font-raleway border-[1px] border-[#cfcfcf]'>
            {/* title  */}
            <div className='text-2xl text-black font-bold pb-2'>{title}</div>
            {/* description  */}
            <div className='text-lg text-[#4d4d4d] font-normal	'>{description}</div>
        </div>
    }

    return (
        <div className='bg-[#F5F4F7]  flex flex-col justify-center items-center py-10	md:py-[100px]	'>


            {/* // Card Container  */}
            <div className='flex flex-col md:flex-row gap-4 md:gap-10 md:justify-around '>
                {/* // Card  */}
                <Card title="Rewards & Incentives" description="Enticing rewards that foster loyalty and drive customer acquisition." />
                <Card title="Gift Cards" description="Gift card promotions, products, and distribution channels that accelerate revenue growth With INSTANT DELIVERY!!" />
                <Card title="Payments" description="Payments and services that allow businesses and customers to manage and use their money conveniently and creatively." />
                <Card title="Web/App Services" description="Get your custom mobile or web app that integrates your business and products with ours to create a unique solution just for you" />
      
            </div>


        </div>
    )
}

export default CardInfo