import { React, useEffect, useState } from 'react';
import { AiFillCreditCard } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';



import Pagination from "react-js-pagination";
import Layout from '../../../components/Layout/Layout';
import baseApiUrl from '../../../utility/baseApiUrl';

const axios = require("axios");

const SellerLists = () => {
  

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [users, setUser] = useState({});

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pageLoaderReducer);


  const [transaction, setTransaction] = useState(false);
  const [sellserCardInfo, setSellserCardInfo] = useState({});
  const [sellserInfo, setSellserInfo] = useState({});
  const [code, setCode] = useState(''); // changeHere - stateName correction
  const [transaction_amount, setTransactionAmount] = useState(''); // changeHere - stateName correction
  const [dueAmount, setDueAmount] = useState(0); // changeHere - stateName correction
  // const [paymentIntentInfo, setpaymentIntentInfo] = { "seller_id": 0, "paid_amount": 0, "due_amount": 0, "code": "" }

  // load users from server
  const loadData = (pageNumber = 1) => {

    dispatch({
      type: "fullPageLoader_update",
      payload: true
    });

    axios
      .get(baseApiUrl() + "seller-lists?page=" + pageNumber)
      .then((res) => {

        dispatch({
          type: "fullPageLoader_update",
          payload: false
        });
        setUser(res.data);

      })
      .catch((err) => {
        dispatch({
          type: "fullPageLoader_update",
          payload: false
        });
      })


    console.log('test-5', isLoading)
  };


  useEffect(() => {
    loadData();
  }, []);



  const payment = (sellerItem) => {

    

    setDueAmount(sellerItem.seller_due)
    console.log("i m popup action")

    setTransaction(true);
    setSellserInfo(sellerItem)

    // axios.get(baseApiUrl() + 'seller-card-info/' + sellerItem.id).then(res => {

    //   setSellserCardInfo({});

    //   const data = {
    //     pending_cards: res.data.pending_cards,
    //     paid_cards: res.data.paid_cards,
    //   }

    //   setSellserCardInfo(data);

    // })

  }

  const closePopup = () => {
    setTransaction(false)
  }


  const onSubmit = e => {

    e.preventDefault();

    let data = new FormData();
    data.append('code', code);
    data.append("transaction_amount", transaction_amount);

    axios.post(baseApiUrl() + 'multiple-transaction/' + sellserInfo?.id, data).then(res => {


    }).catch((err) => {
      console.log(err);

      Toast.fire({
        icon: 'error',
        title: 'server error'
      });
    });

  }
  const bulkTransaction = (e) => {
    e.preventDefault(); 
    
   
    if(transaction_amount> parseInt(sellserInfo.seller_due)){
      console.log("condition",sellserInfo);
      Toast.fire({
        icon: 'error',
        title: 'Paid amount could not be large than due.'
      });
      return; 
    }
    if(transaction_amount==0){
      Toast.fire({
        icon: 'error',
        title: 'Paid amount can not be Zero'
      });
      return; 
    }
    if(!code){
      Toast.fire({
        icon: 'error',
        title: 'Payment code is empty'
      });
      return; 
    }
    console.log("sellserInfo",sellserInfo);
    // alert("hello");

    

    
   

    const data = {
      seller_id: sellserInfo.id, 
      paid_amount: transaction_amount, 
      code: code, 
    }

    dispatch({
      type: "fullPageLoader_update",
      payload: true
    });
    axios.post(baseApiUrl() + 'bulk-payment', data)
    .then(res =>{

      console.log("test-101", res); 
      console.log("test-102",res.data); 
      console.log("test-103",res.status);

      dispatch({
        type: "fullPageLoader_update",
        payload: false
      });
      Toast.fire({
        icon: 'success',
        title: 'Payment succeeded'
      });
      closePopup();
      loadData();

     
      console.log(res.data); 
    })
    .catch(errors =>{
      dispatch({
        type: "fullPageLoader_update",
        payload: false
      });
      console.log(errors)
    }

    )
    .finally()

  }

  return (
    <Layout>

      {transaction &&

        <div className="popup__background">
          <div className='bg-white form__center w-100 rounded'>

            {transaction &&
              <div className='py-5 px-8'>
                <div className='flex justify-between '>
                  <h1 > <span style={{ fontWeight: 'bold' }}>Name : </span> {sellserInfo?.name}</h1>
                  <button onClick={closePopup} className="text-lg text-red-400">X</button>
                </div>

                {/* <div className='mt-6 flex flex-wrap gap-6'>
                             <p>Total Paid Cards {sellserCardInfo?.paid_cards} </p>
                             <p>Due Cards {sellserCardInfo?.pending_cards} </p>
                             
                            
                         </div> */}
                <p> <span style={{ fontWeight: 'bold' }}>Payable amount :</span> {dueAmount} </p>

              </div>
            }


            <div className='bg-white rounded'>
              <form  className='pb-5 px-8'>
                <div className="w-full mb-6 flex gap-6">
                  <div>
                    <label className="form-label">Code</label>
                    <input type="text"
                      value={code}
                      onChange={e => setCode(e.target.value)}
                      className="form-field"
                      required
                    />
                  </div>

                  <div>
                    <label className="form-label">Amount</label>
                    <input type="number"
                      value={transaction_amount}
                      onChange={e => setTransactionAmount(e.target.value)}
                      className="form-field"
                      required
                    />
                  </div>

                </div>
                {
                  !isLoading && <button onClick={(e) => bulkTransaction(e)} type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Apply</button>
                }
                
              </form>
            </div>
          </div>
        </div>
      }
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Seller List</h3>
          <Link to={'/create/user'}>
            <button className='button__bg px-6 py-2 block rounded'>Add Seller</button>
          </Link>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>

                <th scope="col" className="px-6 py-3">
                  Seller name
                </th>
                <th scope="col" className="px-6 py-3">
                  Seller Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Due
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  User Role
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Bulk Payment
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {users?.data?.map((item, index) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.name}
                    </th>
                    <td className="px-6 py-4">
                      {item.email}
                    </td>
                    <td className="px-6 py-4">
                      {item.seller_due}
                    </td>
                    {/* <td className="px-6 py-4 capitalize ">
                      {item.role}
                    </td> */}
                    <td className="px-6 py-4 flex gap-5 text-2xl">
                      {parseInt(item.seller_due) > 0 && <span onClick={() => payment(item)} className=' cursor-pointer'> <AiFillCreditCard /> </span>}

                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

          {users?.total > 5 &&
            <div className='pl-3 py-4'>
              <Pagination
                activePage={users?.current_page ? users?.current_page : 0}
                itemsCountPerPage={users?.per_page ? users?.per_page : 0}
                totalItemsCount={users?.total ? users?.total : 0}
                onChange={(pageNumber) => {
                  loadData(pageNumber)
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                itemClassFirst="p-first-page-link"
                itemClassLast="p-last-page-link"
                activeClass="p-active"
                activeLinkClass="p-active-link"
                firstPageText="First Page"
                lastPageText="Last Lage"
              />
            </div>
          }
        </div>
      </div>
    </Layout>
  );
};

export default SellerLists;