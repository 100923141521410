import React from 'react';

const PrivatePageTest = () => {
    return (
        <div>
            Protected page , here should able to come only when loggged in state. 
        </div>
    );
};

export default PrivatePageTest;