import React from 'react'

const InfoCardOne = () => {


    const Card = ({ img, title, description }) => {
        return <div className='font-raleway md:basis-1/3 px-10 py-4 md:p-0'>
            <div className={``}>
                <img src={img} className={`w-[100%] }`} />
            </div>
            <div className='flex flex-row justify-start items-center py-3 md:py-5'>
                <img src="/img/v2/gift-icon.png" className='pr-3' />
                <h3 className='text-[#ffffff] font-bold font-3xl'>{title}</h3>

            </div>
            <p className='text-[#D1EAEC] text-sm'>{description}</p>

        </div>
    }

    return (
        <section className='bg-[#1C979F] rounded-xl py-10 md:py-20 md:px-40'>
            <div className='flex flex-col '>
                {/* Description  */}
                <div className='flex flex-col justify-center items-center font-raleway px-5 '>

                    <div className='flex flex-row justify-center  w-[100%] '>
                        <div className='px-1 md:px-0 md:basis-3/5  w-[100%]'>
                            <div className=' flex flex-col justify-center items-center gap-3'>
                                <p className='text-[#D1EAEC] text-xl text-center'>BROAD COMPETENCIES</p>
                                <p className='h2 text-[#D1EAEC] text-center'>
                                    You're fully supported with our entire suite of services.
                                </p>
                                <p className='text-[#D1EAEC] text-xl text-center '>
                                    We provide an extensive array of services tailored to your business needs, targeting these pivotal areas: Awareness, Acquisition, Conversion, Loyalty and Retention
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
                {/* blog columns  */}
                <div className='flex flex-col md:flex-row md:gap-8 mt-10 '>
                    <Card img={"/img/v2/card-img-1.png"} title={"Gift Cards and eGifts"} description={"Our gift card management solutions foster business growth through a scalable platform, a comprehensive network, and advanced industry strategies."} />
                    <Card img={"/img/v2/card-img-2.png"} title={"Payment Solutions"} description={"We empower merchants, individuals, and corporations to deliver cutting-edge payment experiences through necessary integrations designed for their specific needs."} />
                    <Card img={"/img/v2/card-img-3.png"} title={"Reward Program"} description={"Our Incentive Solutions supply timely, customizable, and meaningful rewards that motivate consumers, employees and fans to take desired actions."} />


                </div>
            </div>
        </section>
    )
}

export default InfoCardOne