const { createSlice } = require("@reduxjs/toolkit");

export const loadingSlice = createSlice({
    name: "isLoading",
    initialState: {fullPage:false, component: false, msg:"Laoding...",screenSize:"100vh"},   // initialState: {count:0},
    reducers: {
        
        isLoadingFullPageUpdate: (state,action) => {
            
            // console.log('redux_201',  action.payload)
            state.fullPage =  action.payload.status;
            state.msg =  action.payload.msg;
        },
        isLoadingComponentUpdate:(state,action) => {
            // console.log('redux-101', action, action.payload.role)
            state.component =  action.payload;
        }, 
        screenSize:(state,action) => {
            // console.log('redux-101', action, action.payload.role)
            state.screenSize =  action.payload;
        }, 

        // loggedInTrue: (state,action) => {
        //     console.log('redux-101', action, action.payload.role)
        //     state.status = true; 
        //     state.user = action.payload;
        //     state.permission = action.payload.role;
        // },


    }
})

export const { isLoadingFullPageUpdate,isLoadingComponentUpdate ,screenSize } = loadingSlice.actions;
export default loadingSlice.reducer;