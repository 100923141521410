import React from 'react';

const OpenPageTest = () => {
    return (
        <div>
            Can view wihtout Login. 
            Public Page
        </div>
    );
};

export default OpenPageTest;