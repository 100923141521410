import React from 'react';
import NexozFooter from '../../../components/footers/NexozFooter';
import RoobNavBar from '../../../components/NexozHeroSection/RoobNavBar';

const TermsOfSale = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    const textParagraphStyle = "font-sans [&>p]:text-lg font-medium [&>h2]:text-[#625e8a] bg-white"
    const paragraphTitleStyle = "text-2xl  text-[#434060] "
    // const textParagraphStyle = "text-[#605b81] text-md"
    return (
        <div>
            {
                enableHeader && <RoobNavBar />

            }
            {
                enableHeroSection && <section className="py-5 md:py-10 px-5 md:px-28 bg-white ">
                    <h1 className="text-2xl md:text-5xl font-bold text-[#493D93] pb-5">
                        TERMS OF SALE
                    </h1>





                    {/* start  */}
                    <div className={textParagraphStyle}>

                        <br />
                        <h2 className={paragraphTitleStyle}>TERMS OF SALE</h2>
                        <p>
                            THIS AGREEMENT is entered into and between yourself, the account holder (hereinafter &#39;the Account Holder&#39;), and Nexos LLC (&#39;the Company&#39;)</p>

                        <p>Nexoz LLC offer essentially a single service, where one can sell Gift Card (either MasterCard, Visa, Amazon, and/or any major retailer where permitted and accepted by Nexoz), where it shall use processors for the Gift card and issue payment in return to the account holder, the parties have agreed, whereas the Company shall obtain the required information of a Gift Card wholly owned by the Account Holder.</p>

                        <p>The Account Holder automatically accepts these terms of sale when it wishes to sell the Company their respective Gift Cards. Should the Account Holder wishes to reject, disregard, and/or not agree to the Terms of Sale, he/she/it is to delete, terminate, and/or not offer and/or provide its Gift Card information to the Company.</p>

                        <p>With reference to the above, the Account Holder agrees as follows:</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>GIFT CARD INFORMATION</h2>
                        <p>
                            The Account Holder shall provide utilizing the online platform the applicable Gift Card information, which shall include but is not limited to:</p>

                        <p>Gift Card Name<br />
                            Store/Type of Gift Card<br />
                            Card Number<br />
                            CVV Code<br />
                            Expiry date<br />
                            Purchase Receipt (where applicable)<br />
                            The information further required by the Company<br />
                            The Account Holder warrants the Company that he/she/it is the lawful owner of the Gift Card and, where and when required, shall provide proof and/or reasons where the Gift Card has been acquired.</p>

                        <p>The Account Holder warrants the Company it has not obtained the Gift Card by any unlawful means.</p>

                        <p>The Account Holder warrants the Company that it holds the capacity, competency, authority and/or permission to sell and/or provide the Gift Card information to the Company without any conditions.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}> THE PROCESS</h2>
                        <p>
                            The Account Holder understands it does not immediately provide the required payment in return for the Gift Card information; as such, the process works as follows:</p>

                        <p>As per clause 1 of this agreement, the Account Holder provides the Gift Card information to the Company.<br />
                            The Company processes the Gift Card and provides the information to a payment processor or other users interested in purchase of said gift card.<br />
                            The Payment Processors verify the Gift Card information and, where applicable, provide payment to the Company.<br />
                            Once the Company verifies the payment and confirms it has been received, the Company deducts applicable processing fees, commission, and transfer fees from the amount.<br />
                            The residue is paid to the Account Holder in terms of the website and/or to their chosen financial institution/payment platform.</p> <br />

                        <br />
                        <h2 className={paragraphTitleStyle}>PAYMENT PROCESSING</h2>
                        <p>
                            The Company does not process the Gift Cards; external payment processors verify the Gift Card information and issue payment once confirmed to the Company.</p>

                        <p>The Account Holder agrees that the Company is allowed to deduct the following from the Gift Cards for payout:</p>

                        <p>Payment Processor Fees.<br />
                            Payment Processors Transfer Fees.<br />
                            Taxes and Fees.<br />
                            Commission of the Company.<br />
                            Administration Fees.<br />
                            Payment Platform Fees and/or Financial Gateway fees (PAYPAL etc.).<br />
                            TAXES AND FEES<br />
                            The Company shall not issue payment on behalf of the Account Holder for any taxes and/or fees. Should any of the above be applicable, it shall automatically be deducted from the amount to be paid to the Account Holder.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>REFUNDS/RETURNS</h2>
                        <p>
                            Once the Account Holder provides the Company the Gift Card information, it shall be instantly sent to the Payment Processor; the transaction is non-returnable and therefore unable to be stopped once initiated.</p>

                        <p>Therefore, no REFUNDS nor any RETURNS shall be applicable, nor shall any request be accepted.</p>

                        <p>DISCLAIMER OF LIABILITY<br />
                            The Account Holder herefore unconditionally indemnifies the Company for any and all claims in terms of the transaction as outlined above. Should the payment processor fail to make payment, it shall not be processed on the Company&#39;s side and therefore, no payment shall be applicable.</p>

                        <p>The Account Holder expressly acknowledge and accept the risk associated with the transaction.</p>

                        <p>Refer to the Limited Liability provisions of the Terms of Use.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>INDEMNIFICATION</h2>
                        <p>
                            The Account Holder agree to indemnify and hold Nexoz llc, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys&#39; fees) made against it by any third party due to or arising out of or in connection with your use of the website, platform and/or online services.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>UNLAWFUL ACTIVITIES</h2>
                        <p>
                            The Account Holder warrants the Company that the Gift Card obtained is lawful. He/she/they is the lawful owner of the Gift Cards. Should the Company become aware the Account Holder has committed unlawful actions and/or provided unlawfully obtained Gift Cards, and the Account Holder shall be suspended and/or terminated. Any and all funds which are due and payable shall be considered null and void and therefore not payable to the Account Holder.</p>

                        <p>The Company shall inform relevant authorities where required.</p>

                        <p>Should any funds be in the Account Holder&#39;s account, the Company shall suspend the payouts and/or terminate payouts and freeze the Accounts.</p>

                        <p>The Company may use the Account Holder&#39;s Funds where a breach has occurred and consider the Funds liquidated damages owed to the Company, the Account Holder unconditionally agree to such.</p>

                        <p>SUSPENSION AND/OR TERMINATION ARE NON-REVERSIBLE AND IS PERMANENT.</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>SEVERABILITY</h2>
                        <p>
                            Suppose any of these Terms of Use provisions are held by a court or other tribunal of competent jurisdiction to be void or unenforceable. In that case, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use so that these Terms of Use shall remain in full force and effect</p>

                        <br />
                        <h2 className={paragraphTitleStyle}>CONTACT</h2>
                        <p>
                            Should you require any information and/or request assistance, you are to direct your complaint/notice and/or inquiry to legal@nexoz.net.</p>

                        {/* end  */}
                    </div>


                </section>
            }
            {
                enableFooterSection && <NexozFooter />
            }

            {/* <FooterDaisy/> */}

        </div>
    );
};

export default TermsOfSale;