import React from 'react';
import NexozFooter from '../../../components/footers/NexozFooter';
import RoobNavBar from '../../../components/NexozHeroSection/RoobNavBar';

const AffliateDisclosure = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    const textParagraphStyle = "font-sans [&>p]:text-lg font-medium [&>h2]:text-[#625e8a] bg-white"
    const paragraphTitleStyle = "text-2xl  text-[#434060] "
    // const textParagraphStyle = "text-[#605b81] text-md"
    return (
        <div>
            {
                enableHeader && <RoobNavBar />

            }
            {
                enableHeroSection && <section className="py-5 md:py-10 px-5 md:px-28 bg-white ">
                    <h1 className="text-2xl md:text-5xl font-bold text-[#493D93] pb-5">
                        AFFILIATE DISCLOSURE
                    </h1>





                    {/* start  */}
                    <div className={textParagraphStyle}>


                        <br />
                        <h2 className={paragraphTitleStyle}> AFFILIATE DISCLOSURE </h2>
                        <p>
                            To ensure compliance with the FEDERAL TRADE COMMISSION and the DEPARTMENT OF TREASURY, more so the FINANCIAL CRIMES ENFORCEMENT NETWORK BUREAU</p>

                        <br />
                        <p>We hereby confirm as follow:</p>

                        <p>We are not affiliated with MasterCard, Visa, American Express and/or any Gift Card Providers.<br />
                            We are not in partnership with MasterCard, Visa, American Express and/or any Gift Card providers.<br /><br />
                            We therefore do not use their respective trademarks, copyright and/or any of the respective intellectual property of the applicable businesses as a means to produce, insinuate and/or incorporate an applicable partnership of affiliation.<br />
                            Should you believe we have errored in terms of the above disclosure, you are directed to issue a complaint and/or notice of the above to our internal compliance department via the following channel:</p>
                        <br />
                        <p>E-MAIL</p>

                        <p>INTERNAL COMPLIANCE DEPARTMENT</p>

                        <p>LEGAL@NEXOZ.NET</p>
                        {/* end  */}
                    </div>


                </section>
            }
            {
                enableFooterSection && <NexozFooter />
            }

            {/* <FooterDaisy/> */}

        </div>
    );
};

export default AffliateDisclosure;