import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Layout/Layout';

const PurchaseDetails = () => {
    let { id } = useParams();

    // const [paymentStatus,]


    const getPurchaseDetails = () => {
        // call the ss , to get purchase details
        
        axios.get("/purchase/invoiceid"+id)
        .then(response => {
            console.log(response.data);
        })
        .catch(error =>{
            console.log("error_getPurchaseDetails", error);
        })
        .finally(() => {

        })


        // iF not paid yet call the nowpayments getway to check the latest payment status.
    }

    useEffect(() => {
        console.log("PurchaseDetails", id)
    }, [])

    const textCodeTitle = "text-xl md:text-xl font-bold mb-2 md:md-0  md:(text-left w-1/2 ml-5) uppercase"
    const textValue = "text-gray-500 sm:text-sm md:text-xl"
    const lineWrapper = "w-full flex flex-col md:flex-row justify-between items-center pb-1";

    return (
        <Layout>
            <div className="flex justify-end">
                <button onClick={getPurchaseDetails} class="btn btn-outline btn-accent">REFRESH</button>

            </div>
            <div className="flex items-center justify-center mt-20">
                <div >
                    <h1 className="text-2xl md:text-5xl font-bold pb-10">Purchase Code</h1>
                    <div className={lineWrapper}>
                        <h1 className={textCodeTitle}>Purchase ID</h1>
                        <div className="w-1/2">
                            <div class="mt-1  relative rounded-md shadow-sm flex flex-col justify-center">
                                <span class={textValue}> {id}</span>
                            </div>
                        </div>

                    </div>
                    <div className={lineWrapper}>
                        <h1 className={textCodeTitle}>Purchase Amount</h1>
                        <div className="w-1/2">
                            <span class={textValue}>  ₦ 10000</span>
                        </div>

                    </div>
                    <div className={` ${lineWrapper}  my-3 `}>
                        <h1 className={`${textCodeTitle}`}>Payment Status</h1>
                        <div className="w-1/2">
                            <div class="mt-1  relative rounded-md shadow-sm flex flex-col  items-start justify-center">
                                <span class={textValue}>  waiting</span>
                                <div>
                                    <a href="" className="font-semibold text-md text-blue-900 mr-4 hover:underline">PAYMENT PAGE</a>
                                    <a href="" className="font-semibold text-md text-blue-900 mr-4 hover:underline">CHECK PAYMENT STATUS</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="w-full flex flex-col md:flex-row justify-between items-center">
                        <h1 className={textCodeTitle}>Purchase Code</h1>
                        <div className="w-1/2">
                            <span class={textValue}> To get the code pay at first.</span>
                        </div>

                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default PurchaseDetails;