import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import store from './app/store';
// import './index.css';
import './style/roobstyles.css';
import './style/tailwind.css';
// import store from './redux/store';
// import store, { persistor } from './redux/configureStore';

import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './app/configureStore';
import reportWebVitals from './reportWebVitals';


// import store from './app/configureStore';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
