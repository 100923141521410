import React from 'react'

const BuildYourBrand = () => {


    const StatsComponent = ({number, subline}) => {
        return <div className='flex flex-row  justify-center items-center	'>
            <div className='flex flex-col justify-center items-center	'>
                <p className=' text-center font-bold text-5xl md:text-6xl font-raleway text-[#0A2224]'>
                    {number}+
                </p>
                <p className='  w-[150px]  mx-auto text-center font-medium text-sm text-[#0A2224]	font-raleway'>{subline}</p>

            </div>
            <div className=' mt-[-20%]'>
                {/* icon  */}
                <img src="img/v2/top-arrow-icon.png"/>
            </div>

        </div>
    }

    return (
        <section className='bg-[#D4EFEF] rounded-tl-xl rounded-tr-xl pb-12'>
            <div className='flex flex-col md:flex-row pt-10'>

                <div className='flex-1 '>
                    {/* col1  */}
                    <div className='p-5 md:p-10'>
                        <div className='h2'>Build your brand on us</div>
                        <p className='description mt-5'>Leverage our products and technology to build your business or brand. Need help developing your Web application or app? No problem, our team is here to help, we also provide free development to businesses that meet certain criteria to help you kick start and launch your project in no time.</p>

                    </div>

                </div>
                <div className='flex-1 grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-0 p-5 md:p-0'>
                    {/* col2  */}
                    <StatsComponent number={100} subline={"Global payment solutions."} />
                    <StatsComponent number={100} subline={"Country supported."} />
                    <StatsComponent number={1000} subline={"Product Catalog. "} />
                    <StatsComponent number={"$1m"} subline={"Sales YTD."} />
                    
                </div>
            </div>
        </section>
    )
}

export default BuildYourBrand