import axios from 'axios';
import React, { useState } from 'react';
import { RiRotateLockLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isLoadingComponentUpdate } from '../../../redux/slices/loadingSlice';
import { loggedInTrue, tokenUpdate } from '../../../redux/slices/userSlice';
import baseApiUrl from '../../../utility/baseApiUrl';
import responseErrorManage from '../../../utility/responseErrorManage';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CallIcon from './img/call-svgrepo-com.svg';
import UserIcon from './img/user-svgrepo-com.svg';
import './signup.css';


const Signup = () => {
    const [formData, setFormData] = useState({ name: '', email: '', password: '', contact_number: '' })
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.isLoading);
    const [activeTab, setActiveTab] = useState('login')
    let navigate = useNavigate();




    // axios requests
    const signupPostRequest = () => {
        dispatch(isLoadingComponentUpdate(true))
        axios.post(baseApiUrl() + 'users', { ...formData })
            .then(response => {
                dispatch(isLoadingComponentUpdate(false))
                console.log("signupPostRequest_101", response.data);
                dispatch(isLoadingComponentUpdate(false))
                const userInfo = response.data.data
                dispatch(loggedInTrue(userInfo));
                toast.success("Signup succeed, now login.")
                // navigate("/dashboard");
                setActiveTab('login');

            })
            .catch(errors => {
                dispatch(isLoadingComponentUpdate(false))


                // check status code 
                const responseStatusCode = errors.response.status
                // console.log("axiosReqPost_status_code",errors.response.status )

                // const errorMsgList = errors.response.data.errorMsg
                responseErrorManage(errors)

            })
    }
    // axios requests //
    const handleOnSubmitLogin = (e) => {
        e.preventDefault();
        loginRequest()
    }

    const loginRequest = () => {
        dispatch(isLoadingComponentUpdate(true))
        axios.post(baseApiUrl() + 'login', { ...formData })
            .then(response => {
                dispatch(isLoadingComponentUpdate(false))
                console.log("signupPostRequest_101", response.data);
                dispatch(isLoadingComponentUpdate(false))
                const userInfo = response.data.user
                const token = response.data.token;
                console.log("btoken", token)
                // localStorage.setItem("token_value", token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                dispatch(loggedInTrue(userInfo));
                dispatch(tokenUpdate(token));
                toast.success("Login Successfull")
                navigate("/dashboard");
            })
            .catch(errors => {
                dispatch(isLoadingComponentUpdate(false))
                responseErrorManage(errors)
                const responseStatusCode = errors.response.status
            })
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        // alert("Signup will be here.")
        console.log("Signup will be here.", formData)
        signupPostRequest();

    }

    const handleInputOnChange = (e) => {
        // console.log("handleInputOnChange",e.target.value, e.target.name)
        const prevFromData = { ...formData }
        prevFromData[e.target.name] = e.target.value
        // console.log("test_101", prevFromData);
        setFormData(prevFromData);


    }



    return (
        <>

            {/* <style>
                .login_img_section {
                    background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1650825556125-060e52d40bd0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) center center;
}
            </style> */}
            <div className="h-screen flex ">
                <div className="hidden lg:flex w-full lg:w-1/2 login_img_section justify-around items-center">
                    <div className="   bg-black   opacity-20    inset-0  z-0"  >

                    </div>
                    <div className="w-full mx-auto px-20 flex-col items-center space-y-6">
                        <h1 className="text-white font-bold text-4xl font-sans">NEXOZ</h1>
                        {/* <p className="text-white mt-1">The simplest app to use</p> */}
                        <div className="flex justify-center lg:justify-start mt-6">
                            <a href="#" className="hover:bg-indigo-700 hover:text-white hover:-translate-y-1 transition-all duration-500 bg-white text-indigo-800 mt-4 px-4 py-2 rounded-2xl font-bold mb-2">Get Started</a>
                        </div>
                    </div>
                </div>
                <>
                    {/* <div className="flex "> */}


                    <div className=" flex flex-col justify-center w-full lg:w-1/2">
                        {activeTab === "signup" ?
                            <div div className="flex w-full  justify-center items-center bg-white space-y-8">
                                <div className="w-full px-8 md:px-32 lg:px-24">

                                    <form className="bg-white rounded-md shadow-2xl p-5">
                                        <h1 className="text-gray-800 font-bold text-2xl mb-1">REGISTER</h1>
                                        <p className="text-sm font-normal text-gray-600 mb-8">Enter your info to register</p>
                                        <div className="flex items-center border-2 mb-5 md:mb-8 py-2 px-3 rounded-2xl">
                                            <img className="h-5 w-5 text-gray-400" src={UserIcon} alt="user" />
                                            <input onChange={(e) => handleInputOnChange(e)} id="name" className=" pl-2 w-full outline-none border-none" type="text" name="name" placeholder="Full Name" />
                                        </div>

                                        <div className="flex items-center border-2 mb-5 md:mb-8 py-2 px-3 rounded-2xl">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                            </svg>
                                            <input onChange={(e) => handleInputOnChange(e)} id="email" className=" pl-2 w-full outline-none border-none" type="email" name="email" placeholder="Email Address" />
                                        </div>
                                        <div className="flex items-center border-2 mb-5 md:mb-8 py-2 px-3 rounded-2xl">
                                            <img className="h-5 w-5 text-gray-400" src={CallIcon} alt="user" />
                                            <input onChange={(e) => handleInputOnChange(e)} id="contact_number" className=" pl-2 w-full outline-none border-none" type="text" name="contact_number" placeholder="Contact Number" />
                                        </div>

                                        <div className="flex items-center border-2 mb-5 md:mb-12 py-2 px-3 rounded-2xl ">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                            </svg>
                                            <input onChange={(e) => handleInputOnChange(e)} className="pl-2 w-full outline-none border-none" type="password" name="password" id="password" placeholder="Password" />

                                        </div>
                                        <button disabled={isLoading.component} onClick={handleOnSubmit} type="submit" className="block text-center w-full bg-indigo-600 mt-2 py-2 rounded-2xl hover:bg-indigo-700 hover:-translate-y-1 transition-all duration-500 text-white font-semibold mb-2"> <span className="flex justify-center items-center">
                                            {isLoading.component && <RiRotateLockLine className="mx-2 animate-spin text-2xl" />}


                                            Signup</span></button>
                                        <div className="flex flex-col md:flex-row justify-between mt-12 md:mt-4">
                                            {/* <span className="text-sm ml-2 hover:text-blue-500 cursor-pointer hover:-translate-y-1 duration-500 transition-all">Forgot Password ?</span> */}

                                            <a onClick={(e) => { e.preventDefault(); setActiveTab('login'); }} href="/" className="text-sm py-2 ml-2 hover:text-blue-500 cursor-pointer hover:-translate-y-1 duration-500 transition-all">Have already an account? <span className="text-indigo-500 font-semibold">Login</span></a>
                                        </div>

                                    </form>
                                </div>

                            </div>
                            :
                            <>
                                {/* login tab */}
                                <div div className="flex w-full justify-center items-center bg-white space-y-8">
                                    <div className="w-full px-8 md:px-32 lg:px-24">

                                        <form className="bg-white rounded-md shadow-2xl p-5">
                                            <h1 className="text-gray-800 font-bold text-2xl mb-1">Login</h1>
                                            <p className="text-sm font-normal text-gray-600 mb-8">Welcome Back</p>
                                            <div className="flex items-center border-2 mb-5 md:mb-8 py-2 px-3 rounded-2xl">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                                </svg>
                                                <input onChange={(e) => handleInputOnChange(e)} id="email" className=" pl-2 w-full outline-none border-none" type="email" name="email" placeholder="Email Address" />
                                            </div>
                                            <div className="flex items-center border-2 mb-5 md:mb-12 py-2 px-3 rounded-2xl ">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                                </svg>
                                                <input onChange={(e) => handleInputOnChange(e)} className="pl-2 w-full outline-none border-none" type="password" name="password" id="password" placeholder="Password" />

                                            </div>
                                            <button disabled={isLoading.component} onClick={handleOnSubmitLogin} type="submit" className="block text-center w-full bg-indigo-600 mt-2 py-2 rounded-2xl hover:bg-indigo-700 hover:-translate-y-1 transition-all duration-500 text-white font-semibold mb-2"> <span className="flex justify-center items-center">
                                                {isLoading.component && <RiRotateLockLine className="mx-2 animate-spin text-2xl" />}
                                                Login</span></button>
                                            <div className="flex flex-col md:flex-row justify-between mt-12 md:mt-4">
                                                {/* <span className="text-sm ml-2 hover:text-blue-500 cursor-pointer hover:-translate-y-1 duration-500 transition-all">Forgot Password ?</span> */}

                                                <a onClick={(e) => { e.preventDefault(); setActiveTab('signup'); }} href="/" className="text-sm  py-2 ml-2 hover:text-blue-500 cursor-pointer hover:-translate-y-1 duration-500 transition-all">Don't have an account yet?<span className="text-indigo-500  font-semibold"> Signup Now</span> </a>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                                {/* login tab */}
                            </>
                        }
                        <div className="pt-10 text-2xl font-bold text-gray-800 text-center">

                            <Link to="/">  Back to <span className="text-indigo-500">Homepage</span> </Link>
                        </div>
                    </div>



                    {/* </div> */}

                </>
            </div>

        </>
    );
};

export default Signup;