import { React, useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
// import Layout from '../../../components/Layout/Layout';
// import baseApiUrl from '../../../utility/baseApiUrl';

import Pagination from "react-js-pagination";
import Layout from '../../../../components/Layout/Layout';
import { isLoadingFullPageUpdate } from '../../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../../utility/baseApiUrl';

const axios = require("axios");
const GiftCardOffers = () => {
    const [giftCardOffer, setGiftCardOffer] = useState({});

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pageLoaderReducer);


  // load giftCardOffer from server
  const loadUser = (pageNumber = 1) => {
    // dispatch({
    //   type:"fullPageLoader_update",
    //   payload : true
    // });
    dispatch(isLoadingFullPageUpdate({status:true,msg:"Loading..."}));
    axios

      .get(baseApiUrl() + "gift-card-offer?page="+pageNumber)
      .then((res) => {
        
        
        setGiftCardOffer(res.data);
        // console.log(test2)

      })
      .catch((err) => {
       
      })
      .finally(() => {
        dispatch(isLoadingFullPageUpdate({status:false,msg:"Loading..."}));
      })


    console.log('test-5', isLoading)
  };


  useEffect(() => {

    loadUser();
  }, []); 




  // delete giftCardOffer
  const deleteData = item => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(isLoadingFullPageUpdate({status:true,msg:"Deleting..."}));
        axios.delete(baseApiUrl() + 'gift-card-offer/' + item.id).then(res => {

            
            Swal.fire(
              'Deleted!',
              res.data.msg,
              'success',
            )
            // reload-load giftCardOffer
            // let index = giftCardOffer.indexOf(item);
            // giftCardOffer.splice(index, 1);
            loadUser();
        })
        .finally(() =>{
          dispatch(isLoadingFullPageUpdate({status:false,msg:"Loading..."}));
        })
      }
    })
  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Gift Card Offer List</h3>
              <Link to={'/add-gift-card-offer'}>
                     <button className='button__bg px-6 py-2 block rounded'>Add Card Offer</button>
              </Link>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Card Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {giftCardOffer?.data?.map((item, index) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.title}
                    </th>
                    <td className="px-6 py-4 capitalize created_at">
                      {item.rate_type === 0 ? <>{item.rate} %</> : <>{item.rate} Fixed</>}
                    </td>
                    <td className="created_at px-6 py-4 ">
                       {item.is_active === 0 ? <>Maintenance</> : <>Active</>}
                    </td>
                    <td className="px-6 py-4 flex gap-5 text-2xl">
                      <span onClick={() => deleteData(item)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                      <Link to={`/edit-gift-card-offer/${item.id}`}><AiOutlineEdit /></Link>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

            {giftCardOffer?.total > 10 && 
                 <div className='pl-3 py-4'>
                    <Pagination
                        activePage={giftCardOffer?.current_page ? giftCardOffer?.current_page : 0}
                        itemsCountPerPage={giftCardOffer?.per_page ? giftCardOffer?.per_page : 0 }
                        totalItemsCount={giftCardOffer?.total ? giftCardOffer?.total : 0}
                        onChange={(pageNumber) => {
                          loadUser(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </Layout>
  );
};

export default GiftCardOffers;