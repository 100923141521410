import React from 'react';


const NexozHeroSection = () => {
    //#726d9a  dark
    // #494662 light
    const test = true;
    return (
        <div>
            
            {test &&
                <div className="px-2 pt-10 pb-5 md:p-10 w-full bg-white ">
                    <div className="grid grid-cols-1 md:grid-cols-60/40 gap-4">
                        <section className="bg-white">
                            <p className=" font-circular text-xl md:text-4xl font-bold text-[#4C3A9E] text-center md:text-left">Turn your unwanted gift card <br></br> Into gift cards your want or cash</p>
                            <div className="flex flex-col-reverse md:flex-row items-center py-10 justify-center md:justify-start">
                                <div className="pt-5 md:pt-0">
                                    <a class="bg-[#4c3b96]  text-white text-lg px-8 py-2 rounded " href="/">Try Now</a>
                                </div>

                                <img src="/img/nexoz-2.gif" alt="" class="px-2 md:px-10 h-[250px]" />

                            </div>
                            <div>
                                <p className="text-xl md:text-4xl  font-bold text-[#4C3A9E] text-center md:text-right">No gift card is a waste anymore</p>
                                <div className="flex items-center justify-end py-5 pr-[] pl-[25px] md:pl-0 md:py-2">
                                    <img src="/img/crh-2.png" alt="" class="md:mr-10 h-[150px]" />
                                    <p className="text-[#4C3A9E] text-md md:text-2xl text-right mx-5">
                                        Over $3 billion of gift cards go <br className=""></br>
                                        unused every year
                                    </p>
                                </div>

                            </div>
                        </section>
                        {/* left sidebar  end*/}
                        {/* right sidebar  */}
                        <section className="bg-white">
                            {/* <CardRightSide/> */}
                            <img src="/img/rightside-hero.png" alt="" className=""/>
                        </section>
                        {/* right sidebar  end*/}


                    </div>

                </div>}

            {/* <BecomeNexBoss /> */}

        </div>
    );
};

export default NexozHeroSection;