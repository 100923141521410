import React from 'react';
import NexozFooter from '../../components/footers/NexozFooter';
import NexozHeroSection from '../../components/NexozHeroSection/NexozHeroSection';
import RoobNavBar from '../../components/NexozHeroSection/RoobNavBar';

const Homepage = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    return (
        <div>
            {
                enableHeader && <RoobNavBar />

            }
            {
                enableHeroSection && <NexozHeroSection />
            }
            {
                enableFooterSection && <NexozFooter />
            }

            {/* <FooterDaisy/> */}

        </div>
    );
};

export default Homepage;