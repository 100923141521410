import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdSelectAll } from "react-icons/md";
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBox from '../../../UiComponents/Boxes/SimpleBox';
import baseApiUrl from '../../../utility/baseApiUrl';


import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from 'react-toastify';
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';


const TransactionSaveToExistingUser = () => {


    const [users, setUser] = useState({});

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.pageLoaderReducer);
    const [showModal, setShowModal] = useState(false)
    const [isModalDataProcessing, setIsModalDataProcessing] = useState(false)
    const [modalTitle, setModalTitle] = useState("Confirmation")
    const [sellerInfo, setSellerInfo] = useState([]);
    const [currentSellerId, setCurrentSellerId] = useState(null);

    const [linkedSellerId, setLinkedSellerId] = useState(0);
    const [linkedSellerInfo, setLinkedSellerInfo] = useState([]);



    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);


    const transactionId = useSelector(state => state.creditCard.txnId);
    const chargedAmount = useSelector(state => state.creditCard.chargedAmount);
    const [txnLabel, setTxnLabel] = useState("");

    const [commissionType, setCommissionType] = useState("")
    const [txnCommission, setTxnCommission] = useState(0)
    const [giftCardName, setGiftCardName] = useState("")
    const [txnNote, setTxnNote] = useState("")



    // load users from server
    const loadData = (pageNumber = 1) => {

        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });

        axios
            .get(baseApiUrl() + "seller-lists?page=" + pageNumber)
            .then((res) => {

                dispatch({
                    type: "fullPageLoader_update",
                    payload: false
                });
                setUser(res.data);

            })
            .catch((err) => {
                dispatch({
                    type: "fullPageLoader_update",
                    payload: false
                });
            })


        console.log('test-5', isLoading)
    };


    const handleSelectUser = (sellerInfo) => {
        console.log("sellerInfo", sellerInfo)
        setCurrentSellerId(sellerInfo.id)
        setShowModal(true);
        setSellerInfo([
            { "Seller Name": sellerInfo.name },
            { "Email": sellerInfo.email },
            { "Charge Amount": `$ ${chargedAmount}` },

        ]);
    }


    const handleSaveTxnToNexoz = () => {
        console.log("test_212_saveTxnToNexoz", currentSellerId)

        let validationPass = true;

        if (!commissionType) {
            validationPass = false;
            toast.warning("Select any commission type")
        }
        if (!txnCommission && txnCommission === 0) {
            validationPass = false;
            toast.warning("Type the commission amount")
        }
        if (!validationPass) {
            return;
        }

        const payload = {
            transaction_label: txnLabel,
            internal_id: currentSellerId,

            commission_type: commissionType === "fixed" ? 1 : 2,
            txn_commission_amount: txnCommission,
            gift_card_name: giftCardName,
            txn_note: txnNote,

            txn_charged_amount: chargedAmount

            // "","","",""

        }


        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..." }))
        setIsSaveButtonDisabled(true)
        axios
            .post(baseApiUrl() + "paylog-save-nexoz/" + transactionId, payload)
            .then(response => {
                console.log(response.data);

                if (response.data.data) {
                    toast.success("Transaction has been saved.")
                    setLinkedSellerId(currentSellerId);
                    setLinkedSellerInfo([
                        { "Seller Name": sellerInfo.name },
                        { "Email": sellerInfo.email },
                        { "Charge Amount": `$ ${chargedAmount}` },
                        { "Gift Card Name": `$ ${giftCardName}` },
                        { "Commision Type": ` ${commissionType}` },
                        // { "Commision Amount":  `${commissionType === "fixed" ? "1" : "2" }`}
                          { "Commision Amount": `${commissionType === "fixed" ? `$ ${txnCommission}` : `${txnCommission} %`} ` },
                        
                    ]);
                  
                    setIsSaveButtonDisabled(true);
                }

            })
            .catch(errors => {
                setLinkedSellerId(0);
                console.log(errors);
                setIsSaveButtonDisabled(false);

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "" }))
            })
    }

    useEffect(() => {
        loadData();
    }, []);






    const handleRadioButton = (e) => {
        console.log("handleRadioButton", e.target.value)
        setCommissionType(e.target.value)
        // dispatch(purchaseInfoUpdate({ payment_schedule: e.target.value }))
    }

    const handleCloseModal = () => {
        setShowModal(false)
        resetModalContent();
    }

    const resetModalContent = () => {

        setTxnLabel("");
        setCommissionType("")
        setTxnCommission(0)
        setGiftCardName("")
        setTxnNote("")
        setIsSaveButtonDisabled(false)

    }




    return (
        <>


            {/* // Modal Test START */}
            <>

                {showModal ? (
                    <>
                        {
                            isModalDataProcessing ? <div
                                className="text-white justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                            >
                                <AiOutlineLoading3Quarters className="animate-spin text-5xl text-white" />
                            </div> :
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <h3 className="text-3xl font-semibold">
                                                    {modalTitle}
                                                </h3>
                                                <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            {/*body*/}
                                            <div className="relative pt-6 px-6 pb-2 flex-auto">

                                                <p>
                                                    Seller Info
                                                </p>
                                                <div className="my-4 bg-gray-100 rounded-lg p-5">
                                                    {sellerInfo.map((item) =>

                                                        <div className="grid grid-cols-2 gap-2">
                                                            <div className=" text-slate-500 text-md leading-relaxed">{Object.keys(item).toString()}</div>
                                                            <div>{item[Object.keys(item).toString()]}</div>
                                                        </div>

                                                    )}
                                                </div>
                                                <section>
                                                    <div className="grid grid-cols-2 gap-2 mb-2">
                                                        <p> Transaction Label</p>
                                                        <input onChange={e => setTxnLabel(e.target.value)} value={txnLabel} type="text" className="input-blue text-xl" />
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-2 mb-2">
                                                        <p> Transaction Type</p>

                                                        <div className="text-xl"  >
                                                            <input type="radio" id="rad1" name="txn_type" value="fixed" onChange={(e) => handleRadioButton(e)} checked={commissionType === "fixed"} />
                                                            <label for="rad1" className="pl-2 py-2  block-inline" >Fixed</label><br></br>
                                                            <input type="radio" id="rad2" name="txn_type" value="percentage" onChange={(e) => handleRadioButton(e)} checked={commissionType === "percentage"} />
                                                            <label for="rad2" className="pl-2 py-2 block-inline">Percentage</label><br></br>
                                                        </div>
                                                    </div>

                                                    <div className="grid grid-cols-2 gap-2 mb-2">
                                                        <p> Commission Amount {commissionType === "fixed" ? "$" : "( % )"}  </p>
                                                        <input onChange={e => setTxnCommission(e.target.value)} value={txnCommission} type="text" className="input-blue text-xl" />
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-2 mb-2">
                                                        <p> Gift Card Name</p>
                                                        <input onChange={e => setGiftCardName(e.target.value)} value={giftCardName} type="text" className="input-blue text-xl" />
                                                    </div>

                                                    <div className="grid grid-cols-2 gap-2 mb-2">
                                                        <p> Note</p>
                                                        <textarea rows="4" onChange={e => setTxnNote(e.target.value)} value={txnNote} className="input-blue text-xl" />
                                                    </div>


                                                </section>


                                                <div className="grid grid-cols-2 gap-2 mb-1">
                                                    <p></p>
                                                    <button disabled={isSaveButtonDisabled} onClick={() => handleSaveTxnToNexoz()} className="btn btn-outline btn-sm  btn-primary  ">CONFIRM & SAVE</button>

                                                </div>



                                            </div>
                                            {/*footer*/}
                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                <button
                                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => handleCloseModal()}
                                                >
                                                    Close
                                                </button>
                                                {/* <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Save Changes
                                        </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>

            {/* // Modal Test  END */}


            {/* if this txn already linked  */}
            {linkedSellerId && <section>


                <div className="relative pt-6 px-6 pb-2 flex-auto">

                    <p>
                        Linked Seller Info
                    </p>
                    <div className="my-4 bg-gray-100 rounded-lg p-5">
                        {linkedSellerInfo.map((item) =>

                            <div className="grid grid-cols-2 gap-2">
                                <div className=" text-slate-500 text-md leading-relaxed">{Object.keys(item).toString()}</div>
                                <div>{item[Object.keys(item).toString()]}</div>
                            </div>

                        )}
                    </div>
                    

{/* 
                    <div className="grid grid-cols-2 gap-2 mb-1">
                        <p></p>
                        <button disabled={isSaveButtonDisabled} onClick={() => handleSaveTxnToNexoz()} className="btn btn-outline btn-sm  btn-primary  ">CONFIRM & SAVE</button>

                    </div> */}



                </div>


            </section>}
            {/* if this txn already linked  */}

            {(transactionId && !linkedSellerId) && <div className="mx-5 my-10">
                <SimpleBox title="Transaction Save To Existinguser" titleBgColor="bg-gray-500" >

                    <div>



                        <div className='bg-white rounded-lg '>



                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                                        <tr>

                                            <th scope="col" className="px-6 py-3">
                                                Seller name
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Seller Email
                                            </th>
                                            <th scope="col" className="text-center">
                                                Select User
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='mt-5'>
                                        {users?.data?.map((item, index) => {
                                            return (
                                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                        {item.name}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {item.email}
                                                    </td>

                                                    <td className="mx-auto text-2xl">
                                                        <MdSelectAll className="cursor-pointer mx-auto" onClick={() => handleSelectUser(item)} />

                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>

                                {users?.total > 5 &&
                                    <div className='pl-3 py-4'>
                                        <Pagination
                                            activePage={users?.current_page ? users?.current_page : 0}
                                            itemsCountPerPage={users?.per_page ? users?.per_page : 0}
                                            totalItemsCount={users?.total ? users?.total : 0}
                                            onChange={(pageNumber) => {
                                                loadData(pageNumber)
                                            }}
                                            pageRangeDisplayed={8}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            itemClassFirst="p-first-page-link"
                                            itemClassLast="p-last-page-link"
                                            activeClass="p-active"
                                            activeLinkClass="p-active-link"
                                            firstPageText="First Page"
                                            lastPageText="Last Lage"
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </SimpleBox>
            </div>
            }
        </>
    );
};

export default TransactionSaveToExistingUser;