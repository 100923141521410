
const { createSlice } = require("@reduxjs/toolkit");

export const purchaseSlice = createSlice({
    name: "purchaseInfo",
    initialState: { amount: null, currency: null, payment_schedule: null, schedule_date:null },   // initialState: {count:0},
    reducers: {

        purchaseInfoUpdate: (state, action) => {

            // if( action.payload?.amount ===""){
                
            // }
            action.payload?.amount || action.payload?.amount ==="" ? state.amount = action.payload.amount: state.amount =state.amount ;
            console.log("purchaseInfo", action.payload)
            

            action.payload?.currency ? state.currency = action.payload?.currency : state.currency = state.currency;
            action.payload?.payment_schedule ? state.payment_schedule = action.payload?.payment_schedule : state.payment_schedule = state.payment_schedule;
            action.payload?.schedule_date ? state.schedule_date = action.payload?.schedule_date : state.schedule_date = state.schedule_date;
        },

        purchaseInfoClear: (state) => {
            state.amount = null;
            state.currency = null;
            state.payment_schedule = null;
        },


    }
});


export const { purchaseInfoUpdate, purchaseInfoClear } = purchaseSlice.actions;
export default purchaseSlice.reducer;