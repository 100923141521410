const { createSlice } = require("@reduxjs/toolkit");

export const userSlice = createSlice({
    name: "authInfo",
    initialState: { status: false, user: null, permission: null, token: null , availableBalance:200, dueBalance:0},   // initialState: {count:0},
    reducers: {
        loggedInTrue: (state, action) => {

            console.log('redux-101', action, action.payload.role)
            state.status = true;
            state.user = action.payload;
            if (action.payload?.role) {
                state.permission = action.payload.role;
            } else {

                state.permission = null;
            }
        },
        tokenUpdate: (state, action) => {
            console.log("tokenUpdate_101",action.payload)
            //token
            state.token = action.payload

        },
        loggedOut: (state) => {
            state.status = false;
            state.user = null;
            state.permission = null;
            state.token  = null
        },
        updateUserInfo: (state, action) => {
            state.user = action.payload;
        },
        updateBalanceInfo: (state, action) => {
            state.availableBalance = action.payload.availableBalance;
            state.dueBalance = action.payload.dueBalance;
        },


    }
})

export const { loggedInTrue, loggedOut, tokenUpdate, updateUserInfo,updateBalanceInfo } = userSlice.actions;
export default userSlice.reducer;