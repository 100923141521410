import React from 'react';
import NexozFooter from '../../../components/footers/NexozFooter';
import RoobNavBar from '../../../components/NexozHeroSection/RoobNavBar';

const FinancialDisclosureNotice = () => {
    const enableHeader = true;
    const enableHeroSection = true;
    const enableFooterSection = true;
    const textParagraphStyle = "font-sans [&>p]:text-lg font-medium [&>h2]:text-[#625e8a] bg-white"
    const paragraphTitleStyle = "text-2xl  text-[#434060] "
    // const textParagraphStyle = "text-[#605b81] text-md"
    return (
        <div>
            {
                enableHeader && <RoobNavBar />

            }
            {
                enableHeroSection && <section className="py-5 md:py-10 px-5 md:px-28 bg-white ">
                    <h1 className="text-2xl md:text-5xl font-bold text-[#493D93] pb-5">
                        FINANCIAL DISCLOSURE
                    </h1>



                    

                    {/* start  */}
                    <div className={textParagraphStyle}>

                        <br />
                        <h2 className={paragraphTitleStyle}> FINANCIAL DISCLOSURE NOTICE</h2>
                        <p>
                            To ensure compliance with the FEDERAL TRADE COMMISSION and the DEPARTMENT OF TREASURY, more so the FINANCIAL CRIMES ENFORCEMENT NETWORK BUREAU,</p>

                        <br />
                        <p>We hereby disclose as follow:</p>

                        <p>We are a limited liability company duly incorporated under the laws and regulations of the United States of America, domiciled within the State of Texas.<br />
                            Our business model and our business operations comply with all (to the best of our knowledge) directives, regulations, requirements, and/or enforcement compliance guidelines.<br />
                            We are not in operation and/or cooperation with any registered foreign business or any foreign person.<br />
                            Our business interests and operations are operated from the United States of America and for the United States of America. Should any person outside the United States make use of our services, we shall duly collect the required information as provided by the Federal Trade Commission and/or the department of treasury where foreign funds are obtained. The information collected shall be done in accordance with our Privacy Policy<br />
                            We do not restrict business operations with foreign nationals, where business is done with foreign nationals, it shall be done in accordance with our Terms of Use, Terms of Sale, our &lsquo;KNOW YOUR CUSTOMER&rsquo; policy and our &lsquo;ANTI-MONEY LAUNDERING&rsquo; policy.<br />
                            We have not paid any media influencer and/or media person to advertise our services without following the proper protocol and/or failed to disclose affiliation.<br />
                            Should you believe we have errored in terms of the above disclosure, you are directed to issue a complaint and/or notice of the above to our internal compliance department via the following channel:</p>

                        <br /><p>E-MAIL</p>

                        <br />
                        <p>INTERNAL COMPLIANCE DEPARTMENT</p>
                        <p>LEGAL@NEXOZ.NET</p>

                        <p>Do include the following:</p>

                        <p>Your full name.<br />
                            Contact e-mail address<br />
                            Phone number.<br />
                            Current physical address.<br />
                            Complaint/notice</p>

                        <p>We shall respond within five to seven business days.</p>

                        {/* start  */}
                    </div>


                </section>
            }
            {
                enableFooterSection && <NexozFooter />
            }

            {/* <FooterDaisy/> */}

        </div>
    );
};

export default FinancialDisclosureNotice;