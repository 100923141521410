import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Signup from "../pages/Auth/Signup/Signup";

export default function ProtectedRoutes(){
   
    const isLoggedIn = useSelector(state => state.authInfo.token);
    // const isLoggedIn = useSelector(state => state.userLoginStatus);
    
    // console.log("isLoggedIn_101", isLoggedIn)
    return isLoggedIn ? <Outlet/> : <Signup/>; 
}