import React from 'react'
import SwipperSlider from '../../slider/SwipperSlider'




const CarosoulContainer = () => {

   
    const CustomSlider = () => {
        return <div className='p-6 md:p-10  basis-2/5'>
            <div className='w-[100%] bg-[#1C979F] rounded-xl flex justify-center'>
                <img className='mt-10' src="/img/v2/carousel-1-item.png"></img>
            </div>
            <div className='pt-3 flex flex-row justify-center gap-2'>
                <div className='bg-[#1C979F] w-[50px] rounded-lg h-[10px]'></div>
                <div className='bg-white w-[10px] rounded-full h-[10px]'></div>
                <div className='bg-white w-[10px] rounded-full h-[10px]'></div>
            </div>
        </div>

    }

    return (
        // <section className='rounded-lg bg-[#D4EFEF] h-[200px] my-4 flex flex-row'>
        //     <div classNameclass="bg-red-500 py-4"  >

        //         <div className='bg-[#1C979F] w-[100px]'>
        //             hello
        //         </div>
        //     </div>
        //     <div className="flex-grow bg-blue-500" style={{ flexGrow: 2 }}>Column 2</div>
        // </section>
        <section className='flex flex-col md:flex-row items-center	 bg-[#D4EFEF] rounded-xl pt-5 md:pt-0'>
            {/* Carosouel  */}
            <SwipperSlider />
            {/* Carosouel end */}
            <div className='p-6 md:p-10  basis-3/5'>
                <div className='font-raleway'>
                    <h2 className='h2 pb-5'>The results speaks for themselves.</h2>
                    <p className='text-xl text-[#4D4D4D]'>Nexoz solutions is behind a handful of apps and platforms that are tailor made to suite their respective demography, influencer, business,financial institution or service provider.</p>
                </div>
            </div>
        </section>
    )
}

export default CarosoulContainer