import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Layout from '../../../../components/Layout/Layout';
import { isLoadingFullPageUpdate } from '../../../../redux/slices/loadingSlice';
import baseApiUrl from '../../../../utility/baseApiUrl';
import responseErrorManage from '../../../../utility/responseErrorManage';

const PaymentMethods = () => {
    const loggedinUserInfo = useSelector((state) => state.authInfo.user);
    const [paypal, setPaypal] = useState('');
    const [bankDetails, setBankDetails] = useState('');
    const [usdt, setUsdt] = useState('');
    const [sellerId, setSellerId] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        getPaymentDetailsInfo()
    }, [])
    const getPaymentDetailsInfo = () => {
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }));
        axios.get(baseApiUrl() + `payment-method/${loggedinUserInfo.id}`)
            .then(res => {
                const data = res.data.data; //
                console.log("data_101", data)
                setPaypal(data.paypal);
                setUsdt(data.usdt);
                setBankDetails(data.bank_details);


            })
            .catch((err) => {
                responseErrorManage(err)

            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));

            })
    }
    const savePaymentMethods = () => {



        const data = {
            'paypal': paypal,
            'bank_details': bankDetails,
            'usdt': usdt,
        }
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..." }));
        axios.post(baseApiUrl() + `payment-method`, { ...data })
            .then((response) => {
                toast.success(response.data.msg);
                // getUserInfo();              
            })
            .catch((error) => {
                responseErrorManage(error);
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }
    const updatePaymentMethods = () => {



        const data = {
            'paypal': paypal,
            'bank_details': bankDetails,
            'usdt': usdt,
        }
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Saving..." }));
        axios.put(baseApiUrl() + `payment-method/${loggedinUserInfo.id}`, { ...data })
            .then((response) => {
                toast.success(response.data.msg);
                // getUserInfo();              
            })
            .catch((error) => {
                responseErrorManage(error);
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "Loading..." }));
            })
    }

    return (
        <div>
            <Layout>


                <div className="relative max-w-md mx-auto md:max-w-2xl mt-2 md:mt-16 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl ">
                    <div className="p-5">

                        <h3 className="text-2xl text-slate-700 font-bold leading-normal mb-1">Withdrawal Methods</h3>
                        <p className="text-sm text-red-500 my-5">
                            DOUBLE CHECK TO ENSURE ALL WITHDRAWAL DETAILS ARE ACCURATE AND MEET OUR REQUESTED PARAMETERS. WE WILL NOT BE RESPONSIBLE FOR LOSS OF FUNDS DUE TO INCORRECT DETAILS
                        </p>
                        <div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2 bg-gray-100 p-4 rounded-lg">
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Paypal Email</label>
                                <input value={paypal} onChange={(e) => setPaypal(e.target.value)} type="text" placeholder="paypal email" className="form-field" required />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 mb-2 bg-gray-100 p-4 rounded-lg">
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">Bank Details</label>
                                <div>
                                    <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">*Bank account name most match profile name</label>
                                    {/* <input value={paypal} onChange={(e) => setPaypal(e.target.value)} type="text" placeholder="paypal email" className="form-field" required /> */}
                                    <textarea value={bankDetails} onChange={(e) => setBankDetails(e.target.value)} className="form" className="form-field" />
                                </div>

                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center mb-2 bg-gray-100 p-4 rounded-lg">
                                <label className="block mb-2 text-md font-medium  text-gray-900 dark:text-gray-300">USDT (trc20 Network ONLY!)</label>
                                <input value={usdt} onChange={(e) => setUsdt(e.target.value)} type="text" placeholder=" " className="form-field" required />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button class="btn btn-outline btn-primary" onClick={() => { sellerId ? savePaymentMethods() : updatePaymentMethods() }}>SAVE</button>
                        </div>



                    </div>


                </div>
            </Layout>
        </div>
    );
};

export default PaymentMethods;