

import { React, useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import baseApiUrl from '../../../utility/baseApiUrl';

import Pagination from "react-js-pagination";
import { isLoadingFullPageUpdate } from '../../../redux/slices/loadingSlice';

const axios = require("axios");

const Customer = () => {

    const [users, setUser] = useState([]);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.pageLoaderReducer);


    // load users from server
    const loadUser = (pageNumber = 1) => {
        dispatch(isLoadingFullPageUpdate({ status: true, msg: "Loading..." }));
        dispatch({
            type: "fullPageLoader_update",
            payload: true
        });
        axios

            .get(baseApiUrl() + "all-user-lists?page=" + pageNumber)
            .then((res) => {

                dispatch({
                    type: "fullPageLoader_update",
                    payload: false
                });
                setUser(res.data);
                // console.log(test2)

            })
            .catch((err) => {
                dispatch({
                    type: "fullPageLoader_update",
                    payload: false
                });
            })
            .finally(() => {
                dispatch(isLoadingFullPageUpdate({ status: false, msg: "" }));
            })


        console.log('test-5', isLoading)
    };


    useEffect(() => {

        loadUser();
    }, []);




    // delete users
    const deleteUser = item => {
        // console.log(form)

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                // axios.delete('http://127.0.0.1:8000/api/user/delete/' + item.id).then(res => {
                axios.delete(baseApiUrl() + 'users/' + item.id).then(res => {


                    Swal.fire(
                        'Deleted!',
                        res.data.msg,
                        'success',
                    )
                    // reload-load users
                    // let index = users.indexOf(item);
                    // users.splice(index, 1);
                    loadUser();
                })
            }
        })
    }

    return (
        <Layout>
            <div className='bg-white rounded-lg '>

                <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                    <h3>User List</h3>
                    <Link to={'/create/user'}>
                        <button className='button__bg px-6 py-2 block rounded'>Add User</button>
                    </Link>
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Avialable Balance
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Pending Balance
                                </th>
                                {/* <th scope="col" className="px-6 py-3 text-right">
                    Rate %
                  </th> */}
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='mt-5'>
                            {users?.data?.map((item, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                            {item.name}
                                        </th>
                                        <td className="px-6 py-4">
                                            {item.email}
                                        </td>
                                        <td className="px-6 py-4 capitalize text-center">
                                            0
                                        </td>
                                        <td className="px-6 py-4 capitalize  text-center">
                                            0
                                        </td>
                                        
                                        {/* <td className="px-6 py-4 capitalize text-right">
                        {Math.round(item.rate) } %
                      </td> */}
                                        <td className="px-6 py-4 flex gap-5 text-2xl">
                                            <span onClick={() => deleteUser(item)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                                            <Link to={`/edit/user/${item.id}`}><AiOutlineEdit /></Link>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    {users?.total > 5 &&
                        <div className='pl-3 py-4'>
                            <Pagination
                                activePage={users?.current_page ? users?.current_page : 0}
                                itemsCountPerPage={users?.per_page ? users?.per_page : 0}
                                totalItemsCount={users?.total ? users?.total : 0}
                                onChange={(pageNumber) => {
                                    loadUser(pageNumber)
                                }}
                                pageRangeDisplayed={8}
                                itemClass="page-item"
                                linkClass="page-link"
                                itemClassFirst="p-first-page-link"
                                itemClassLast="p-last-page-link"
                                activeClass="p-active"
                                activeLinkClass="p-active-link"
                                firstPageText="First Page"
                                lastPageText="Last Lage"
                            />
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default Customer;


